const state = () => {
  return {
    unread: []
  };
};

const getters = {
  unread: state => state?.unread
};

const actions = {
  setUnreadNotifications({ commit }, notifications) {
    commit("SET_UNREAD_NOTIFICATIONS", notifications);
  },
  clearUnreadNotifications({ commit }) {
    commit("SET_UNREAD_NOTIFICATIONS", []);
  },
  addUnreadNotification({ commit }, notification) {
    commit("ADD_UNREAD_NOTIFICATION", notification);
  },
  removeUnreadNotification({ commit }, notificationId) {
    commit("REMOVE_UNREAD_NOTIFICATION", notificationId);
  }
};

const mutations = {
  SET_UNREAD_NOTIFICATIONS(state, notifications) {
    state.unread = notifications;
  },
  ADD_UNREAD_NOTIFICATION(state, notification) {
    state.unread.push(notification);
  },
  REMOVE_UNREAD_NOTIFICATION(state, notificationId) {
    const index = state.unread.findIndex(notif => notif.id === notificationId);

    if (index === -1) {
      return;
    }

    state.unread.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
