import Toastify from "toastify-js";
import i18n from "@/i18n";

const t = i18n.global.t;

const show = (
  text,
  status,
  options = {
    duration: 3000,
    newWindow: true,
    close: false,
    gravity: "top",
    position: "right",
    stopOnFocus: true
  }
) => {
  if (!text) {
    return;
  }

  Toastify({
    node: getNode(text, status),
    duration: options.duration,
    newWindow: options.newWindow,
    close: options.close,
    gravity: options.gravity,
    position: options.position,
    stopOnFocus: options.stopOnFocus,
    className: "toastify-content flex flex-col sm:flex-row"
  }).showToast();
};

const showAPIMessage = data => {
  if (!data) {
    return;
  }

  show(getMessage(data), getStatus(data));
};

const showAPIErrors = data => {
  if (!data) {
    return;
  }

  show(getErrorMessage(data), "Failure");
};

const getErrorMessage = data => {
  let message = getMessage(data);

  // @TODO: display error messages (or set it on form...?)
  // const errors = data?.payload?.errors?.errors;

  return message;
};

const removeChar = (str, char) => {
  return str.split(char).join("");
};

/**
 * Get the status from the response data returned from the backend
 * @param {Object} data - the response data returned from the backend
 * @returns {String} success or failure
 */
const getStatus = data => {
  return data?.payload?.message?.status;
};

/**
 * Get the message from the response data returned from the backend
 * @param {Object} data - the response data returned from the backend
 * @returns {String} the message
 */
const getMessage = data => {
  let values = {};
  let message = data?.payload?.message;

  if (message?.text) {
    values = Object.keys(message).reduce((acc, cv) => {
      return cv === "text" ? acc : { ...acc, [cv]: message[cv] };
    }, {});
    message = message.text;
  }

  // @NOTE: Can't use periods in i18n as it thinks it's a nested path
  return message && typeof message === "string"
    ? t(`backend.${removeChar(message, ".")}`, values)
    : "";
};

/**
 * Get the icon based off the status
 * @param {String} status - success or failure
 * @returns {String} html with the icon appropriate to the status
 */
const getIcon = status => {
  switch (status) {
    case "Success":
      return `<i class="fal fa-check-circle fa-2x text-theme-9"></i>`;
    case "Failure":
      return `<i class="fal fa-times-circle fa-2x text-theme-6"></i>`;
    default:
      return "<div></div>";
  }
};

const getBackgroundColor = status => {
  const opacity = "bg-opacity-20";

  switch (status) {
    case "Success":
      return `bg-theme-9 ${opacity}`;
    case "Failure":
      return `bg-theme-6 ${opacity}`;
    default:
      return "";
  }
};

const getNode = (text, status) => {
  const node = document.createElement("div");

  node.innerHTML = `
    <div class="${getBackgroundColor(status)} w-full h-full p-5">
      <div class="flex justify-center items-center">
        ${getIcon(status)}

        <div class="mx-4">
          ${text}
        </div>
      </div>
    </div>
  `;

  return node;
};

const install = app => {
  const toast = {
    show,
    showAPIMessage,
    showAPIErrors
  };

  app.config.globalProperties.$toast = toast;
  app.provide("toast", toast);
};

export { install as default };
