import { inject, ref } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

export default function useRequest() {
  // Misc
  const store = useStore();
  const router = useRouter();
  const toast = inject("toast");

  // Data
  const isRequesting = ref(false);

  // Methods
  const request = async ({
    endpoint,
    pathParams = {},
    queryParams = {},
    data = {},
    showToaster = true
  }) => {
    isRequesting.value = true;

    const response = await store.dispatch("api/request", {
      endpoint,
      pathParams,
      queryParams,
      data,
      router,
      toast: showToaster ? toast : null
    });

    isRequesting.value = false;

    return response;
  };

  const requestExternal = async config => {
    return await store.dispatch("api/requestExternal", config);
  };

  const isSuccess = response => {
    return response?.payload?.message?.status === "Success";
  };

  return {
    isRequesting,
    request,
    isSuccess,
    requestExternal
  };
}
