import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "competencies",
    name: "teaching-competencies",
    meta: {
      title: t("app.competencies", 2)
    },
    redirect: { name: "teaching-competencies-search" },
    component: () => import("@/views/teaching/competencies/CompetencyView"),
    children: [
      {
        path: "",
        name: "teaching-competencies-search",
        component: () => import("@/views/teaching/competencies/IndexPage")
      },
      {
        path: "add",
        name: "teaching-competencies-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/teaching/competencies/CreatePage")
      }
    ]
  },
  {
    path: "competencies/:id",
    name: "teaching-competencies-update",
    meta: {
      parentName: "teaching-competencies",
      title: t("app.competencies", 2)
    },
    props: true,
    redirect: { name: "teaching-competencies-overview" },
    component: () => import("@/views/teaching/competencies/Competency"),
    children: [
      {
        path: "overview",
        name: "teaching-competencies-overview",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/competencies/OverviewPage")
      },
      {
        path: "details",
        name: "teaching-competencies-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/competencies/DetailsPage")
      },
      {
        path: "learners",
        name: "teaching-competencies-learners",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/competencies/LearnersPage")
      },
      {
        path: "usage",
        name: "teaching-competencies-usage",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/competencies/UsagePage")
      },
      {
        path: "logs",
        name: "teaching-competencies-logs",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/competencies/LogsPage")
      }
    ]
  }
];
