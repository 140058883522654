import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "collections",
    name: "teaching-collections",
    meta: {
      title: t("app.collections", 2)
    },
    redirect: { name: "teaching-collections-search" },
    component: () => import("@/views/teaching/collections/ResourceView"),
    children: [
      {
        path: "",
        name: "teaching-collections-search",
        component: () => import("@/views/teaching/collections/IndexPage")
      },
      {
        path: "add",
        name: "teaching-collections-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/teaching/collections/CreatePage")
      }
    ]
  },
  {
    path: "collections/:id",
    name: "teaching-collections-update",
    meta: {
      parentName: "teaching-collections",
      title: t("app.collections", 2)
    },
    props: true,
    redirect: { name: "teaching-collections-overview" },
    component: () => import("@/views/teaching/collections/Resource"),
    children: [
      {
        path: "overview",
        name: "teaching-collections-overview",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/collections/OverviewPage")
      },
      {
        path: "details",
        name: "teaching-collections-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/collections/DetailsPage")
      },
      {
        path: "registrations",
        name: "teaching-collections-registrations",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/collections/RegistrationsPage")
      },
      {
        path: "feedback",
        name: "teaching-collections-feedback",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/collections/FeedbackPage")
      },
      {
        path: "logs",
        name: "teaching-collections-logs",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/collections/LogsPage")
      }
    ]
  }
];
