import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "roles",
    name: "administration-roles",
    meta: {
      title: t("app.roles", 2)
    },
    redirect: { name: "administration-roles-search" },
    component: () => import("@/views/administration/roles/RoleView"),
    children: [
      {
        path: "",
        name: "administration-roles-search",
        component: () => import("@/views/administration/roles/IndexPage")
      },
      {
        path: "add",
        name: "administration-roles-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/administration/roles/CreatePage")
      }
    ]
  },
  {
    path: "roles/:id",
    name: "administration-roles-update",
    meta: {
      parentName: "administration-roles",
      title: t("app.roles", 2)
    },
    redirect: { name: "administration-roles-details" },
    component: () => import("@/views/administration/roles/RoleView"),
    children: [
      {
        path: "details",
        name: "administration-roles-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/administration/roles/ReadPage")
      }
    ]
  }
];
