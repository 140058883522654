import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "requests",
    name: "teaching-requests",
    redirect: "teaching-requests-observations",
    component: () => import("@/components/RouterView"),
    children: [
      {
        path: "observations",
        name: "teaching-requests-observations",
        meta: {
          title: t("app.observations", 2)
        },
        component: () =>
          import("@/views/teaching/requests/observations/IndexPage")
      },
      {
        path: "attestations",
        name: "teaching-requests-attestations",
        meta: {
          title: t("app.attestations", 2)
        },
        component: () =>
          import("@/views/teaching/requests/attestations/IndexPage")
      },
      {
        path: "registration-collections",
        name: "teaching-requests-registrations-collections",
        meta: {
          title: t("app.registration_collections", 2)
        },
        component: () =>
          import("@/views/teaching/requests/collectionRegistrations/IndexPage")
      },
      {
        path: "registrations",
        name: "teaching-requests-registrations",
        meta: {
          title: t("app.registration_trainings", 2)
        },
        component: () =>
          import("@/views/teaching/requests/registrations/IndexPage")
      }
    ]
  }
];
