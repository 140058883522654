import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "licenses",
    name: "administration-licenses",
    meta: {
      title: t("app.licenses", 2)
    },
    component: () => import("@/views/administration/licenses/IndexPage")
  },
  {
    path: "licenses/:id",
    name: "administration-license",
    meta: {
      parentName: "administration-licenses",
      title: t("app.licenses", 2)
    },
    component: () => import("@/components/RouterView"),
    children: [
      {
        path: "users",
        name: "administration-license-users",
        meta: {
          title: t("app.users", 2)
        },
        props: true,
        component: () => import("@/views/administration/licenses/UsersPage")
      },
      {
        path: "invoice/:invoice_id",
        name: "administration-license-invoice",
        meta: {
          title: t("app.invoices", 2)
        },
        component: () => import("@/views/administration/licenses/InvoicePage"),
        props: route => ({
          licenseId: route.params.id,
          invoiceId: route.params.invoice_id
        })
      }
    ]
  }
];
