const state = () => {
  return {
    messages: {}
  };
};

const getters = {
  messages: state => state.messages
};

const actions = {
  setMessages({ commit }, messages) {
    commit("SET_MESSAGES", messages);
  }
};

const mutations = {
  SET_MESSAGES(state, messages) {
    state.messages = messages;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
