import administration from "./administration";
import teaching from "./teaching";
import learning from "./learning";
import mentorship from "./mentorship";
import general from "./general";
import middleware from "../middleware";

export default [
  ...general,
  teaching,
  administration,
  learning,
  mentorship,
  {
    path: "/tests",
    component: () => import("@/components/side-menu/TheSideMenu"),
    children: [
      {
        path: "upload-file",
        component: () => import("@/views/tests/TestUploadFile")
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      middleware: [middleware.auth]
    },
    component: () => import("@/views/general/NotFoundPage")
  }
];
