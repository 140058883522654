const state = () => {
  return {
    criteria: []
  };
};

const getters = {
  criteria: state => state.criteria,
  getCriterion: state => index => state.criteria[index]
};

const actions = {
  setCriteria({ commit }, criteria) {
    commit("SET_CRITERIA", criteria);
  },
  addCriterion({ commit }, fieldValue = "") {
    commit("ADD_CRITERION", fieldValue);
  },
  removeCriterion({ commit }, index) {
    commit("REMOVE_CRITERION", index);
  },
  setCriterion({ commit }, criterion) {
    commit("SET_CRITERION", criterion);
  },
  setCriterionField({ commit }, { index, value }) {
    commit("SET_CRITERION", {
      index,
      value: { f: value }
    });
  },
  setCriterionComparator({ commit }, { index, value }) {
    commit("SET_CRITERION", {
      index,
      value: { c: value }
    });
  },
  setCriterionValue({ commit }, { index, value }) {
    commit("SET_CRITERION", {
      index,
      value: { v: value }
    });
  }
};

const mutations = {
  SET_CRITERIA(state, criteria) {
    state.criteria = criteria;
  },
  ADD_CRITERION(state, fieldValue) {
    state.criteria.push({
      f: fieldValue,
      c: "LIKE",
      v: ""
    });
  },
  REMOVE_CRITERION(state, index) {
    state.criteria.splice(index, 1);
  },
  SET_CRITERION(state, { index, value }) {
    state.criteria[index] = {
      ...state.criteria[index],
      ...value
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
