const state = () => {
  return {
    // actions
    ban: "fal fa-ban",
    add: "fal fa-plus",
    edit: "fal fa-edit",
    delete: "fal fa-trash-alt",
    pin: "fas fa-thumbtack",
    unpin: "fal fa-thumbtack",
    lock: "fal fa-lock-alt",
    unlock: "fal fa-lock-open-alt",
    personify: "fal fa-user-crown",
    license: "fal fa-certificate",
    search: "fal fa-search",
    download: "fal fa-file-download",
    help: "fal fa-question",
    duplicate: "fal fa-copy",
    "new-version": "fal fa-layer-plus",
    expand: "fal fa-chevron-down",
    "expand-all": "fal fa-chevron-double-down",
    collapse: "fal fa-chevron-up",
    "collapse-all": "fal fa-chevron-double-up",
    save: "fal fa-save",
    correct: "fal fa-check",
    incorrect: "fal fa-times",
    move: "fal fa-grip-lines",
    redirect: "fal fa-external-link",
    "add-feedback": "fal fa-comment-alt-lines",
    "add-rating": "fal fa-star",
    "mark-as-unread": "fal fa-envelope",
    "mark-as-read": "fal fa-envelope-open",
    refresh: "fal fa-sync",
    tag: "fal fa-tag",
    // tabs
    overview: "fal fa-tachometer-alt",
    details: "fal fa-edit",
    questions: "fal fa-tasks",
    logs: "fal fa-list-ul",
    preview: "fal fa-eye",
    attempts: "fal fa-check-double",
    usage: "fal fa-bezier-curve",
    requirements: "fal fa-clipboard-list-check",
    submissions: "fal fa-file-check",
    memberships: "fal fa-user-tag",
    instructors: "fal fa-chalkboard-teacher",
    outline: "fal fa-ballot",
    roles: "fal fa-clipboard-user",
    feedback: "fal fa-bullhorn",
    progress: "fal fa-chart-line",
    trainings: "fal fa-user-chart",
    collections: "fal fa-analytics",
    collection: "fal fa-analytics",
    "ui-settings": "fal fa-cog",
    // resources
    learners: "fal fa-users-class",
    registrations: "fal fa-file-user",
    competencies: "fal fa-head-side-brain",
    attestations: "fal fa-file-certificate",
    evaluations: "fal fa-clipboard-check",
    lessons: "fal fa-presentation",
    departments: "fal fa-users",
    users: "fal fa-users-cog",
    sites: "fal fa-city"
  };
};

const getters = {
  getIcon: state => icon => state[icon]
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
