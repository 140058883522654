import cash from "cash-dom";
import TomSelect from "tom-select";

const setValue = (el, props) => {
  if (props.modelValue.length) {
    cash(el).val(props.modelValue);
  }
};

const init = (el, emit, computedOptions) => {
  el.TomSelect = new TomSelect(el, computedOptions);
  el.TomSelect.on("change", function(selectedItems) {
    emit("update:modelValue", selectedItems);
  });
};

const addOptions = (el, options = []) => {
  el.TomSelect.addOption(options);
};

const addItem = (el, value) => {
  if (Array.isArray(value)) {
    value.forEach(v => {
      el.TomSelect.addItem(v);
    });
  }

  el.TomSelect.addItem(value);
};

const checkDisabled = (el, disabled) => {
  if (disabled) {
    el.TomSelect.disable();
    return;
  }

  el.TomSelect.enable();
};

const reInit = (el, props, emit, computedOptions) => {
  setValue(el, props);
  addOptions(el, computedOptions?.options);
  addItem(el, props.modelValue);
  checkDisabled(el, props.disabled);
};

export { setValue, init, reInit };
