import axios from "axios";
import get from "lodash/get";
import endpoints from "@/constants/endpoints";

const state = () => {
  return {
    endpoints: {
      api_endpoints: null,
      client: {
        url: "https://clients.chexpertise.com/?client=staging",
        method: "get"
      },
      ...endpoints
    },
    identification: null
  };
};

const getters = {
  baseURL: state => state.api_endpoints,
  getEndpoint: state => (endpoint, pathParams = {}) => {
    if (endpoint === "client") {
      return state.endpoints.client;
    }

    const config = endpoint.split(".").reduce((acc, cv, index, arr) => {
      let { url, method } = get(
        state.endpoints,
        arr.slice(0, index + 1).join(".")
      );

      url = url.replace(
        /(\{.*?\})/g,
        match => pathParams[match.replace(/[{}]/g, "")]
      );

      if (acc.url) {
        url = url ? `${acc.url}/${url}` : acc.url;
      }

      return { ...acc, url, method };
    }, {});

    return {
      ...config,
      url: `${state.api_endpoints}/${config?.url}`
    };
  },
  // Identification
  client: state => state.identification?.client,
  company: state => state.identification?.company,
  environment: state => state.identification?.environment,
  learning_hub: state => state.identification?.learning_hub,
  name: state => state.identification?.name,
  support_site: state => state.identification?.support_site,
  timezone: state => state.identification?.timezone,
  url: state => state.identification?.url,
  version: state => state.identification?.version
};

const actions = {
  setEndpoint({ commit }, endpoint) {
    commit("SET_ENDPOINT", endpoint);
  },
  setIdentification({ commit }, identification) {
    commit("SET_IDENTIFICATION", identification);
  },
  async requestExternal(context, data) {
    try {
      return await axios(data);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  },
  async request(
    context,
    { endpoint, pathParams, queryParams = {}, data, toast, router }
  ) {
    let config = context.getters.getEndpoint(endpoint, pathParams);
    let query = queryParams;

    const api_token = context.rootGetters["user/api_token"];
    const token_verified = context.rootGetters["user/token_verified"];
    if (token_verified && api_token) {
      query = {
        ...query,
        api_token
      };
    }

    query = new URLSearchParams(query).toString();
    if (query) {
      config = {
        ...config,
        url: `${config.url}?${query}`
      };
    }

    try {
      const response = await axios({ ...config, data, router });

      if (toast) {
        toast.showAPIMessage(response?.data);
      }

      axios.interceptors.response.use(
        response => response,
        error => {
          const router = error?.response?.config?.router;
          const status = error?.response?.status;

          if (router) {
            switch (status) {
              case 404:
                router.replace({ name: "error-page" });
                break;
              case 403:
                router.replace({ name: "unauthorized" });
                break;
              default:
            }
          }

          return Promise.reject(error);
        }
      );

      return response?.data;
    } catch (error) {
      if (toast && error?.response?.data) {
        toast.showAPIErrors(error?.response?.data);
      }

      // console.error(error);
    }
  }
};

const mutations = {
  SET_ENDPOINT(state, { endpoint, value }) {
    state[endpoint] = value;
  },
  SET_IDENTIFICATION(state, identification) {
    state.identification = identification;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
