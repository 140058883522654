import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "lessons",
    name: "teaching-lessons",
    meta: {
      title: t("app.lessons", 2)
    },
    redirect: { name: "teaching-lessons-search" },
    component: () => import("@/views/teaching/lessons/LessonView"),
    children: [
      {
        path: "",
        name: "teaching-lessons-search",
        component: () => import("@/views/teaching/lessons/IndexPage")
      },
      {
        path: "add",
        name: "teaching-lessons-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/teaching/lessons/CreatePage")
      }
    ]
  },
  {
    path: "lessons/:id",
    name: "teaching-lessons-update",
    meta: {
      parentName: "teaching-lessons",
      title: t("app.lessons", 2)
    },
    redirect: { name: "teaching-lessons-overview" },
    props: true,
    component: () => import("@/views/teaching/lessons/Lesson"),
    children: [
      {
        path: "overview",
        name: "teaching-lessons-overview",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/lessons/overview/OverviewPage")
      },
      {
        path: "details",
        name: "teaching-lessons-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/lessons/DetailsPage")
      },
      {
        path: "learners",
        name: "teaching-lessons-learners",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/lessons/LearnersPage")
      },
      {
        path: "usage",
        name: "teaching-lessons-usage",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/lessons/UsagePage")
      },
      {
        path: "feedback",
        name: "teaching-lessons-feedback",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/lessons/FeedbackPage")
      },
      {
        path: "logs",
        name: "teaching-lessons-logs",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/lessons/LogsPage")
      }
    ]
  }
];
