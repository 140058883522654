<!-- eslint-disable -->
<template>
  <VModal v-if="isModalVisible" id="intro" size="modal-xl">
    <template #content>
      <div class="grid grid-cols-2">
        <div class="flex justify-center items-center">
          <img alt="intro" src="@/assets/images/intro.svg" />
        </div>

        <div class="mx-6 mb-8">
          <div class="text-right cursor-pointer" @click="onClickHideModal">
            <XIcon class="w-8 h-8 text-gray-500" />
          </div>

          <TinySlider
            :options="{
              autoplay: false,
              controls: true,
              items: 1,
              nav: true
            }"
          >
            <div
              v-for="(slide, index) in slides"
              :key="index"
              class="h-96 px-2"
            >
              <div class="h-full rounded-md px-2">
                <h3 class="mt-10 font-medium text-xl">
                  {{ slide.title }}
                </h3>

                <h5 class="mt-1 text-gray-500 text-base">
                  {{ slide.subtitle }}
                </h5>

                <div
                  class="mt-5 text-justify text-gray-600 text-base"
                  v-html="slide.msg"
                />
              </div>
            </div>
          </TinySlider>
        </div>
      </div>
    </template>
  </VModal>
</template>

<script>
import { onMounted, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import useRequest from "@/composables/useRequest";
import useOptions from "@/composables/useOptions";
import useModal from "@/composables/useModal";
// Components
import VModal from "@/components/modals/VModal";

export default {
  components: {
    VModal
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const store = useStore();

    // Composables
    const { isModalVisible, showModal, hideModal } = useModal("#intro");
    const { request } = useRequest();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const showIntro = computed(() => store.getters["app/showIntro"]);

    // Constants
    const slides = [
      {
        title: t("intro.welcome_title"),
        subtitle: t("intro.welcome_subtitle"),
        msg: t("intro.welcome_msg")
      },
      {
        title: t("intro.settings_title"),
        subtitle: t("intro.settings_subtitle"),
        msg: t("intro.settings_msg")
      },
      {
        title: t("intro.competency_title"),
        subtitle: t("intro.competency_subtitle"),
        msg: t("intro.competency_msg")
      },
      {
        title: t("intro.evaluation_title"),
        subtitle: t("intro.evaluation_subtitle"),
        msg: t("intro.evaluation_msg")
      },
      {
        title: t("intro.lesson_title"),
        subtitle: t("intro.lesson_subtitle"),
        msg: t("intro.lesson_msg")
      },
      {
        title: t("intro.attestation_title"),
        subtitle: t("intro.attestation_subtitle"),
        msg: t("intro.attestation_msg")
      },
      {
        title: t("intro.log_title"),
        subtitle: t("intro.log_subtitle"),
        msg: t("intro.log_msg")
      }
    ];

    // Methods
    const showIntroModal = () => {
      if (showIntro.value) {
        showModal();
      }
    };

    const updateIntroSetting = async () => {
      await request({
        endpoint: "my.ui-settings",
        data: {
          intro: ALL_OPTIONS.NO.value
        },
        showToaster: false
      });
    };

    const onClickHideModal = async () => {
      hideModal();
      hideShowIntro();
      await updateIntroSetting();
    };

    const hideShowIntro = () => {
      store.dispatch("app/setIntro", false);
    };

    // Lifecycle Hooks
    onMounted(() => {
      showIntroModal();
    });

    // Watch
    watch(
      () => showIntro.value,
      () => {
        showIntroModal();
      }
    );

    return {
      slides,
      onClickHideModal,
      // useModal
      isModalVisible,
      showModal,
      hideModal
    };
  }
};
</script>
