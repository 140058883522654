import { ref, watch } from "vue";

export default function useModal(id = "#modal") {
  // Data
  const isModalVisible = ref(false);

  // Methods
  const show = () => {
    cash(id).modal("show");
  };

  const hide = () => {
    cash(id).modal("hide");
  };

  const showModal = () => {
    isModalVisible.value = true;
  };

  const hideModal = () => {
    hide();

    setTimeout(() => {
      isModalVisible.value = false;
    }, 500);
  };

  // Watch
  watch(isModalVisible, () => {
    if (isModalVisible.value) {
      setTimeout(show, 100);
      return;
    }
  });

  return {
    isModalVisible,
    showModal,
    hideModal
  };
}
