const defaultValue = {
  current_page: 1,
  per_page: 10,
  filters: []
};

const state = () => {
  return {
    route: "",
    search: null,
    queries: { ...defaultValue }
  };
};

const getters = {
  queries: state => state.queries,
  current_page: state => state.queries.current_page,
  per_page: state => state.queries.per_page,
  filters: state => state.queries.filters,
  search: state => state.search,
  route: state => state.route
};

const actions = {
  setCurrentPage({ commit }, current_page) {
    commit("SET_CURRENT_PAGE", current_page);
  },
  setPerPage({ commit }, per_page) {
    commit("SET_PER_PAGE", per_page);
  },
  setFilters({ commit }, filters) {
    commit("SET_FILTERS", filters);
  },
  setSearch({ commit }, search) {
    commit("SET_SEARCH", search);
  },
  setRoute({ commit }, route) {
    commit("SET_ROUTE", route);
  },
  clearQueries({ commit }) {
    commit("CLEAR_QUERIES");
  }
};

const mutations = {
  SET_CURRENT_PAGE(state, current_page) {
    state.queries.current_page = current_page;
  },
  SET_PER_PAGE(state, per_page) {
    state.queries.per_page = per_page;
  },
  SET_FILTERS(state, filters) {
    state.queries.filters = filters;
  },
  SET_SEARCH(state, search) {
    state.search = search;
    state.queries.current_page = 1;
  },
  SET_ROUTE(state, route) {
    state.route = route;
  },
  CLEAR_QUERIES(state) {
    state.queries = { ...defaultValue };
    state.search = null;
    state.route = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
