import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "quizzes",
    name: "teaching-evaluations-quizzes",
    meta: {
      parentName: "teaching-evaluations",
      title: t("app.quizzes", 2)
    },
    redirect: { name: "teaching-evaluations-quizzes-search" },
    component: () => import("@/views/teaching/evaluations/quizzes/QuizView"),
    children: [
      {
        path: "",
        name: "teaching-evaluations-quizzes-search",
        component: () =>
          import("@/views/teaching/evaluations/quizzes/IndexPage")
      },
      {
        path: "add",
        name: "teaching-evaluations-quizzes-create",
        meta: {
          title: t("app.add")
        },
        component: () =>
          import("@/views/teaching/evaluations/quizzes/CreatePage")
      }
    ]
  },
  {
    path: "quizzes/:id",
    name: "teaching-evaluations-quizzes-update",
    meta: {
      parentName: "teaching-evaluations-quizzes",
      title: t("app.quizzes", 2)
    },
    props: true,
    redirect: { name: "teaching-evaluations-quizzes-overview" },
    component: () => import("@/views/teaching/evaluations/quizzes/Quiz"),
    children: [
      {
        path: "overview",
        name: "teaching-evaluations-quizzes-overview",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/quizzes/OverviewPage")
      },
      {
        path: "details",
        name: "teaching-evaluations-quizzes-details",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/quizzes/DetailsPage")
      },
      {
        path: "usage",
        name: "teaching-evaluations-quizzes-usage",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/quizzes/UsagePage")
      },
      {
        path: "attempts",
        name: "teaching-evaluations-quizzes-attempts",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/quizzes/AttemptsPage")
      },
      {
        path: "attempts/:attemptId",
        name: "teaching-evaluations-quizzes-attempts-details",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/quizzes/AttemptPage")
      },
      {
        path: "preview",
        name: "teaching-evaluations-quizzes-preview",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/quizzes/PreviewPage")
      },
      {
        path: "feedback",
        name: "teaching-evaluations-quizzes-feedback",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/quizzes/FeedbackPage")
      },
      {
        path: "logs",
        name: "teaching-evaluations-quizzes-logs",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () => import("@/views/teaching/evaluations/quizzes/LogsPage")
      }
    ]
  }
];
