import auth from "./auth";
import unauth from "./unauth";
import admin from "./admin";
import learning from "./learning";
import teaching from "./teaching";

export default {
  auth,
  unauth,
  admin,
  learning,
  teaching
};
