import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "observations",
    name: "teaching-evaluations-observations",
    meta: {
      parentName: "teaching-evaluations",
      title: t("app.observations", 2)
    },
    redirect: { name: "teaching-evaluations-observations-search" },
    component: () =>
      import("@/views/teaching/evaluations/observations/ObservationView"),
    children: [
      {
        path: "",
        name: "teaching-evaluations-observations-search",
        component: () =>
          import("@/views/teaching/evaluations/observations/IndexPage")
      },
      {
        path: "add",
        name: "teaching-evaluations-observations-create",
        meta: {
          title: t("app.add")
        },
        component: () =>
          import("@/views/teaching/evaluations/observations/CreatePage")
      }
    ]
  },
  {
    path: "observations/:id",
    name: "teaching-evaluations-observations-update",
    meta: {
      parentName: "teaching-evaluations-observations",
      title: t("app.observations", 2)
    },
    props: true,
    redirect: { name: "teaching-evaluations-observations-overview" },
    component: () =>
      import("@/views/teaching/evaluations/observations/Observation"),
    children: [
      {
        path: "overview",
        name: "teaching-evaluations-observations-overview",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/observations/OverviewPage")
      },
      {
        path: "details",
        name: "teaching-evaluations-observations-details",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/observations/DetailsPage")
      },
      {
        path: "usage",
        name: "teaching-evaluations-observations-usage",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/observations/UsagePage")
      },
      {
        path: "attempts",
        name: "teaching-evaluations-observations-attempts",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/observations/AttemptsPage")
      },
      {
        path: "attempts/:attemptId",
        name: "teaching-evaluations-observations-attempts-details",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/observations/AttemptPage")
      },
      {
        path: "preview",
        name: "teaching-evaluations-observations-preview",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/observations/PreviewPage")
      },
      {
        path: "feedback",
        name: "teaching-evaluations-observations-feedback",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/observations/FeedbackPage")
      },
      {
        path: "logs",
        name: "teaching-evaluations-observations-logs",
        meta: {
          title: t("app.edit")
        },
        props: true,
        component: () =>
          import("@/views/teaching/evaluations/observations/LogsPage")
      }
    ]
  }
];
