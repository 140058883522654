import { createI18n } from "vue-i18n";
import en from "./en";
import fr from "./fr";

export default createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  fallbackLocale: "en",
  messages: {
    en,
    fr
  }
});
