import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "attestations",
    name: "teaching-attestations",
    meta: {
      title: t("app.attestations", 2)
    },
    redirect: { name: "teaching-attestations-search" },
    component: () => import("@/views/teaching/attestations/AttestationView"),
    children: [
      {
        path: "",
        name: "teaching-attestations-search",
        component: () => import("@/views/teaching/attestations/IndexPage")
      },
      {
        path: "add",
        name: "teaching-attestations-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/teaching/attestations/CreatePage")
      }
    ]
  },
  {
    path: "attestations/:id",
    name: "teaching-attestations-update",
    meta: {
      parentName: "teaching-attestations",
      title: t("app.attestations", 2)
    },
    redirect: { name: "teaching-attestations-overview" },
    props: true,
    component: () => import("@/views/teaching/attestations/Attestation"),
    children: [
      {
        path: "overview",
        name: "teaching-attestations-overview",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/attestations/overview/OverviewPage")
      },
      {
        path: "details",
        name: "teaching-attestations-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/attestations/DetailsPage")
      },
      {
        path: "submissions",
        name: "teaching-attestations-submissions",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/attestations/SubmissionsPage")
      },
      {
        path: "submissions/:submissionId",
        name: "teaching-attestations-submissions-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/attestations/SubmissionPage")
      },
      {
        path: "usage",
        name: "teaching-attestations-usage",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/attestations/UsagePage")
      },
      {
        path: "logs",
        name: "teaching-attestations-logs",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/attestations/LogsPage")
      }
    ]
  }
];
