import i18n from "@/i18n";
import middleware from "@/router/middleware";
import users from "./users";
import roles from "./roles";
import sites from "./sites";
import departments from "./departments";
import licenses from "./licenses";

const t = i18n.global.t;

export default {
  path: "/administration",
  name: "administration",
  redirect: { name: "administration-dashboard" },
  meta: {
    middleware: [middleware.auth, middleware.admin],
    title: t("app.administration", 2)
  },
  component: () => import("@/components/side-menu/TheSideMenu"),
  children: [
    {
      path: "dashboard",
      name: "administration-dashboard",
      meta: {
        title: t("app.dashboard", 2)
      },
      component: () => import("@/views/administration/dashboard/DashboardPage")
    },
    ...users,
    ...roles,
    ...sites,
    ...departments,
    ...licenses,
    {
      path: "import-trainings",
      name: "administration-import-trainings",
      meta: {
        title: t("app.import_trainings", 2)
      },
      component: () => import("@/views/administration/ImportTrainingsPage")
    },
    {
      path: "integrations",
      name: "administration-integrations",
      meta: {
        title: t("app.integrations", 2)
      },
      component: () =>
        import("@/views/administration/integrations/IntegrationsPage")
    },
    {
      path: "logs",
      name: "administration-logs",
      meta: {
        title: t("app.logs", 2)
      },
      component: () => import("@/views/administration/logs/IndexPage"),
      props: true
    }
  ]
};
