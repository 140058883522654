import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "sites",
    name: "administration-sites",
    meta: {
      title: t("app.sites", 2)
    },
    redirect: { name: "administration-sites-search" },
    component: () => import("@/views/administration/sites/SiteView"),
    children: [
      {
        path: "",
        name: "administration-sites-search",
        component: () => import("@/views/administration/sites/IndexPage")
      },
      {
        path: "add",
        name: "administration-sites-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/administration/sites/CreatePage")
      }
    ]
  },
  {
    path: "sites/:id",
    name: "administration-sites-update",
    meta: {
      parentName: "administration-sites",
      title: t("app.sites", 2)
    },
    redirect: { name: "administration-sites-details" },
    component: () => import("@/views/administration/sites/SiteView"),
    children: [
      {
        path: "details",
        name: "administration-sites-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/administration/sites/ReadPage")
      }
    ]
  }
];
