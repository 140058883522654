const getData = async context => {
  const { api_endpoints, app, client } = await context.dispatch(
    "api/request",
    { endpoint: "client" },
    { root: true }
  );

  context.dispatch("client/setClient", client, { root: true });
  context.commit("SET_APP", app);
  context.dispatch(
    "api/setEndpoint",
    {
      endpoint: "api_endpoints",
      value: api_endpoints
    },
    { root: true }
  );
};

const getStorageData = async context => {
  const remember_me = getStorageDataItem("remember_me") === "true";
  context.dispatch("user/setRememberMe", remember_me, { root: true });

  const data = getStorageDataItem("user");

  if (data) {
    const user = JSON.parse(data);
    await context.dispatch(
      "user/login",
      {
        data: {
          username: user.username,
          api_token: user.api_token
        }
      },
      { root: true }
    );

    if (!context.rootGetters["user/token_verified"]) {
      localStorage.removeItem("user");
      sessionStorage.removeItem("user");
    }
  }
};

const getStorageDataItem = item => {
  return localStorage.getItem(item) || sessionStorage.getItem(item);
};

const state = () => {
  return {
    hasLoaded: false,
    app: null,
    showHints: false,
    showIntro: false
  };
};

const getters = {
  client: () => "staging",
  app: state => state.app,
  "2fa_enabled": state => state.app["2fa_enabled"],
  anonymize_enabled: state => state.app.anonymize_enabled,
  available_languages: state => state.app?.available_languages,
  available_modules: state => state.app?.available_modules ?? [],
  date_format: state => state.app?.date_format,
  default_language: state => state.app?.default_language,
  default_theme: state => state.app?.default_theme,
  max_upload_size: state => {
    const size = state.app?.max_upload_size ?? "10MB";

    return parseInt(size.replace("MB", ""));
  },
  sms_enabled: state => state.app?.sms_enabled,
  verify_by: state => state.app?.verify_by ?? "password",
  version: state => state.app?.version,
  pusher: state => state.app?.pusher,
  hasLoaded: state => state.hasLoaded,
  showHints: state => state.showHints,
  showIntro: state => state.showIntro
};

const actions = {
  setApp({ commit }, app) {
    commit("SET_APP", app);
  },
  async loadApp(context) {
    await getData(context);
    await getStorageData(context);

    context.commit("SET_HAS_LOADED", true);
  },
  setHints({ commit }, value) {
    commit("SET_HINTS", value);
  },
  setIntro({ commit }, value) {
    commit("SET_INTRO", value);
  },
  toggleShowHints({ commit }) {
    commit("TOGGLE_HINTS");
  },
  toggleShowIntro({ commit }) {
    commit("TOGGLE_INTRO");
  }
};

const mutations = {
  SET_APP(state, app) {
    state.app = app;
  },
  SET_HAS_LOADED(state, hasLoaded) {
    state.hasLoaded = hasLoaded;
  },
  SET_HINTS(state, value) {
    state.showHints = value;
  },
  SET_INTRO(state, value) {
    state.showIntro = value;
  },
  TOGGLE_HINTS(state) {
    state.showHints = !state.showHints;
  },
  TOGGLE_INTRO(state) {
    state.showIntro = !state.showIntro;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
