import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "trainings",
    name: "teaching-trainings",
    meta: {
      title: t("app.trainings", 2)
    },
    redirect: { name: "teaching-trainings-search" },
    component: () => import("@/views/teaching/trainings/ResourceView"),
    children: [
      {
        path: "",
        name: "teaching-trainings-search",
        component: () => import("@/views/teaching/trainings/IndexPage")
      },
      {
        path: "add",
        name: "teaching-trainings-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/teaching/trainings/CreatePage")
      }
    ]
  },
  {
    path: "trainings/:id",
    name: "teaching-trainings-update",
    meta: {
      parentName: "teaching-trainings",
      title: t("app.trainings", 2)
    },
    props: true,
    redirect: { name: "teaching-trainings-overview" },
    component: () => import("@/views/teaching/trainings/Resource"),
    children: [
      {
        path: "overview",
        name: "teaching-trainings-overview",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/trainings/OverviewPage")
      },
      {
        path: "details",
        name: "teaching-trainings-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/trainings/DetailsPage")
      },
      {
        path: "registrations",
        name: "teaching-trainings-registrations",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/trainings/RegistrationsPage")
      },
      {
        path: "usage",
        name: "teaching-trainings-usage",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/trainings/UsagePage")
      },
      {
        path: "feedback",
        name: "teaching-trainings-feedback",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/trainings/FeedbackPage")
      },
      {
        path: "logs",
        name: "teaching-trainings-logs",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/trainings/LogsPage")
      }
    ]
  }
];
