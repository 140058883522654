export default {
  app: {
    filters: "Filter | Filters",
    global_search_results: "Global Search Results",
    results_found_in: `Global search results found in {module} {submodule} with "{term}"`,
    show_help_manual: "Show Help  Manual",
    show_intro: "Show Intro",
    hide_intro: "Hide Intro",
    show_hints: "Show Hints",
    hide_hints: "Hide Hints",
    trainings_registered: "Trainings Registered",
    trainings_available: "Trainings Available",
    collection_registered: "Collections Registered",
    collection_available: "Collections Available",
    tagged_resources: "Tagged Resources",
    no_import_trainings: `Please contact your Chexpertise account manager in order to activate SCORM compliant training imports created by Adobe Captivate&trade; or Articulate Storyline&trade;.`,
    acknowledge_lesson_failure: "You have failed to acknowledge the lesson.",
    acknowledge_lesson_success: `You have successfully acknowledged the lesson.`,
    acquiring: "Acquiring",
    action: "Action",
    actions: "Actions",
    activated_on: "Activated on {date}",
    activating_lesson: `Activating this version will archive the current active version.`,
    add_answer: "Add answer",
    add_attestation: "Add attestation",
    add_collection: "Add collection",
    add_competency: "Add competency",
    add_criteria: "Add criteria",
    add_criterion: "Add criterion",
    add_department: "Add department",
    add_feedback: "Add feedback",
    add_filter: "Add filter",
    add_instructor: "Add instructor",
    add_lesson: "Add lesson",
    add_new_attestation: "Add new attestation",
    add_new_collection: "Add new collection",
    add_new_competency: "Add new competency",
    add_new_department: "Add new department",
    add_new_lesson: "Add new lesson",
    add_new_observation: "Add new observation",
    add_new_quiz: "Add new quiz",
    add_new_registration: "Add new registration",
    add_new_role: "Add new role",
    add_new_site: "Add new site",
    add_new_training: "Add new training",
    add_new_user: "Add new user",
    add_new_version: "Add new version",
    add_observation: "Add observation",
    add_question: "Add question",
    add_quiz: "Add quiz",
    add_rating_name_success_msg: `You have successfully added a rating of <span class='font-medium'>{ rating }</span> on <span class='font-medium'>{ name }</span>`,
    add_rating_success_msg: `You have successfully added a rating of <span class='font-medium'>{ rating }</span>`,
    add_rating: "Add rating",
    add_registration: "Add registration",
    add_requirement: "Add requirement",
    add_role: "Add role",
    add_saved_search: "Add saved search",
    add_site: "Add site",
    add_training: "Add training",
    add_user: "Add user",
    add: "Add",
    added_on: "Added on",
    address: "Address",
    administration: "Administration",
    advanced_search: "Advanced search",
    all_competencies: "All competencies",
    all_languages: "All languages",
    all_statuses: "All statuses",
    all_submissions: "All submissions",
    all_versions: "All versions",
    all: "All",
    and: "And",
    answer_type: "Answer type",
    answers: "Answer | Answers",
    api_access: "API access",
    api_token: "API token",
    approaching_deadline: "Approaching deadline",
    approval_date: "Approval date",
    approvals: "Approvals",
    approve_all: "Approve all",
    approve_by: "Approve by",
    approve: "Approve",
    approved_by: "Approved by",
    approved_on: "Approved on",
    approver_id: "Approver",
    april: "April",
    archived_on: "Archived on {date}",
    archiving_lesson: `Archiving an active version will make it unavailable to all trainings where this is used.`,
    are_you_sure: "Are you sure?",
    assign_competencies_upon: "Assign competencies upon",
    assign_comptencies_before_approval: "Assign competencies before approval",
    assigned: "Assigned",
    attempt_interval_in_days: "Attempt interval in days",
    attempts: "Attempt | Attempts",
    attestation_overview: "Attestation overview",
    attestations: "Attestation | Attestations",
    august: "August",
    author: "Author",
    automatic_registration: "Automatic registration",
    available_date: "Available date",
    available_from: "Available from",
    available_registrations: "Available registrations",
    available_to: "Available to",
    available_until: "Available until",
    back_to_home: "Back to home",
    back: "Back",
    bank_account: "Bank account",
    by_signing_up: "By signing in, you agree to our",
    cancel: "Cancel",
    cancelled_on: "Cancelled on",
    certificate_file_id: "Certificate",
    certificate_file: "Certificate file",
    certificate_template: "Certificate template",
    certificate_valid_until: "Certificate valid until",
    change_log: "Change log",
    change_password: "Change password",
    change: "Change",
    changed_user_password: `Password changed successfully for user <span class="font-medium">{name}</span>.`,
    click_here: "Click here",
    client_details: "Client details",
    code: "Code",
    collapse_all: "Collapse all",
    collapse: "Collapse",
    collection_id: "Collection",
    collection_registration_failure: `You have failed to register to this collection.`,
    collection_registration_success: `You have sucessfully registered to this collection.`,
    collection_registrations: "Collection registrations",
    collections_approaching_deadline: "Collections approaching deadline",
    collections: "Collection | Collections",
    colour: "Color",
    comments: "Comments",
    competencies_by_department: "Competencies by department",
    competencies_by_role: "Competencies by role",
    competencies: "Competency | Competencies",
    competency_matrix: "Competency matrix",
    competency_name: "Competency name",
    competency_score_progression: "Competency score progression",
    competency_score: "Competency score",
    competency_statuses: "Competency statuses",
    complete_lesson_password_msg: `Please enter your password to confirm that you completed the lesson.`,
    complete_lesson_pin_msg: `Please enter your PIN to confirm that you completed the lesson.`,
    complete_lesson: "Complete lesson",
    completed_on: "Completed on",
    completed: "Completed",
    completion_approval_requested_on: "Completion approval requested on",
    completion_approved_by: "Completion approved by",
    completion_approved_on: "Completion approved on",
    completion_approved: "Completion approved",
    completion_approver_comments: "Completion approver comments",
    completion_approver: "Completion approver",
    completion_comments: "Completion comments",
    completion_date: "Completion date",
    completion_deadline_days_new_learners: `Completion deadline for new learners (days)`,
    completion_deadline_days: "Completion deadline (days)",
    completion_deadline: "Completion deadline",
    completion_requested_on: "Completion requested on",
    completion_score_progression: "Completion score progression",
    completion_score: "Completion score",
    completion_time: "Completion time",
    completions: "Completion | Completions",
    confirm_password_msg: "Please enter your password to confirm your request.",
    confirm_password: "Confirm password",
    confirm: "Confirm",
    confirmed_new_password: "Confirmed new password",
    contact_details: "Contact details",
    content_location: "Content location",
    coordinator: "Coordinator",
    copied: "Copied to clipboard!",
    correct_answer: "Correct answer",
    count_towards_maximum: "Count towards maximum",
    create_new_version: "Create new version",
    created_on: "Created on",
    credits: "Credits",
    criteria: "Criteria",
    current_score: "Current score",
    daily_completions: "Daily completion | Daily completions",
    dashboard: "Dashboard",
    data: "Data",
    date_activated: "Date activated",
    date_archived: "Date archived",
    date: "Date",
    days_valid: "Days valid",
    days: "Days",
    december: "December",
    default_answer_type: "Default answer type",
    default_approve_by: "Default approve by",
    default_assign_competencies_upon: "Default assign competencies upon",
    default_maximum_failed_attempts: "Default maximum failed attempts",
    default_submit_by: "Default submit by",
    delete_item: `Do you really want to delete <span class="font-medium">{name}</span>? <br /> this process cannot be undone.`,
    delete_msg: `Do you really want to delete this? <br /> this process cannot be undone.`,
    delete_relationship_msg: `Do you really want to remove this? <br /> this process cannot be undone.`,
    delete_relationship: `Do you really want to remove <span class="font-medium">{name}</span> from <span class="font-medium">{relationship}</span>? <br /> this process cannot be undone.`,
    delete_text_msg: `Do you really want to remove the texts? <br /> this process cannot be undone.`,
    delete_text: `Do you really want to remove the texts in <span class="font-medium">{name}</span>? <br /> this process cannot be undone.`,
    delete: "Delete",
    department_id: "Department",
    department_users: "Department users",
    departments: "Department | Departments",
    description: "Description",
    designations: "Designations",
    details: "Details",
    disabled_new_version_msg: `Can only create a new version from an active version`,
    display_answers: "Display answers",
    display_correct_answers: "Display correct answers",
    download_audio: "Download audio",
    download_document: "Download document",
    download_slides: "Download slides",
    download: "Download",
    drag: "Drag",
    due_soon: "Due soon",
    duplicate: "Duplicate",
    duration_type: "Duration type",
    duration: "Duration",
    edit_competency: "Edit competency",
    edit_department: "Edit department",
    edit_lesson: "Edit lesson",
    edit_profile: "Edit profile",
    edit_question: "Edit question",
    edit_requirement: "Edit requirement",
    edit_role: "Edit role",
    edit_site: "Edit site",
    edit_user: "Edit user",
    edit: "Edit",
    email_enabled: "Email notifications enabled",
    email_notification: "Email notification",
    email: "Email",
    end_date: "End date",
    equal: "Equal",
    evaluation_results: "Evaluation results",
    evaluations: "Evaluation | Evaluations",
    events: "Event | Events",
    expand_all: "Expand all",
    expand: "Expand",
    expired_competencies: "Expired competencies",
    expires_on: "Expires on {date}",
    expiring_competencies: "Expiring competency | Expiring competencies",
    export_results: "Export results",
    external_code: "External code",
    external_registrations: "External registrations",
    february: "February",
    feedback: "Feedback",
    field: "Field",
    file_id: "File",
    file_ids: "Files",
    files: "Files",
    firstname: "First name",
    forgot_password: "Forgot password?",
    grade_distribution: "Grade distribution",
    grade: "Grade",
    greater_equal: "Greater or equal",
    greater: "Greater",
    has_active_usage_msg: "This is being used by active trainings",
    home: "Home",
    hours: "Hours",
    image_file_id: "Image",
    import_trainings: "Import trainings",
    in: "In",
    instructor_comment: "Instructor comment",
    instructor_comments: "Instructor comments",
    instructor_password: "Instructor password",
    instructor_pin: "Instructor PIN",
    instructors: "Instructors",
    integrations: "Integrations",
    interval_after_prerequisite: "Interval after prerequisite",
    interval_between_attempts: "Interval between attempts",
    invoices: "Invoice | Invoices",
    ip_address: "IP address",
    items_require_attention_msg: "Items require attention",
    items_requiring_attention_msg: `Items requiring attention available in their respective area`,
    january: "January",
    july: "July",
    june: "June",
    language: "Language",
    languages: "Language | Languages",
    last_28_days: "Last 28 days",
    last_changed: "Last changed",
    last_modified_on: "Last modified on",
    lastname: "Last name",
    latest_feedback: "Latest feedback",
    latest_submissions: "Latest submissions",
    learner_comment: "Learner comment",
    learner_comments: "Learner comments",
    learner_completed_on: "Learner completed on",
    learner_completed: "Learner completed",
    learner_password: "Learner password",
    learner_pin: "Learner PIN",
    learners: "Learner | Learners",
    learning_hub: "Learning hub",
    learning_scores: "Learning scores",
    learning: "Learning",
    lesser_equal: "Lesser or equal",
    lesser: "Lesser",
    lesson_no_content: "This lesson has no content.",
    lessons: "Lesson | Lessons",
    license_details: "License details",
    license_id: "License",
    license_users: "License users",
    licenses: "License | Licenses",
    like: "Like",
    links: "Links",
    list_competencies: "List of competencies",
    listen: "Listen",
    log_in: "Log in",
    log_types: "Log types",
    login_subtitle: `Maintain your organisation's trainings, competencies, and mentorships`,
    login_time: "Login time",
    login_title_1: "Knowledge and learning",
    login_title_2: "Management for professionals",
    logins: "Login | Logins",
    logout: "Logout",
    logs: "Logs",
    manage_competencies: "Manage competencies",
    manage_instructors: "Manage instructors",
    manage_prerequisites: "Manage prerequisites",
    manage_questions: "Manage questions",
    manage_requirements: "Manage requirements",
    manage_roles: "Manage roles",
    manage_trainings: "Manage trainings",
    mandatory_for_roles: "Mandatory for roles",
    mandatory_for: "Mandatory for",
    march: "March",
    mark_as_all_read: "Mark as all read",
    mark_as_read: "Mark as read",
    mark_as_unread: "Mark as unread",
    matchup_matrix: "Matchup matrix",
    maximum_attempts_per_registration: "Maximum attempts per registration",
    maximum_attempts: "Maximum attempts",
    maximum_failed_attempts: "Maximum failed attempts",
    maximum_registration_duration: "Maximum registration duration",
    may: "May",
    memberships: "Memberships",
    mentee_profile: "Mentee profile",
    mentee: "Mentee",
    mentor_profile: "Mentor profile",
    mentor: "Mentor",
    mentorship: "Mentorship",
    messages: "Messages",
    minutes: "Minutes",
    missing_non_obtainable: "Missing non obtainable",
    missing_obtainable: "Missing obtainable",
    missing: "Missing",
    mobile: "Mobile",
    failed_attempts: "Failed Attempts",
    name: "Name",
    new_password: "New password",
    old_password: "Old Password",
    no_answers: "There are no answers",
    no_approaching_deadline: "There are no items approaching deadline.",
    no_attestation_requests: `This attestation has no pending requests at the moment.`,
    no_completion_requests: "There are no completion requests",
    no_content_lesson: "This lesson does not have any content.",
    no_criteria: "There are no criteria",
    no_expiring_competencies: "There are no expiring competecies",
    no_feedback: "There is no feedback.",
    no_latest_feedbacks: "There are no latest feedbacks.",
    no_latest_submissions: `This attestation does not have any latest submissions.`,
    no_learner_comment: "No comments.",
    no_notifications: "There are no notifications.",
    no_outline: "There is no outline items at the moment.",
    no_pending_approvals: "There are no pending approvals at the moment.",
    no_questions: "There are no questions",
    no_registrations: "There are no registrations",
    no_requirements: "There are no requirements",
    no_results_found: "No results found.",
    no_statistics: "There are no statistics.",
    no_submission_requests: "There are no submission requests at the moment.",
    no_tagged_resources: "There are no tagged resources for this tag.",
    no_tags: "No tags found.",
    no_unread_notifications: "No unread notifications",
    no_versions_lesson: "This lesson does not have any other versions.",
    no_versions: "There are no other versions.",
    non_obtainable_competencies: "Non obtainable competencies",
    none: "None",
    not_equal: "Not equal",
    not_found_subtitle: "But we learn from our mistakes everyday.",
    not_found_title: "Oops. this is embarrassing...",
    notification_read: "Notification read",
    notifications: "Notifications",
    november: "November",
    number_of_reevaluations_if_failed: "Number of reevaluation if failed",
    observation_requested_on: "Observation requested on",
    observation_scheduled_on: "Observation scheduled on",
    observations: "Observation | Observations",
    observed_by: "Observed by",
    observer_comments: "Observer comments",
    observer_id: "Observer",
    observer: "Observer",
    obtainable_competencies: "Obtainable competencies",
    obtained_on: "Obtained on",
    obtained_via: "Obtained via",
    october: "October",
    ok: "Ok",
    on: "On",
    outline: "Outline",
    overview: "Overview",
    pairings: "Pairings",
    parent_department: "Parent department",
    passing_grade: "Passing grade",
    password: "Password",
    past_due: "Past due",
    pause: "Pause",
    payment_info: "Payment info",
    pending_approvals: "Pending approvals",
    pending_requests: "Pending requests",
    perpetual_license: "Perpetual license",
    perpetuity: "Perpetuity",
    personal_information: "Personal information",
    personification_msg: `You are impersonating <span class='font-medium'>{name}</span>.`,
    phone: "Numero de telephone",
    pin: "NIP",
    places_available: "Places available",
    points: "Points",
    position: "Position",
    prerequisites: "Prerequisite | Prerequisites",
    preview: "Preview",
    price: "Price",
    print: "Print",
    privacy_policy: "Privacy policy",
    profile: "Profile",
    progress: "Progress",
    pts: "Pts",
    purchase_order: "Purcharse order",
    qty: "Qty",
    questionnaire_no_answer_comments_error: `You need to have an answer selected before you can comment on a question.`,
    questions: "Question | Questions",
    quiz_leave_msg: `Are you sure you want to leave this quiz? you will not be able to resume later. you will be assigned a grade of 0 and a result of incomplete.`,
    quiz_start_msg: `You are about to start this quiz. you cannot pause or leave without receiving a failing grade. please ensure that all other chexpertise windows and tabs are closed before you begin.`,
    quizzes: "Quiz | Quizzes",
    randomize_answer_order: "Randomize answer order",
    randomize_question_order: "Randomize question order",
    range: "Range",
    ratings: "Rating | Ratings",
    read: "Read",
    receipt: "Receipt",
    recent_logins: "Recent logins",
    recover_password: "Recover password",
    redirect_url: "Redirect URL",
    redirect: "Redirect",
    refresh_content: "Refresh content",
    refresh: "Refresh",
    regenerate: "Regenerate",
    register_now: "Register now",
    register: "Register",
    registered_on: "Registered on",
    registered_via: "Registered via",
    registration_approved_by: "Registration approved by",
    registration_approver: "Registration approver",
    registration_collections: `Registration collection | Registration collections`,
    registration_date: "Registration date",
    registration_processes: "Registration processes",
    registration_requested_on: "Registration requested on",
    registration_trainings: "Registration training | Registration trainings",
    registrations_to_complete: "Registrations to complete",
    registrations: "Registration | Registrations",
    reject_all: "Reject all",
    reject: "Reject",
    remember_me: "Remember me",
    reorder_questions: "Reorder questions",
    reports: "Report | Reports",
    request_attestation_upload: "Request attestation upload",
    request_observation: "Request observation",
    request_upload: "Request upload",
    requested_on: "Requested on",
    requests: "Requests",
    required_for_role: "Required for role",
    required_valid: "Required valid",
    requirements: "Requirement | Requirements",
    requires_approval: "Requires approval",
    requires_completion_approval: "Requires completion approval",
    requires_registration_approval: "Requires registration approval",
    reschedule: "Reschedule",
    reset_password: "Reset password",
    reset: "Reset",
    resource_name: "Resource name",
    resource: "Resource",
    result: "Result",
    results: "Result | Results",
    resume: "Resume",
    role_users: "Role users",
    roles: "Roles",
    save_criteria: "Save criteria",
    save: "Save",
    saved_searches: "Saved searches",
    schedule: "Schedule",
    scheduled_observations: "Scheduled observations",
    scheduled_on: "Scheduled on",
    scheduled: "Scheduled",
    score: "Score",
    scores_progression: "Scores progression",
    search_results: "Search results",
    search: "Search",
    select_month: "Select month",
    select_new_license: "Select new license",
    send: "Send",
    september: "September",
    show_expired: "Show expired",
    showing_entries: "{first} to {last} of {total}",
    sign_in: "Sign in",
    sign_up_sheet: "Sign up sheet",
    site_users: "Site users",
    sites: "Site | Sites",
    sms_enabled: "SMS notifications enabled",
    sms_notification: "SMS notification",
    source: "Source",
    sources: "Sources",
    start_date: "Start date",
    start: "Start",
    started_on: "Started on",
    statistics: "Statistics",
    status: "Status",
    statuses: "Status | Statuses",
    stop_impersonating: "Stop impersonating",
    submission_details: "Submission details",
    submissions: "Submission | Submissions",
    submit_by: "Submit by",
    submit_evaluation: "Submit evaluation",
    submit: "Submit",
    submitted_by: "Submitted by",
    submitted_on: "Submitted on",
    submitter_id: "Submitter",
    subtotal: "Subtotal",
    summary: "Summary",
    sys_admin_locked_competency_msg: `You need to be a system administrator to make changes to this competency.`,
    sys_admin_locked_lesson_msg: `You need to be a system administrator to make changes to this lesson.`,
    sys_admin_locked_msg: `You need to be a system administrator to make changes to this.`,
    sys_admin_locked: "System admin locked",
    system_role: "System role",
    tags: "Tag | Tags",
    taxes_included: "Taxes included",
    teaching: "Teaching",
    temp_password: "Temporary password",
    terms_and_conditions: "Terms and conditions",
    terms: "Terms",
    time: "Time",
    title: "Title",
    to: "To",
    top_trainings: "Top training | Top trainings",
    total_amount: "Total amount",
    total: "Total",
    training_completions: "Training completion | Training completions",
    training_id: "Training",
    training_imports: "Training imports",
    training_registration_failure: `You have failed to register to this training.`,
    training_registration_success: `You have sucessfully registered to this training.`,
    trainings_approaching_deadline: "Trainings approaching deadline",
    trainings: "Training | Trainings",
    type: "Type",
    ui_settings: "UI settings",
    unable_copy: "Unable to copy.",
    unassigned: "Unassigned",
    unlimited_attempts: "Unlimited attempts",
    unlimited: "Unlimited",
    unread: "Unread",
    update_criteria: "Update criteria",
    update_saved_search: "Update saved search",
    updated_on: "Last updated on {date}",
    upload_as: "Upload as",
    upload_attestation: "Upload attestation",
    upload_request_success: "You have successfully requested an upload.",
    upload: "Upload",
    uploaded_by: "Uploaded by",
    usage: "Usage",
    user_id: "User",
    user_type: "User type",
    username: "Username",
    users_assigned: `Users assigned <span class="text-theme-1 font-semibold">{number}</span> of <span class="text-theme-1 font-semibold">{total}</span>`,
    users: "User | Users",
    valid_competencies: "Valid competencies",
    valid_period: "Valid period",
    valid_until: "Valid until",
    value: "Value",
    venue: "Venue",
    version: "Version",
    versions: "Version | Versions",
    view_all: "View all",
    view_attempt: "View attempt",
    view_attestation: "View attestation",
    view_evaluation: "View evaluation",
    view_lesson_msg: `Please complete the lesson to start immediately viewing the lesson.`,
    view_lesson: "View lesson",
    view_registration: "View registration",
    view: "View",
    waiting_approval_competencies: "Waiting approval competencies",
    watch: "Watch",
    wiki_docs: "Wiki docs",
    with: "With",
    // DO NOT CHANGE KEY BELOW
    INSTRUCTOR: "Instructor",
    OBSERVER: "Observer",
    APPROVER: "Approver",
    REGISTRATION_APPROVER: "Registration Approver",
    COMPLETION_APPROVER: "Completion Approver",
    DEFAULT_SUBMITTER: "Default Submitter",
    DEFAULT_APPROVER: "Default Approver",
    TRAINING_SUBMITTER: "Training Submitter",
    TRAINING_APPROVER: "Training Approver",
    REGISTRATION_ATTESTATION_SUBMITTED: "Registration Attestation Submitted",
    REGISTRATION_ATTESTATION_APPROVED: "Registration Attestation Approver",
    "All Learners": "All Learners",
    "Any Training Instructor": "Any Training Instructor",
    "Completion Approval Requested": "Completion Approval Requested",
    "Completion Approval": "Completion Approval",
    "External Learners": "External Learners",
    "In Progress": "In Progress",
    "Information Update": "Information Update",
    "Instructor Action": "Instructor Action",
    "Internal and External Learners": "Internal and External Learners",
    "Internal Learners": "Internal Learners",
    "Learner Completed": "Learner Completed",
    "Learner Completion": "Learner Completion",
    "Live Lecture": "Live Lecture",
    "New Learners": "New Learners",
    "Nightly Process": "Nightly Process",
    "Non-obtainable": "Non-obtainable",
    "O-3C": "Three Choices",
    "O-4C": "Four Choices",
    "Observation Requested": "Observation Requested",
    "Observation Scheduled": "Observation Scheduled",
    "Pending Approval": "Pending Approval",
    "Q-MS": "Multiple Choices",
    "Q-SS": "Single Choice",
    "Q-TF": "True/False",
    "Registration Requested": "Registration Requested",
    "Specific Instructor": "Specific Instructor",
    "Under Review": "Under Review",
    "User Request": "User Request",
    "Waiting Approval": "Waiting Approval",
    Active: "Active",
    Approved: "Approved",
    Archived: "Archived",
    Audio: "Audio",
    Cancelled: "Cancelled",
    Completed: "Completed",
    Denied: "Denied",
    Disabled: "Disabled",
    Document: "Document",
    Expired: "Expired",
    External: "External",
    Failed: "Failed",
    Inactive: "Inactive",
    Incomplete: "Incomplete",
    Internal: "Internal",
    Learner: "Learner",
    No: "No",
    None: "None",
    Observation: "Observation",
    Obtainable: "Obtainable",
    Passed: "Passed",
    Paused: "Paused",
    Pending: "Pending",
    Quiz: "Quiz",
    Registered: "Registered",
    Rejected: "Rejected",
    Requested: "Requested",
    Slides: "Slides",
    URL: "URL",
    Valid: "Valid",
    Video: "Video",
    Withdrawn: "Withdrawn",
    Yes: "Yes"
  },
  backend: {
    "Unauthorized access": "Unauthorized access.",
    // Login
    "Login failed Expired license key": "Login failed. Expired license key.",
    "Login failed Invalid user": "Login failed. Invalid user.",
    "Login failed Invalid username and / or password": `Login failed. Invalid username and/or password.`,
    "Login failed The user status is not active": `Login failed. The user status is not active.`,
    "Login successful": "Login successful.",
    "Maximum login attempts reached Account disabled": `Maximum login attempts reached. Account disabled.`,
    "Logout successful": "Logout successful.",
    "Password expired": "Password expired.",
    "Invalid current password": "Invalid current password.",
    "Login failed Invalid login credentials": `Login failed. Invalid login credentials.`,
    // System Wide Message
    "The license key will expire on __value1__": `The license key will expire on {value1}`,
    // 2FA
    "Verify 2FA code": "Verify 2FA code.",
    // Reset Request Password
    "User not found": "User not found.",
    "Reset code sent to __value1__": `Reset code sent to <span class="font-medium">{value1}</span>.`,
    // Reset Password
    "Reset request not found": "Reset request not found.",
    "Password reset successfully": "Password reset successfully.",
    // Change Password
    "Password changed": "Password changed",
    "Password previously used": "Password previously used.",
    "New password not provided": "New password not provided.",
    "New password cannot be empty": "New password cannot be empty.",
    // CREATE
    "Resource added successfully": "Resource added successfully.",
    "Validation errors": "Validation errors.",
    "Cannot perform this action": "Cannot perform this action.",
    // READ
    "Resource(s) found successfully": "Resource(s) found successfully.",
    // UPDATE
    "Resource updated successfully": "Resource updated successfully.",
    "Resource not updated": "Resource not updated.",
    "Resource(s) updated successfully": "Resource(s) updated successfully.",
    // DELETE
    "Resource(s) deleted successfully": "Resource(s) deleted successfully.",
    "Resource(s) not deleted": "Resource(s) not deleted.",
    "Unauthorized access System roles cannot be deleted": `Unauthorized access. System roles cannot be deleted.`,
    // Log actions
    "Logged In": "Logged In",
    Added: "Added",
    Updated: "Updated",
    Deleted: "Deleted",
    "Added User": "Added User",
    "Removed User": "Removed User",
    "Reset Password": "Reset Password",
    "Regenerated API Key": "Regenerated API Key",
    "Impersonated User": "Impersonated User",
    "Added Answer": "Added Answer",
    "Deleted Answer": "Deleted Answer",
    "Updated Answer": "Updated Answer",
    "Added Question": "Added Question",
    "Deleted Question": "Deleted Question",
    "Updated Question": "Updated Question",
    "Deleted Criteria": "Deleted Criteria",
    "Added Criteria": "Added Criteria",
    "Updated Criteria": "Updated Criteria",
    // Log types
    Role: "Role",
    Site: "Site",
    Department: "Department",
    Login: "Login",
    User: "User",
    Competency: "Competency",
    Lesson: "Lesson",
    Evaluation: "Evaluation",
    Attestation: "Attestation",
    Learner: "Learner",
    // UI Settings
    "Animate User Interface": "Animate User Interface",
    "Animations on the user interface page loads": `Animations on the user interface page loads.`,
    "Email Notfication Alerts": "Email Notfication Alerts",
    "Receive email alerts when a notification is sent": `Receive email alerts when a notification is sent.`,
    "Per Page Pagination": "Per Page Pagination",
    "Number of results to display per page": `Number of results to display per page.`,
    "SMS Notfication Alerts": "SMS Notfication Alerts",
    "Receive SMS alerts when a notification is sent": `Receive SMS alerts when a notification is sent.`,
    "UI Theme": "UI Theme",
    "The user interface colour settings": "The user interface colour settings.",
    Default: "Default",
    Yes: "Yes",
    No: "No",
    // Lesson Overview Stats
    number_of_learners: "Number of Learners",
    average_completion_time: "Average Completion Time",
    number_of_trainings: "Number of Training Used",
    number_of_instructors: "Number of Instructors",
    number_of_tags: "Number of Tags",
    number_of_competencies: "Number of Competencies",
    // Attestation Overview Stats
    number_of_approved: "Approved",
    number_of_rejected: "Rejected",
    number_of_pending_approvals: "Approval Requests",
    number_of_submission_requests: "Upload Requests",
    // Competency Usage Stats
    lessons: "Lessons",
    attestations: "Attestations",
    evaluations: "Evaluations",
    trainings: "Trainings",
    collections: "Collections",
    // Competency Learners Stats
    number_of_obtainable: "Obtainable",
    number_of_nonobtainable: "Non Obtainable",
    number_of_inprogress: "In Progress",
    number_of_waitingapproval: "Waiting Approvals",
    number_of_valid: "Valid",
    number_of_expired: "Expired",
    // Evaluation Stats
    number_of_questions: "Number of Questions",
    average_grade: "Average Grade",
    highest_grade: "Highest Grade",
    lowest_grade: "Lowest Grade",
    number_of_attempts: "Number of Attempts",
    longest_completion_time: "Longest Completion Time",
    shortest_completion_time: "Shortest Completion Time",
    // Training Stats
    number_of_completed_registrations: "Number of Completed Registrations",
    number_of_active_registrations: "Number of Active Registrations",
    number_of_completion_requests: "Number of Completion Requests",
    number_of_incomplete_registrations: "Number of Incomplete Registrations",
    number_of_late_completions: "Number of Late Completions",
    // Training Overview
    registration_requested: "Registration Requested",
    registered: "Registered",
    in_progress: "In Progress",
    completion_approval_requested: "Completion Approval Requested",
    completed: "Completed",
    cancelled: "Cancelled",
    denied: "Denied",
    passed: "Passed",
    failed: "Failed",
    withdrawn: "Withdrawn",
    // Teaching
    attestation_approvals: "Attestation Approvals",
    attestation_requests: "Attestation Requests",
    evaluation_approvals: "Evaluation Approvals",
    observation_requests: "Observation Requests",
    registration_approvals: "Registration Approvals",
    total: "Total",
    due_soon: "Due Soon",
    past_due: "Past Due",
    incomplete: "Incomplete",
    rejected: "Rejected",
    pending: "Pending",
    paused: "Paused",
    observation_requested: "Observation Requested",
    observation_scheduled: "Observation Scheduled",
    learner_completed: "Learner Completed",
    training_registrations: "Training Registrations",
    collection_registrations: "Collection Registrations",
    observations: "Observations",
    quizzes: "Quizzes",
    observation_request: "Observation Request",
    attestation_request: "Attestation Request",
    // Learner Overview
    "Registration Requested": "Registration Requested",
    Registered: "Registered",
    "In Progress": "In Progress",
    "Completion Approval Requested": "Completion Approval Requested",
    Rejected: "Rejected",
    Completed: "Completed",
    Cancelled: "Cancelled",
    Denied: "Denied",
    Passed: "Passed",
    Failed: "Failed",
    Withdrawn: "Withdrawn",
    Pending: "Pending",
    "Pending Approval": "Pending Approval",
    Approved: "Approved",
    Requested: "Requested",
    Paused: "Paused",
    "Observation Requested": "Observation Requested",
    "Observation Scheduled": "Observation Scheduled",
    "Learner Completed": "Learner Completed",
    Incomplete: "Incomplete",
    Obtainable: "Obtainable",
    "Non-obtainable": "Non-obtainable",
    "Waiting Approval": "Waiting Approval",
    Valid: "Valid",
    Expired: "Expired",
    Accepted: "Accepted",
    // Learning dashboard
    attestations_to_complete: "Attestations to complete",
    collections_to_complete: "Collections to complete",
    evaluations_to_complete: "Evaluation to complete",
    lessons_to_complete: "Lessons to complete",
    trainings_to_complete: "Trainings to complete",
    requested: "Requested",
    pending_approval: "Pending approval",
    "All unread notifications set as read": `All unread notifications set as read.`,
    "Notification set as read": "Notification set as read.",
    Active: "Active",
    Inactive: "Inactive",
    "Under Review": "Under Review",
    Archived: "Archived",
    Disabled: "Disabled",
    INSTRUCTOR: "Instructor",
    OBSERVER: "Observer",
    APPROVER: "Approver",
    REGISTRATION_APPROVER: "Registration Approver",
    COMPLETION_APPROVER: "Completion Approver",
    DEFAULT_SUBMITTER: "Default Submitter",
    DEFAULT_APPROVER: "Default Approver",
    TRAINING_SUBMITTER: "Training Submitter",
    TRAINING_APPROVER: "Training Approver",
    REGISTRATION_ATTESTATION_SUBMITTED: "Registration Attestation Submitted",
    REGISTRATION_ATTESTATION_APPROVED: "Registration Attestation Approver",
    "Activate Hints": "Activate Hints",
    "Display hints on pages": "Display hints on pages",
    "Show Intro": "Show Intro",
    "Display the intro sequence on the home page when the user logs in": `Display the intro sequence on the home page when the user logs in`
  },
  notifications: {
    // Registrations
    RegistrationRequest: "Registration Request",
    RegistrationRequest_msg: `Registration request for training <span class="font-medium">{training}</span>.`,
    RegistrationAdded: "Registration Added",
    RegistrationAdded_msg: `Registration added for training <span class="font-medium">{training}</span>.`,
    RegistrationAcceptanceDecision: "Registration Acceptance Decision",
    RegistrationAcceptanceDecision_msg: `Registration acceptance decision for training <span class="font-medium">{training}</span>`,
    RegistrationCompletionRequest: "Registration Completion Request",
    RegistrationCompletionRequest_msg: `Registration completion request <span class="font-medium">{training}</span>`,
    RegistrationCompletionApproval: "Registration Completion Approval",
    RegistrationCompletionApproval_msg: `Registration completion approval for training <span class="font-medium">{training}</span>`,
    RegistrationCancelled: "Registration Cancelled",
    RegistrationCancelled_msg: `Registration cancelled for training <span class="font-medium">{training}</span>`,
    // Collection Registrations
    CollectionRegistrationRequest: "Collection Registration Request",
    CollectionRegistrationRequest_msg: `Collection registration request for collection <span class="font-medium">{collection}</span>.`,
    CollectionRegistrationAdded: "Collection Registration Added",
    CollectionRegistrationAdded_msg: `Collection registration added for collection <span class="font-medium">{collection}</span>.`,
    CollectionRegistrationAcceptanceDecision: `Collection Registration Acceptance Decision`,
    CollectionRegistrationAcceptanceDecision_msg: `Collection registration acceptance decision for collection <span class="font-medium">{collection}</span>`,
    CollectionRegistrationCompletionRequest: `Collection Registration Completion Request`,
    CollectionRegistrationCompletionRequest_msg: `Collection registration completion request <span class="font-medium">{collection}</span>`,
    CollectionRegistrationCompletionApproval: `Collection Registration Completion Approval`,
    CollectionRegistrationCompletionApproval_msg: `Collection registration completion approval for collection <span class="font-medium">{collection}</span>`,
    CollectionRegistrationCancelled: "Collection Registration Cancelled",
    CollectionRegistrationCancelled_msg: `Collection registration cancelled for collection <span class="font-medium">{collection}</span>`,
    // Attestations
    AttestationUploadRequest: "Attestation Upload Request",
    AttestationUploadRequest_msg: `Attestation <span class="font-medium">{attestation}</span> upload requested for training <span class="font-medium">{training}</span>.`,
    AttestationApprovalRequest: "Attestation Approval Request",
    AttestationApprovalRequest_msg: `Attestation <span class="font-medium">{attestation}</span> approval request for training <span class="font-medium">{training}</span>`,
    AttestationInstructorSubmission: "Attestation Instructor Submission",
    AttestationInstructorSubmission_msg: `Attestation <span class="font-medium">{attestation}</span> instructor submission for traiing <span class="font-medium">{training}</span>`,
    AttestationApprovalDecision: "Attestation Approval Decision",
    AttestationApprovalDecision_msg: `Attestation <span class="font-medium">{attestation}</span> approval decision for training <span class="font-medium">{training}</span>`,
    // Evaluations
    ObservationRequest: "Observation Request",
    ObservationScheduled: "Observation Scheduled",
    EvaluationCompletionApprovalRequest: `Evaluation Completion Approval Request`,
    ObservationRequest_msg: `Observation <span class="font-medium">{observation}</span> request for training <span class="font-medium">{training}</span>`,
    ObservationScheduled_msg: `Observation <span class="font-medium">{observation}</span> scheduled for training <span class="font-medium">{training}</span>`,
    EvaluationCompletionApprovalRequest_msg: `Evaluation <span class="font-medium">{evaluation}</span> completion approval request for training <span class="font-medium">{training}</span>`
  },
  validations: {
    required: "The field is required.",
    maxLength: "The field must be {max} characters maximum.",
    minLength: "The field must be {min} characters minimum.",
    email: "The field must have a valid email address.",
    sameAs: "The field must match the {otherName} field."
  }
};
