const state = () => {
  return {
    options: {}
  };
};

const getters = {
  options: state => resource => state.options[resource] ?? []
};

const actions = {
  async getOptions(context, data) {
    const stateOptions = context.state.options[data?.resource];

    if (!stateOptions) {
      const options = await data.getData();

      context.commit("SET_OPTIONS", {
        resource: data.resource,
        options
      });

      return options;
    }

    return stateOptions;
  },
  clearOption(context, resource) {
    context.commit("CLEAR_OPTION", resource);
  }
};

const mutations = {
  SET_OPTIONS(state, { resource, options }) {
    if (!state.options[resource]) {
      state.options = {
        ...state.options,
        [resource]: []
      };
    }

    state.options[resource] = options;
  },
  CLEAR_OPTION(state, resource) {
    if (state?.options[resource]) {
      delete state.options[resource];
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
