const state = () => {
  return {
    client: null
  };
};

const getters = {
  client: state => state.client,
  footer_info: state => state.client?.footer_info,
  logo: state => state.client?.logo,
  name: state => state.client?.name,
  privacy_policy: state => state.client?.privacy_policy,
  terms_and_conditions: state => state.client?.terms_and_conditions,
  url: state => state.client?.url
};

const actions = {
  setClient({ commit }, client) {
    commit("SET_CLIENT", client);
  }
};

const mutations = {
  SET_CLIENT(state, client) {
    state.client = client;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
