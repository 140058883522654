import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "departments",
    name: "administration-departments",
    meta: {
      title: t("app.departments", 2)
    },
    redirect: { name: "administration-departments-search" },
    component: () =>
      import("@/views/administration/departments/DepartmentView"),
    children: [
      {
        path: "",
        name: "administration-departments-search",
        component: () => import("@/views/administration/departments/IndexPage")
      },
      {
        path: "add",
        name: "administration-departments-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/administration/departments/CreatePage")
      }
    ]
  },
  {
    path: "departments/:id",
    name: "administration-departments-update",
    meta: {
      parentName: "administration-departments",
      title: t("app.departments", 2)
    },
    redirect: { name: "administration-departments-details" },
    component: () =>
      import("@/views/administration/departments/DepartmentView"),
    children: [
      {
        path: "details",
        name: "administration-departments-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/administration/departments/ReadPage")
      }
    ]
  }
];
