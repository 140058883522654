import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "collections",
    name: "teaching-registrations-collections",
    meta: {
      title: t("app.registrations", 2)
    },
    redirect: { name: "teaching-registrations-collections-search" },
    component: () =>
      import("@/views/teaching/registrations/collections/ResourceView"),
    children: [
      {
        path: "",
        name: "teaching-registrations-collections-search",
        component: () =>
          import("@/views/teaching/registrations/collections/IndexPage")
      },
      {
        path: "add",
        name: "teaching-registrations-collections-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () =>
          import("@/views/teaching/registrations/collections/CreatePage")
      }
    ]
  },
  {
    path: "collections/:id",
    name: "teaching-registrations-collections-update",
    meta: {
      parentName: "teaching-registrations-collections",
      title: t("app.registrations", 2)
    },
    redirect: { name: "teaching-registrations-collections-details" },
    props: true,
    component: () =>
      import("@/views/teaching/registrations/collections/ReadPage"),
    children: [
      {
        path: "details",
        name: "teaching-registrations-collections-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/registrations/collections/DetailsPage")
      },
      {
        path: "progress",
        name: "teaching-registrations-collections-progress",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/registrations/collections/OutlinePage")
      },
      {
        path: "logs",
        name: "teaching-registrations-collections-logs",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/registrations/collections/LogsPage")
      }
    ]
  }
];
