import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "approvals",
    name: "teaching-approvals",
    redirect: "teaching-approvals-evaluations",
    component: () => import("@/components/RouterView"),
    children: [
      {
        path: "evaluations",
        name: "teaching-approvals-evaluations",
        meta: {
          title: t("app.evaluations", 2)
        },
        component: () =>
          import("@/views/teaching/approvals/evaluations/IndexPage")
      },
      {
        path: "registrations",
        name: "teaching-approvals-registrations",
        meta: {
          title: t("app.registrations", 2)
        },
        component: () =>
          import("@/views/teaching/approvals/registrations/IndexPage")
      },
      {
        path: "collection-registrations",
        name: "teaching-approvals-registrations-collections",
        meta: {
          title: t("app.registrations", 2)
        },
        // eslint-disable-next-line
        component: () => import("@/views/teaching/approvals/collectionRegistrations/IndexPage")
      },
      {
        path: "attestations",
        name: "teaching-approvals-attestations",
        meta: {
          title: t("app.attestations", 2)
        },
        // eslint-disable-next-line
        component: () => import("@/views/teaching/approvals/attestations/IndexPage")
      }
    ]
  }
];
