import i18n from "@/i18n";
import middleware from "@/router/middleware";
import mentor from "./mentor";
import mentee from "./mentee";
import coordinator from "./coordinator";

const t = i18n.global.t;

export default {
  path: "/mentorship",
  name: "mentorship",
  meta: {
    middleware: [middleware.auth, middleware.mentorship],
    title: t("app.mentorship")
  },
  component: () => import("@/components/side-menu/TheSideMenu"),
  children: [...mentor, ...mentee, ...coordinator]
};
