export default {
  watch: {
    documentTitle: {
      handler() {
        const mainTitle = "Chexpertise";
        const title = this.documentTitle;

        if (title && !title.includes("undefined")) {
          document.title = `${title} | ${mainTitle}`;
        }
      },
      immediate: true
    }
  }
};
