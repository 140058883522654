import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "dashboard",
    name: "mentor-dashboard",
    meta: {
      title: t("app.dashboard")
    },
    component: () => import("@/views/mentorship/mentor/DashboardPage")
  },
  {
    path: "pairings",
    name: "mentor-pairings",
    meta: {
      title: t("app.pairings")
    },
    component: () => import("@/views/mentorship/mentor/PairingsPage")
  },
  {
    path: "profile",
    name: "mentor-profile",
    meta: {
      title: t("app.profile")
    },
    component: () => import("@/views/mentorship/mentor/ProfilePage")
  }
];
