import trainings from "./trainings";
import collections from "./collections";

export default [
  {
    path: "registrations",
    name: "teaching-registrations",
    redirect: "teaching-registrations-trainings",
    component: () => import("@/components/RouterView"),
    children: [...trainings, ...collections]
  }
];
