import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "reports",
    name: "teaching-reports",
    meta: {
      title: t("app.reports", 2)
    },
    component: () => import("@/components/RouterView"),
    children: [
      {
        path: "departments",
        name: "teaching-reports-departments",
        meta: {
          title: t("app.departments", 2)
        },
        component: () => import("@/views/teaching/reports/departments/Index")
      },
      {
        path: "learners",
        name: "teaching-reports-learners",
        meta: {
          title: t("app.learners", 2)
        },
        component: () => import("@/views/teaching/reports/learners/Index")
      },
      {
        path: "roles",
        name: "teaching-reports-roles",
        meta: {
          title: t("app.roles", 2)
        },
        component: () => import("@/views/teaching/reports/roles/Index")
      },
      {
        path: "trainings",
        name: "teaching-reports-trainings",
        meta: {
          title: t("app.trainings", 2)
        },
        component: () => import("@/views/teaching/reports/trainings/Index")
      },
      {
        path: "failed-attempts",
        name: "teaching-reports-failed-attempts",
        meta: {
          title: t("app.failed_attempts", 2)
        },
        component: () => import("@/views/teaching/reports/failedAttempts/Index")
      }
    ]
  }
];
