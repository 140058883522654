import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import middlewarePipeline from "./middlewarePipeline";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters["app/hasLoaded"]) {
    await store.dispatch("app/loadApp");
  }

  // Taken from https://blog.logrocket.com/vue-middleware-pipelines/ with modifications
  if (to.matched.every(match => !match.meta.middleware)) {
    return next();
  }

  const middleware = to.matched.reduce((acc, cv) => {
    return cv.meta.middleware ? [...acc, ...cv.meta.middleware] : acc;
  }, []);
  const context = {
    to,
    from,
    next,
    store
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
    nextVue: context.next
  });
});

const checkQueries = to => {
  const route = store.getters["queries/route"];
  const hasRoute = to.matched.some(
    match => match.name === route || match.meta.parentName === route
  );

  if (!hasRoute) {
    store.dispatch("queries/clearQueries");
  }
};

router.afterEach(to => {
  checkQueries(to);
});

export default router;
