import quizzes from "./quizzes";
import observations from "./observations";

export default [
  {
    path: "evaluations",
    name: "teaching-evaluations",
    redirect: "teaching-evaluations-quizzes",
    component: () => import("@/components/RouterView"),
    children: [...quizzes, ...observations]
  }
];
