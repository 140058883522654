import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "users",
    name: "administration-users",
    meta: {
      title: t("app.users", 2)
    },
    redirect: { name: "administration-users-search" },
    component: () => import("@/views/administration/users/UserView"),
    children: [
      {
        path: "",
        name: "administration-users-search",
        component: () => import("@/views/administration/users/IndexPage")
      },
      {
        path: "add",
        name: "administration-users-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () => import("@/views/administration/users/CreatePage")
      }
    ]
  },
  {
    path: "users/:id",
    name: "administration-users-update",
    redirect: { name: "administration-users-details" },
    meta: {
      parentName: "administration-users",
      title: t("app.users", 2)
    },
    props: true,
    component: () => import("@/views/administration/users/User"),
    children: [
      {
        path: "details",
        name: "administration-users-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/administration/users/DetailsPage")
      },
      {
        path: "memberships",
        name: "administration-users-memberships",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/administration/users/memberships/MembershipsPage")
      },
      {
        path: "logs",
        name: "administration-users-logs",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/administration/users/LogsPage")
      }
    ]
  }
];
