import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
// Constants
import resourceTypes from "@/constants/resourceTypes";

export default function useOptions() {
  // MISC
  const store = useStore();
  const { t } = useI18n();

  // Composables
  const { getText } = useTexts();
  const { request } = useRequest();

  // CONSTANTS
  const ALL_OPTIONS = {
    YES: {
      text: t("app.Yes"),
      value: "Yes"
    },
    NO: {
      text: t("app.No"),
      value: "No"
    },
    // Status
    ACTIVE: {
      text: t("app.Active"),
      value: "Active"
    },
    INACTIVE: {
      text: t("app.Inactive"),
      value: "Inactive"
    },
    DISABLED: {
      text: t("app.Disabled"),
      value: "Disabled"
    },
    UNDER_REVIEW: {
      text: t("app.Under Review"),
      value: "Under Review"
    },
    ARCHIVED: {
      text: t("app.Archived"),
      value: "Archived"
    },
    // Duration Type
    HOURS: {
      text: t("app.hours"),
      value: "hours"
    },
    MINUTES: {
      text: t("app.minutes"),
      value: "minutes"
    },
    // Type
    DOCUMENT: {
      text: t("app.Document"),
      value: "Document"
    },
    VIDEO: {
      text: t("app.Video"),
      value: "Video"
    },
    URL: {
      text: t("app.URL"),
      value: "URL"
    },
    AUDIO: {
      text: t("app.Audio"),
      value: "Audio"
    },
    LIVE_LECTURE: {
      text: t("app.Live Lecture"),
      value: "Live Lecture"
    },
    SLIDES: {
      text: t("app.Slides"),
      value: "Slides"
    },
    LEARNER: {
      text: t("app.Learner"),
      value: "Learner"
    },
    LEARNER_COMPLETED: {
      text: t("app.Learner Completed"),
      value: "Learner Completed"
    },
    ANY_INSTRUCTOR: {
      text: t("app.Any Training Instructor"),
      value: "Any Training Instructor"
    },
    INSTRUCTOR: {
      text: t("app.Specific Instructor"),
      value: "Specific Instructor"
    },
    NONE: {
      text: t("app.None"),
      value: "None"
    },
    TRUE_FALSE: {
      text: t("app.Q-TF"),
      value: "Q-TF"
    },
    MULTIPLE_CHOICE: {
      text: t("app.Q-MS"),
      value: "Q-MS"
    },
    SINGLE_CHOICE: {
      text: t("app.Q-SS"),
      value: "Q-SS"
    },
    THREE_CHOICES: {
      text: t("app.O-3C"),
      value: "O-3C"
    },
    FOUR_CHOICES: {
      text: t("app.O-4C"),
      value: "O-4C"
    },
    QUIZ: {
      text: t("app.Quiz"),
      value: "Quiz"
    },
    OBSERVATION_REQUESTED: {
      text: t("app.Observation Requested"),
      value: "Observation Requested"
    },
    OBSERVATION_SCHEDULED: {
      text: t("app.Observation Scheduled"),
      value: "Observation Scheduled"
    },
    OBSERVATION: {
      text: t("app.Observation"),
      value: "Observation"
    },
    INTERNAL_LEARNERS: {
      text: t("app.Internal Learners"),
      value: "Internal Learners"
    },
    EXTERNAL_LEARNERS: {
      text: t("app.External Learners"),
      value: "External Learners"
    },
    INTERNAL_EXTERNAL_LEARNERS: {
      text: t("app.Internal and External Learners"),
      value: "Internal and External Learners"
    },
    ALL_LEARNERS: {
      text: t("app.All Learners"),
      value: "All Learners"
    },
    NEW_LEARNERS: {
      text: t("app.New Learners"),
      value: "New Learners"
    },
    INTERNAL: {
      text: t("app.Internal"),
      value: "Internal"
    },
    EXTERNAL: {
      text: t("app.External"),
      value: "External"
    },
    // Registered Via
    USER_REQUEST: {
      text: t("app.User Request"),
      value: "User Request"
    },
    INFO_UPDATE: {
      text: t("app.Information Update"),
      value: "Information Update"
    },
    NIGHT_PROCESS: {
      text: t("app.Nightly Process"),
      value: "Nightly Process"
    },
    INSTRUCTOR_ACTION: {
      text: t("app.Instructor Action"),
      value: "Instructor Action"
    },
    // Status
    REGISTRATION_REQUESTED: {
      text: t("app.Registration Requested"),
      value: "Registration Requested"
    },
    IN_PROGRESS: {
      text: t("app.In Progress"),
      value: "In Progress"
    },
    COMPLETION_APPROVAL_REQUESTED: {
      text: t("app.Completion Approval Requested"),
      value: "Completion Approval Requested"
    },
    COMPLETED: {
      text: t("app.Completed"),
      value: "Completed"
    },
    CANCELLED: {
      text: t("app.Cancelled"),
      value: "Cancelled"
    },
    DENIED: {
      text: t("app.Denied"),
      value: "Denied"
    },
    // Result
    PASSED: {
      text: t("app.Passed"),
      value: "Passed"
    },
    FAILED: {
      text: t("app.Failed"),
      value: "Failed"
    },
    WITHDRAWN: {
      text: t("app.Withdrawn"),
      value: "Withdrawn"
    },
    REQUESTED: {
      text: t("app.Requested"),
      value: "Requested"
    },
    PENDING_APPROVAL: {
      text: t("app.Pending Approval"),
      value: "Pending Approval"
    },
    PENDING: {
      text: t("app.Pending"),
      value: "Pending"
    },
    APPROVED: {
      text: t("app.Approved"),
      value: "Approved"
    },
    PAUSED: {
      text: t("app.Paused"),
      value: "Paused"
    },
    INCOMPLETE: {
      text: t("app.Incomplete"),
      value: "Incomplete"
    },
    LEARNER_COMPLETION: {
      text: t("app.Learner Completion"),
      value: "Learner Completion"
    },
    COMPLETION_APPROVAL: {
      text: t("app.Completion Approval"),
      value: "Completion Approval"
    },
    VALID: {
      text: t("app.Valid"),
      value: "Valid"
    },
    EXPIRED: {
      text: t("app.Expired"),
      value: "Expired"
    },
    WAITING_APPROVAL: {
      text: t("app.Waiting Approval"),
      value: "Waiting Approval"
    },
    OBTAINABLE: {
      text: t("app.Obtainable"),
      value: "Obtainable"
    },
    NON_OBTAINABLE: {
      text: t("app.Non-obtainable"),
      value: "Non-obtainable"
    },
    SUMMARY: {
      text: t("app.summary"),
      value: "summary"
    },
    DETAILED: {
      text: t("app.detailed"),
      value: "detailed"
    }
  };

  const defaultSystemOptions = [ALL_OPTIONS.YES, ALL_OPTIONS.NO];
  const defaultStatusOptions = [ALL_OPTIONS.ACTIVE, ALL_OPTIONS.INACTIVE];
  const durationTypeOptions = [ALL_OPTIONS.HOURS, ALL_OPTIONS.MINUTES];

  // COMPUTED
  const langs = computed(() => {
    const langs = store.getters["app/available_languages"];

    return Object.keys(langs).map(key => ({
      text: langs[key],
      value: key
    }));
  });

  // METHODS
  const loadOptions = (endpoint, queryParams = {}) => {
    return request({ endpoint, queryParams });
  };

  /**
   * Formats an array of objects
   * @param {Array<Object>} options - options to format
   * @param {Object} formattingProperties
   *  @param {String} textProperty - the object's property used for the text. name by default.
   *  @param {String} valueProperty - the object's property used for the value. id by default.
   *  @param {String} text - the name of the property used for the text. text by default.
   *  @param {String} value - the name of the property used for the value. value by default.
   *  @param {Function} formatText - the function to get the object's property. undefined by default.
   * @returns {Array<Object>} formatted options
   */
  const formatOptions = (options, formattingProperties = {}) => {
    const {
      textProperty = "name",
      valueProperty = "id",
      text = "text",
      value = "value",
      formatText
    } = formattingProperties;

    return options.map(option => ({
      ...option,
      [text]: formatText ? formatText(option) : option[textProperty],
      [value]: option[valueProperty]
    }));
  };

  const getUserName = option => {
    return `${option.firstname} ${option.lastname}`;
  };

  const getVersionedTextName = option => {
    return `${getText(option?.texts, "name")} ${option.version}`;
  };

  const getLicenses = async () => {
    return store.dispatch("options/getOptions", {
      resource: resourceTypes.LICENSE,
      getData: async () => {
        const options = await getResources("administration.licenses");
        return setOptions(options);
      }
    });
  };

  const getCollections = async () => {
    return store.dispatch("options/getOptions", {
      resource: resourceTypes.COLLECTION,
      getData: async () => {
        const options = await getResources("teaching.collections");
        return setOptions(options, { formatText: getVersionedTextName });
      }
    });
  };

  const getTrainings = async () => {
    return store.dispatch("options/getOptions", {
      resource: resourceTypes.TRAINING,
      getData: async () => {
        const options = await getResources("teaching.trainings");
        return setOptions(options, { formatText: getVersionedTextName });
      }
    });
  };

  const getUsers = async () => {
    return store.dispatch("options/getOptions", {
      resource: resourceTypes.USER,
      getData: async () => {
        const options = await getResources("administration.users");
        return setOptions(options, { formatText: getUserName });
      }
    });
  };

  const getInstructors = async () => {
    const options = await getResources("teaching.instructors");
    return setOptions(options, { formatText: getUserName });
  };

  const getDepartments = async () => {
    return store.dispatch("options/getOptions", {
      resource: resourceTypes.DEPARTMENT,
      getData: async () => {
        const options = await getResources("administration.departments");
        return setOptions(options);
      }
    });
  };

  const getCertificates = async () => {
    return store.dispatch("options/getOptions", {
      resource: resourceTypes.CERTIFICATE,
      getData: async () => {
        const response = await getResources("teaching.certificates");
        const options = response?.payload?.data || [];

        return options.map(option => ({
          text: option,
          value: option
        }));
      }
    });
  };

  const getTags = async () => {
    return store.dispatch("options/getOptions", {
      resource: resourceTypes.TAG,
      getData: async () => {
        const options = await getResources("platform.tags");
        return setOptions(options, { valueProperty: "name" });
      }
    });
  };

  const getResources = async endpoint => {
    return await request({
      endpoint,
      queryParams: {
        ro_p: -1,
        ro_r: ["texts"]
      }
    });
  };

  const setOptions = (response, formattingProperties = {}) => {
    const options = response?.payload?.data || [];
    return formatOptions(options, formattingProperties);
  };

  return {
    getCertificates,
    getInstructors,
    durationTypeOptions,
    defaultSystemOptions,
    defaultStatusOptions,
    getVersionedTextName,
    getUserName,
    langs,
    formatOptions,
    loadOptions,
    getResources,
    getLicenses,
    getDepartments,
    getTags,
    getCollections,
    getTrainings,
    getUsers,
    setOptions,
    ALL_OPTIONS
  };
}
