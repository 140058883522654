import i18n from "@/i18n";
import middleware from "@/router/middleware";

const t = i18n.global.t;

export default {
  path: "/learning",
  name: "learning",
  redirect: { name: "learning-dashboard" },
  meta: {
    middleware: [middleware.auth, middleware.learning],
    title: t("app.learning", 2)
  },
  component: () => import("@/components/side-menu/TheSideMenu"),
  children: [
    {
      path: "dashboard",
      name: "learning-dashboard",
      meta: {
        title: t("app.dashboard", 2)
      },
      component: () => import("@/views/learning/dashboard/DashboardPage")
    },
    {
      path: "competencies",
      name: "learning-competencies",
      meta: {
        title: t("app.competencies", 2)
      },
      component: () => import("@/views/learning/competencies/IndexPage")
    },
    {
      path: "registrations",
      name: "learning-registrations",
      meta: {
        title: t("app.registrations", 2)
      },
      component: () => import("@/components/RouterView"),
      children: [
        {
          path: "trainings",
          name: "learning-registrations-trainings",
          meta: {
            title: t("app.trainings", 2)
          },
          component: () =>
            import("@/views/learning/registrations/trainings/IndexPage")
        },
        {
          path: "trainings/:id",
          name: "learning-registrations-trainings-read",
          meta: {
            parentName: "learning-registrations-trainings",
            title: t("app.trainings", 2)
          },
          props: true,
          redirect: { name: "learning-registrations-trainings-details" },
          component: () => import("@/components/RouterView"),
          children: [
            {
              path: "",
              name: "learning-registrations-trainings-details",
              meta: {
                title: t("app.details")
              },
              props: true,
              component: () =>
                import("@/views/learning/registrations/trainings/ReadPage")
            },
            {
              path: "lessons/:lessonId",
              name: "learning-registrations-trainings-lessons-details",
              meta: {
                title: t("app.lessons")
              },
              props: true,
              component: () =>
                import("@/views/learning/registrations/trainings/LessonPage")
            },
            {
              path: "attestations/:attestationId",
              name: "learning-registrations-trainings-attestations-details",
              meta: {
                title: t("app.attestations")
              },
              props: true,
              // eslint-disable-next-line
              component: () => import("@/views/learning/registrations/trainings/AttestationPage")
            },
            {
              path: "evaluations/quizzes/:evaluationId",
              // eslint-disable-next-line
              name: "learning-registrations-trainings-evaluations-quizzes-details",
              meta: {
                title: t("app.evaluations")
              },
              props: true,
              // eslint-disable-next-line
              component: () => import("@/views/learning/registrations/trainings/QuizPage")
            },
            {
              path: "evaluations/observations/:evaluationId",
              // eslint-disable-next-line
              name: "learning-registrations-trainings-evaluations-observations-details",
              meta: {
                title: t("app.evaluations")
              },
              props: true,
              // eslint-disable-next-line
              component: () => import("@/views/learning/registrations/trainings/ObservationPage")
            }
          ]
        },
        {
          path: "collections",
          name: "learning-registrations-collections",
          meta: {
            title: t("app.collections", 2)
          },
          component: () =>
            import("@/views/learning/registrations/collections/IndexPage")
        },
        {
          path: "collections/:id",
          name: "learning-registrations-collections-read",
          meta: {
            parentName: "learning-registrations-collections",
            title: t("app.collections", 2)
          },
          props: true,
          redirect: { name: "learning-registrations-collections-details" },
          component: () => import("@/components/RouterView"),
          children: [
            {
              path: "",
              name: "learning-registrations-collections-details",
              meta: {
                title: t("app.details")
              },
              props: true,
              component: () =>
                import("@/views/learning/registrations/collections/ReadPage")
            }
          ]
        }
      ]
    },
    {
      path: "lessons",
      name: "learning-lessons",
      meta: {
        title: t("app.lessons", 2)
      },
      component: () => import("@/views/learning/lessons/IndexPage")
    },
    {
      path: "lessons/:id",
      name: "learning-lessons-view",
      redirect: { name: "learning-lessons-details" },
      meta: {
        parentName: "learning-lessons",
        title: t("app.lessons", 2)
      },
      props: true,
      component: () => import("@/components/RouterView"),
      children: [
        {
          path: "",
          name: "learning-lessons-details",
          meta: {
            title: t("app.view")
          },
          props: true,
          component: () => import("@/views/learning/lessons/ReadPage")
        }
      ]
    },
    {
      path: "quizzes",
      name: "learning-quizzes",
      meta: {
        title: t("app.quizzes", 2)
      },
      component: () => import("@/views/learning/evaluations/quizzes/IndexPage")
    },
    {
      path: "quizzes/:id",
      name: "learning-quizzes-view",
      redirect: { name: "learning-quizzes-details" },
      meta: {
        parentName: "learning-quizzes",
        title: t("app.quizzes", 2)
      },
      props: true,
      component: () => import("@/components/RouterView"),
      children: [
        {
          path: "",
          name: "learning-quizzes-details",
          meta: {
            title: t("app.view")
          },
          props: true,
          component: () =>
            import("@/views/learning/evaluations/quizzes/ReadPage")
        }
      ]
    },
    {
      path: "observations",
      name: "learning-observations",
      meta: {
        title: t("app.observations", 2)
      },
      component: () =>
        import("@/views/learning/evaluations/observations/IndexPage")
    },
    {
      path: "observations/:id",
      name: "learning-observations-view",
      redirect: { name: "learning-observations-details" },
      meta: {
        parentName: "learning-observations",
        title: t("app.observations", 2)
      },
      props: true,
      component: () => import("@/components/RouterView"),
      children: [
        {
          path: "",
          name: "learning-observations-details",
          meta: {
            title: t("app.view")
          },
          props: true,
          component: () =>
            import("@/views/learning/evaluations/observations/ReadPage")
        }
      ]
    },
    {
      path: "attestations",
      name: "learning-attestations",
      meta: {
        title: t("app.attestations", 2)
      },
      component: () => import("@/views/learning/attestations/IndexPage")
    },
    {
      path: "attestations/:id",
      name: "learning-attestations-view",
      redirect: { name: "learning-attestations-details" },
      meta: {
        parentName: "learning-attestations",
        title: t("app.attestations", 2)
      },
      props: true,
      component: () => import("@/components/RouterView"),
      children: [
        {
          path: "",
          name: "learning-attestations-details",
          meta: {
            title: t("app.view")
          },
          props: true,
          component: () => import("@/views/learning/attestations/ReadPage")
        }
      ]
    },
    {
      path: "trainings",
      name: "learning-trainings",
      meta: {
        title: t("app.trainings", 2)
      },
      component: () => import("@/views/learning/trainings/IndexPage")
    },
    {
      path: "trainings/:id",
      name: "learning-trainings-details",
      meta: {
        parentName: "learning-trainings",
        title: t("app.trainings", 2)
      },
      props: true,
      component: () => import("@/views/learning/trainings/ReadPage")
    },
    {
      path: "collections",
      name: "learning-collections",
      meta: {
        title: t("app.collections", 2)
      },
      component: () => import("@/views/learning/collections/IndexPage")
    },
    {
      path: "collections/:id",
      name: "learning-collections-details",
      meta: {
        parentName: "learning-collections",
        title: t("app.collections", 2)
      },
      props: true,
      component: () => import("@/views/learning/collections/ReadPage")
    }
  ]
};
