import systemRoles from "@/constants/systemRoles";
import modules from "@/constants/modules";

const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "home",
        title: "home"
      },
      {
        title: "wiki_docs",
        module: true,
        moduleName: modules.WIKIDOCS
      },
      {
        title: "learning",
        authorizedRoles: [systemRoles.LEARNER],
        moduleName: modules.LMS,
        module: true,
        subMenu: [
          {
            icon: "fal fa-chalkboard-teacher fa-lg",
            pageName: "learning-dashboard",
            title: "dashboard"
          },
          {
            icon: "fal fa-head-side-brain fa-lg",
            pageName: "learning-competencies",
            title: "competencies"
          },
          {
            icon: "fal fa-file-user fa-lg",
            title: "registrations",
            subMenu: [
              {
                icon: "fal fa-user-chart fa-lg",
                pageName: "learning-registrations-trainings",
                title: "trainings"
              },
              {
                icon: "fal fa-analytics fa-lg",
                pageName: "learning-registrations-collections",
                title: "collections"
              }
            ]
          },
          {
            icon: "fal fa-presentation fa-lg",
            pageName: "learning-lessons",
            title: "lessons"
          },
          {
            icon: "fal fa-clipboard-check fa-lg",
            title: "evaluations",
            subMenu: [
              {
                icon: "fal fa-tasks fa-lg",
                pageName: "learning-quizzes",
                title: "quizzes"
              },
              {
                icon: "fal fa-user-clock fa-lg",
                pageName: "learning-observations",
                title: "observations"
              }
            ]
          },
          {
            icon: "fal fa-file-certificate fa-lg",
            pageName: "learning-attestations",
            title: "attestations"
          },
          {
            icon: "fal fa-user-chart fa-lg",
            pageName: "learning-trainings",
            title: "trainings"
          },
          {
            icon: "fal fa-analytics fa-lg",
            pageName: "learning-collections",
            title: "collections"
          }
        ]
      },
      {
        title: "teaching",
        authorizedRoles: [systemRoles.INSTRUCTOR],
        moduleName: modules.LMS,
        module: true,
        subMenu: [
          {
            icon: "fal fa-chart-pie-alt fa-lg",
            pageName: "teaching-dashboard",
            title: "dashboard"
          },
          {
            icon: "fal fa-clipboard-list fa-lg",
            title: "requests",
            subMenu: [
              {
                icon: "fal fa-user-clock fa-lg",
                pageName: "teaching-requests-observations",
                title: "observations"
              },
              {
                icon: "fal fa-file-certificate fa-lg",
                pageName: "teaching-requests-attestations",
                title: "attestations"
              },
              {
                icon: "fal fa-file-user fa-lg",
                pageName: "teaching-requests-registrations",
                title: "registration_trainings"
              },
              {
                icon: "fal fa-file-user fa-lg",
                pageName: "teaching-requests-registrations-collections",
                title: "registration_collections"
              }
            ]
          },
          {
            icon: "fal fa-check-circle fa-lg",
            title: "approvals",
            subMenu: [
              {
                icon: "fal fa-clipboard-check fa-lg",
                pageName: "teaching-approvals-evaluations",
                title: "evaluations"
              },
              {
                icon: "fal fa-file-user fa-lg",
                pageName: "teaching-approvals-registrations",
                title: "registration_trainings"
              },
              {
                icon: "fal fa-file-user fa-lg",
                pageName: "teaching-approvals-registrations-collections",
                title: "registration_collections"
              },
              {
                icon: "fal fa-file-certificate fa-lg",
                pageName: "teaching-approvals-attestations",
                title: "attestations"
              }
            ]
          },
          {
            icon: "fal fa-users-class fa-lg",
            pageName: "teaching-learners",
            title: "learners"
          },
          {
            icon: "fal fa-file-user fa-lg",
            pageName: "teaching-registrations",
            title: "registrations",
            subMenu: [
              {
                icon: "fal fa-user-chart fa-lg",
                pageName: "teaching-registrations-trainings",
                title: "trainings"
              },
              {
                icon: "fal fa-analytics fa-lg",
                pageName: "teaching-registrations-collections",
                title: "collections"
              }
            ]
          },
          {
            icon: "fal fa-clipboard-check fa-lg",
            title: "evaluations",
            subMenu: [
              {
                icon: "fal fa-tasks fa-lg",
                pageName: "teaching-evaluations-quizzes",
                title: "quizzes"
              },
              {
                icon: "fal fa-user-clock fa-lg",
                pageName: "teaching-evaluations-observations",
                title: "observations"
              }
            ]
          },
          {
            icon: "fal fa-presentation fa-lg",
            pageName: "teaching-lessons",
            title: "lessons"
          },
          {
            icon: "fal fa-file-certificate fa-lg",
            pageName: "teaching-attestations",
            title: "attestations"
          },
          {
            icon: "fal fa-user-chart fa-lg",
            pageName: "teaching-trainings",
            title: "trainings"
          },
          {
            icon: "fal fa-analytics fa-lg",
            pageName: "teaching-collections",
            title: "collections"
          },
          {
            icon: "fal fa-clipboard-user fa-lg",
            pageName: "teaching-roles",
            title: "roles"
          },
          {
            icon: "fal fa-user-chart fa-lg",
            pageName: "teaching-instructors",
            title: "instructors"
          },
          {
            icon: "fal fa-head-side-brain fa-lg",
            pageName: "teaching-competencies",
            title: "competencies"
          },
          {
            icon: "fal fa-file-chart-line fa-lg",
            title: "reports",
            subMenu: [
              {
                icon: "fal fa-file-chart-pie fa-lg",
                pageName: "teaching-reports-departments",
                title: "departments"
              },
              {
                icon: "fal fa-file-times fa-lg",
                pageName: "teaching-reports-failed-attempts",
                title: "failed_attempts"
              },
              {
                icon: "fal fa-file-spreadsheet fa-lg",
                pageName: "teaching-reports-learners",
                title: "learners"
              },
              {
                icon: "fal fa-file-spreadsheet fa-lg",
                pageName: "teaching-reports-roles",
                title: "roles"
              },
              {
                icon: "fal fa-file-chart-line fa-lg",
                pageName: "teaching-reports-trainings",
                title: "trainings"
              }
            ]
          }
        ]
      },
      {
        title: "mentorship",
        module: true,
        moduleName: modules.MENTORSHIP,
        subMenu: [
          {
            icon: "fal fa-user-tie fa-lg",
            title: "mentor",
            subMenu: [
              {
                icon: "fal fa-user-chart fa-lg",
                title: "dashboard",
                pageName: "mentor-dashboard"
              },
              {
                icon: "fal fa-hands-helping fa-lg",
                title: "pairings",
                pageName: "mentor-pairings"
              },
              {
                icon: "fal fa-id-card-alt fa-lg",
                title: "mentor_profile",
                pageName: "mentor-profile"
              }
            ]
          },
          {
            icon: "fal fa-head-side fa-lg",
            title: "mentee",
            subMenu: [
              {
                icon: "fal fa-comments-alt fa-lg",
                title: "dashboard",
                pageName: "mentee-dashboard"
              },
              {
                icon: "fal fa-handshake fa-lg",
                title: "pairings",
                pageName: "mentee-pairings"
              },
              {
                icon: "fal fa-file-alt fa-lg",
                title: "mentee_profile",
                pageName: "mentee-profile"
              }
            ]
          },
          {
            icon: "fal fa-user-cog fa-lg",
            title: "coordinator",
            authorizedRoles: [systemRoles.MENTORSHIP_COORDINATOR],
            subMenu: [
              {
                icon: "fal fa-chart-network fa-lg",
                title: "dashboard",
                pageName: "coordinator-dashboard"
              },
              {
                icon: "fal fa-user-plus fa-lg",
                title: "requests",
                pageName: "coordinator-requests"
              },
              {
                icon: "fal fa-people-arrows fa-lg",
                title: "pairings",
                pageName: "coordinator-pairings"
              },
              {
                icon: "fal fa-game-board-alt fa-lg",
                title: "matchup_matrix",
                pageName: "coordinator-matrix"
              }
            ]
          }
        ]
      },
      {
        title: "administration",
        authorizedRoles: [systemRoles.SYSTEM_ADMIN, systemRoles.ADMIN],
        module: true,
        subMenu: [
          {
            icon: "fal fa-tools fa-lg",
            pageName: "administration-dashboard",
            title: "dashboard"
          },
          {
            icon: "fal fa-users-cog fa-lg",
            pageName: "administration-users",
            title: "users"
          },
          {
            icon: "fal fa-clipboard-user fa-lg",
            pageName: "administration-roles",
            title: "roles"
          },
          {
            icon: "fal fa-users fa-lg",
            pageName: "administration-departments",
            title: "departments"
          },
          {
            icon: "fal fa-city fa-lg",
            pageName: "administration-sites",
            title: "sites"
          },
          {
            icon: "fal fa-download fa-lg",
            pageName: "administration-import-trainings",
            title: "import_trainings"
          },
          {
            icon: "fal fa-link fa-lg",
            pageName: "administration-integrations",
            title: "integrations"
          },
          {
            icon: "fal fa-id-card fa-lg",
            pageName: "administration-licenses",
            title: "licenses"
          },
          {
            icon: "fal fa-file-archive fa-lg",
            pageName: "administration-logs",
            title: "logs"
          }
        ]
      }
    ]
  };
};

const getters = {
  menu: state => state.menu
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
