<template>
  <div>
    <router-view />

    <VIntro />
  </div>
</template>

<script>
import { watch, computed } from "vue";
import { useStore } from "@/store";
// Components
import VIntro from "@/components/VIntro";

export default {
  components: {
    VIntro
  },
  setup() {
    // Misc
    const store = useStore();

    // Computed
    const client = computed(() => store.getters["app/client"]);
    const hasLoaded = computed(() => store.getters["app/hasLoaded"]);
    const apiToken = computed(() => store.getters["user/api_token"]);

    // Methods
    const listenToCRUDUpdates = () => {
      const channel = `${client.value}.crud-updates`;

      window.Echo.private(channel).listen("CRUDUpdated", update => {
        store.dispatch("options/clearOption", update?.resourceType);
      });
    };

    // Watchers
    watch(
      () => hasLoaded.value,
      () => {
        if (!hasLoaded.value || !apiToken.value) {
          return;
        }

        listenToCRUDUpdates();
      },
      {
        immediate: true
      }
    );
  }
};
</script>
