import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import i18n from "./i18n";
import "./libs";
import TabTitleMixin from "@/mixins/TabTitleMixin";

window.Pusher = require("pusher-js");

// SASS Theme
import "./assets/sass/app.scss";
import "./assets/fontawesome-pro-5.15.3-web/css/fontawesome.min.css";
import "./assets/fontawesome-pro-5.15.3-web/css/light.min.css";
import "./assets/fontawesome-pro-5.15.3-web/css/solid.min.css";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mixin(TabTitleMixin);

globalComponents(app);
utils(app);

app.mount("#app");
