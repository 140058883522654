import Echo from "laravel-echo";
import i18n from "@/i18n";
import systemRoles from "@/constants/systemRoles";

const setUserStorage = (state, data) => {
  const user = JSON.stringify(data);

  if (state.remember_me) {
    localStorage.setItem("user", user);
  }

  sessionStorage.setItem("user", user);
};

const hasRole = (state, role) => {
  const roles = state?.personification?.api_token
    ? state?.personification?.roles
    : state?.user?.roles;

  if (!roles || roles.length === 0 || !role) {
    return false;
  }

  return roles.some(r => r.code === role);
};

const setPusherData = context => {
  const { app_cluster, app_key } = context?.rootGetters["app/pusher"] ?? {};
  const baseURL = context?.rootGetters["api/baseURL"];
  const api_token = context?.state?.user?.api_token;

  window.Echo = new Echo({
    broadcaster: "pusher",
    authEndpoint: `${baseURL}/broadcasting/auth?api_token=${api_token}`,
    key: app_key,
    cluster: app_cluster,
    forceTLS: true
  });
};

const getSetting = (state, settingCode, fallback) => {
  const settings = state.user.settings || [];
  const setting = settings.find(s => s.code === settingCode);

  return setting?.pivot?.value ?? fallback;
};

const updateAppHints = context => {
  const showHints = context?.getters?.show_hints === "Yes";

  context.dispatch("app/setHints", showHints, { root: true });
};

const updateAppIntro = context => {
  const showIntro = context?.getters?.show_intro === "Yes";
  context.dispatch("app/setIntro", showIntro, { root: true });
};

const updateLanguage = context => {
  const lang = context?.getters?.language;
  i18n.global.locale.value = lang;
};

const getUserData = (state, attr) => {
  if (state.personification.user[attr]) {
    return state.personification.user[attr];
  }

  return state.user[attr];
};

const state = () => {
  return {
    remember_me: false,
    token_verified: false,
    personification: {
      api_token: "",
      user: {}
    },
    user: {},
    temporary_password: ""
  };
};

const getters = {
  isAuthenticated: state => state.token_verified && "api_token" in state.user,
  temporary_password: state => state.temporary_password,
  user: state => {
    if (Object.keys(state.personification.user).length > 0) {
      return state.personification.user;
    }

    return state.user;
  },
  id: state => getUserData(state, "id"),
  api_token: state => state.personification?.api_token || state.user?.api_token,
  avatar: state => getUserData(state, "avatar"),
  departments: state => getUserData(state, "departments"),
  details: state => getUserData(state, "details"),
  email: state => getUserData(state, "email"),
  external_code: state => getUserData(state, "external_code"),
  firstname: state => getUserData(state, "firstname"),
  language: state => state.user?.language,
  last_login: state => getUserData(state, "last_login"),
  lastname: state => getUserData(state, "lastname"),
  license: state => getUserData(state, "license"),
  mobile: state => getUserData(state, "mobile"),
  phone: state => getUserData(state, "phone"),
  priority_level: state => getUserData(state, "priority_level"),
  roles: state => {
    if (state.personification.user?.roles?.length) {
      return state.personification.user?.roles;
    }

    return state.user?.roles || [];
  },
  hasRole: state => role => hasRole(state, role),
  isSysAdmin: state => hasRole(state, systemRoles.SYSTEM_ADMIN),
  // isSysAdmin: () => false,
  isAdmin: state => hasRole(state, systemRoles.ADMIN),
  isInstructor: state => hasRole(state, systemRoles.INSTRUCTOR),
  isUser: state => hasRole(state, systemRoles.USER),
  isLearner: state => hasRole(state, systemRoles.LEARNER),
  status: state => getUserData(state, "status"),
  title: state => getUserData(state, "title"),
  username: state => getUserData(state, "username"),
  remember_me: state => state.remember_me,
  token_verified: state => state.token_verified,
  personification_name: state => {
    const { firstname, lastname } = state.personification.user ?? {};

    return firstname && lastname ? `${firstname} ${lastname}` : "";
  },
  per_page: state => getSetting(state, "PAGINATION", 20),
  animate_ui: state => getSetting(state, "ANIMATE_UI", "Yes"),
  show_hints: state => getSetting(state, "HINTS", "No"),
  show_intro: state => getSetting(state, "INTRO", "No")
};

const actions = {
  setTemporaryPassword(context, value) {
    context.commit("SET_TEMPORARY_PASSWORD", value);
  },
  setUser(context, user) {
    context.commit("SET_USER", user);
    updateAppHints(context);
    updateLanguage(context);
  },
  setAvatar({ commit }, avatar) {
    commit("SET_AVATAR", avatar);
  },
  setPersonification({ commit }, data) {
    commit("SET_PERSONIFICATION", data);
  },
  clearPersonification({ commit }) {
    commit("CLEAR_PERSONIFICATION");
  },
  updateUser(context, user) {
    context.commit("UPDATE_USER", user);
    updateAppHints(context);
    updateLanguage(context);
  },
  async login(context, { data, toast }) {
    if (!data.username || (!data.password && !data.api_token)) {
      context.commit("SET_TOKEN_VERIFIED", false);
      return;
    }

    const response = await context.dispatch(
      "api/request",
      {
        endpoint: "login",
        data,
        toast
      },
      {
        root: true
      }
    );

    const { identification } = response ?? {};
    const { system_messages, data: user } = response?.payload ?? {};

    if (identification) {
      context.dispatch("api/setIdentification", identification, { root: true });
    }

    if (system_messages) {
      context.dispatch("messages/setMessages", system_messages, { root: true });
    }

    if (user) {
      context.dispatch("setUser", user);
    }

    setPusherData(context);
    updateAppIntro(context);

    context.commit(
      "SET_TOKEN_VERIFIED",
      response?.payload?.message?.status === "Success"
    );

    return response;
  },
  setApiToken({ commit }, token) {
    commit("SET_API_TOKEN", token);
  },
  setTokenVerified({ commit }, isVerified) {
    commit("SET_TOKEN_VERIFIED", isVerified);
  },
  setRememberMe({ commit }, remember_me) {
    commit("SET_REMEMBER_ME", remember_me);
  },
  logout({ commit }) {
    commit("CLEAR_USER");
    commit("SET_TOKEN_VERIFIED", false);
    sessionStorage.removeItem("user");
  }
};

const mutations = {
  SET_TEMPORARY_PASSWORD(state, value) {
    state.temporary_password = value;
  },
  SET_USER(state, user) {
    state.user = user;
    setUserStorage(state, user);
  },
  UPDATE_USER(state, user) {
    const updatedUser = {
      ...state.user,
      ...user
    };
    state.user = updatedUser;
    setUserStorage(state, updatedUser);
  },
  SET_AVATAR(state, avatar) {
    state.user.avatar = avatar;
    setUserStorage(state, {
      ...state.user,
      avatar
    });
  },
  SET_API_TOKEN(state, token) {
    state.user.api_token = token;
  },
  SET_PERSONIFICATION(state, { api_token, user }) {
    state.personification.api_token = api_token;
    state.personification.user = user;
    state.personification.user.avatar = user?.avatar?.view_path;
  },
  CLEAR_PERSONIFICATION(state) {
    state.personification.api_token = "";
    state.personification.user = {};
  },
  SET_TOKEN_VERIFIED(state, isVerified) {
    state.token_verified = isVerified;
  },
  SET_REMEMBER_ME(state, remember_me) {
    state.remember_me = remember_me;
  },
  CLEAR_USER(state) {
    state.user = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
