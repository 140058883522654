import { useStore } from "@/store";

export default function useTexts() {
  const store = useStore();

  /**
   * Get the text field to display
   * The text will be displayed in the language available in the following priority
   * 1. The user's language
   * 2. The app default's language
   * 3. The first available language
   * @param {Array} texts
   * @param {String} field
   * @returns {String}
   */
  const getText = (texts, field) => {
    if (!texts || texts.length === 0 || !field) {
      return "";
    }

    const userLang = store.getters["user/language"];
    const defaultLang = store.getters["app/default_language"];
    const firstText = texts[0];
    const text = firstText[field]
      ? getTextLang(firstText[field], firstText.language)
      : "";

    return (
      getTextValue(texts, userLang, field) ||
      getTextValue(texts, defaultLang, field, true) ||
      text
    );
  };

  /**
   * Get the text field in a specific language
   * @param {Array} texts
   * @param {String} lang
   * @param {String} field
   * @param {Boolean} displayLang
   * @returns {String} - the text field in the lang or empty if it doesn't exist
   */
  const getTextValue = (texts, lang, field, displayLang = false) => {
    const text = texts.find(text => text.language === lang);

    if (!text || !text[field]) {
      return "";
    }

    if (displayLang) {
      return getTextLang(text[field], text.language);
    }

    return text[field] || "";
  };

  const getTextLang = (text, lang) => {
    return `${text} [${lang.toUpperCase()}]`;
  };

  /**
   * Format the texts to fit localizedInput structure
   * @param {Object} data
   * @param {Array} fields
   * @returns {Object} formattedTexts
   */
  const getTextValues = (texts, fields) => {
    if (!texts) {
      return {};
    }

    return texts.reduce((acc, text) => {
      return { ...acc, ...formatText(text, acc, fields) };
    }, {});
  };

  /**
   * Format the text to fit localizedInput structure
   * @param {Object} text
   * @param {Object} result
   * @param {Array} fields
   * @returns {Object} formattedText
   */
  const formatText = (text, result, fields) => {
    return Object.keys(text).reduce((acc, cv) => {
      if (fields.includes(cv)) {
        if (result[cv]) {
          return {
            ...acc,
            [cv]: {
              ...result[cv],
              [text.language]: {
                ...text,
                value: text[cv]
              }
            }
          };
        }

        return {
          ...acc,
          [cv]: {
            [text.language]: {
              ...text,
              value: text[cv]
            }
          }
        };
      }

      return acc;
    }, {});
  };

  return {
    getText,
    getTextValue,
    getTextValues
  };
}
