import i18n from "@/i18n";
import middleware from "@/router/middleware";
import lessons from "./lessons";
import competencies from "./competencies";
import attestations from "./attestations";
import evaluations from "./evaluations";
import trainings from "./trainings";
import collections from "./collections";
import registrations from "./registrations";
import learners from "./learners";
import reports from "./reports";
import approvals from "./approvals";
import requests from "./requests";
import roles from "./roles";
import instructors from "./instructors";

const t = i18n.global.t;

export default {
  path: "/teaching",
  name: "teaching",
  redirect: { name: "teaching-dashboard" },
  meta: {
    middleware: [middleware.auth, middleware.teaching],
    title: t("app.teaching", 2)
  },
  component: () => import("@/components/side-menu/TheSideMenu"),
  children: [
    {
      path: "dashboard",
      name: "teaching-dashboard",
      meta: {
        title: t("app.dashboard", 2)
      },
      component: () => import("@/views/teaching/dashboard/DashboardPage")
    },
    ...instructors,
    ...roles,
    ...requests,
    ...approvals,
    ...registrations,
    ...evaluations,
    ...trainings,
    ...collections,
    ...lessons,
    ...competencies,
    ...attestations,
    ...learners,
    ...reports
  ]
};
