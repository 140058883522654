export default {
  app: {
    hide: "Hide",
    show: "Show",
    reports_departments: "Reports: Departments",
    reports_learners: "Reports: Learners",
    reports_roles: "Reports: Roles",
    reports_failed_attempts: "Reports: Failed attempsts",
    reports_trainings: "Reports: Trainings",
    no_registrations_found: "No registrations found",
    no_trainings_found: "No trainings found",
    types: "Type",
    credits_offered: "Credits offered",
    hours_offered: "Hours offered",
    acquired: "Acquired",
    include_all_versions: "Include all versions",
    role_status: "Role status",
    hours_completed: "Hours completed",
    credits_completed: "Credits completed",
    detailed: "Detailed",
    user_status: "User Status",
    training_status: "Training Status",
    report_view: "Report View",
    registration_period: "Registration Period",
    completion_period: "Completion Period",
    as_instructor: "As Instructor",
    as_learner: "As Learner",
    teaching_statuses: "Teaching Statuses",
    my_competency_statuses: "My Competency Statuses",
    my_registrations_to_complete: "My Registrations to Complete",
    instructor_summary: "Instructor Summary",
    learner_summary: "Learner Summary",
    delete_texts: "Delete Texts",
    export_all_data: "Export All Data",
    resend_2FA_code_email: `Code resent to <span class="font-medium">{email}</span>.`,
    resend_2FA_code_mobile_email: `Code resent to <span class="font-medium">{email}</span> and to <span class="font-medium">{mobile}</span>.`,
    resend_2FA_code_mobile: `Code resent to <span class="font-medium">{mobile}</span>.`,
    send_2FA_code_email: `Code sent to <span class="font-medium">{email}</span>.`,
    send_2FA_code_mobile_email: `Code sent to <span class="font-medium">{email}</span> and to <span class="font-medium">{mobile}</span>.`,
    send_2FA_code_mobile: `Code sent to <span class="font-medium">{mobile}</span>.`,
    resend_2FA_code: "Resend a new code.",
    resend_2FA_code_msg: `It may take a minute to receive your code. Haven't received it?`,
    authenticate: "Authenticate",
    anonymize: "Anonymize",
    anonymize_msg: `You are about to anonymize <span class="font-medium">{name}</span>. <br /> It will remove the user’s names including all contact details. Furthermore, it will delete the user’s profile avatar as well the generated certificates and attestation documents. <br /> The process of anonymizing is <span class="font-medium">permanent</span> and <span class="font-medium">irreversible</span>.`,
    copyright: "Copyright",
    filters: "Filter | Filters",
    global_search_results: "Global Search Results",
    results_found_in: `Global search results found in {module} > {submodule} with "{term}"`,
    help_manual: "Help  Manual",
    show_intro: "Show Intro",
    hide_intro: "Hide Intro",
    show_hints: "Show Hints",
    hide_hints: "Hide Hints",
    trainings_registered: "Trainings Registered",
    trainings_available: "Trainings Available",
    collection_registered: "Collections Registered",
    collection_available: "Collections Available",
    tagged_resources: "Tagged Resources",
    no_import_trainings: `Please contact your Chexpertise account manager in order to activate SCORM compliant training imports created by Adobe Captivate&trade; or Articulate Storyline&trade;.`,
    acknowledge_lesson_failure: "You have failed to acknowledge the lesson.",
    acknowledge_lesson_success: `You have successfully acknowledged the lesson.`,
    acquiring: "Acquiring",
    action: "Action",
    actions: "Actions",
    activated_on: "Activated on {date}",
    activating_lesson: `Activating this version will archive the current active version.`,
    active: "Active",
    add_answer: "Add answer",
    add_attestation: "Add attestation",
    add_collection: "Add collection",
    add_competency: "Add competency",
    add_criteria: "Add criteria",
    add_criterion: "Add criterion",
    add_department: "Add department",
    add_feedback: "Add feedback",
    add_filter: "Add filter",
    add_instructor: "Add instructor",
    add_lesson: "Add lesson",
    add_new_attestation: "Add new attestation",
    add_new_collection: "Add new collection",
    add_new_competency: "Add new competency",
    add_new_department: "Add new department",
    add_new_lesson: "Add new lesson",
    add_new_observation: "Add new observation",
    add_new_quiz: "Add new quiz",
    add_new_registration: "Add new registration",
    add_new_role: "Add new role",
    add_new_site: "Add new site",
    add_new_training: "Add new training",
    add_new_user: "Add new user",
    add_new_version: "Add new version",
    add_observation: "Add observation",
    add_question: "Add question",
    add_quiz: "Add quiz",
    add_rating_name_success_msg: `You have successfully added a rating of <span class='font-medium'>{ rating }</span> on <span class='font-medium'>{ name }</span>`,
    add_rating_success_msg: `You have successfully added a rating of <span class='font-medium'>{ rating }</span>`,
    add_rating: "Add rating",
    add_registration: "Add registration",
    add_requirement: "Add requirement",
    add_role: "Add role",
    add_saved_search: "Add saved search",
    add_site: "Add site",
    add_training: "Add training",
    add_user: "Add user",
    add: "Add",
    added_on: "Added on",
    address: "Address",
    administration: "Administration",
    advanced_search: "Advanced search",
    all_competencies: "All Competencies",
    all_languages: "All Languages",
    all_statuses: "All Statuses",
    all_submissions: "All Submissions",
    all_versions: "All Versions",
    all: "All",
    and: "And",
    answer_type: "Answer type",
    answers: "Answer | Answers",
    api_access: "API Access",
    api_token: "API Token",
    approaching_deadline: "Approaching Deadline",
    approval_date: "Approval date",
    approvals: "Approvals",
    approve_all: "Approve all",
    approve_by: "Approve by",
    approve: "Approve",
    approved_by: "Approved by",
    approved_on: "Approved on",
    approver_id: "Approver",
    april: "April",
    archived_on: "Archived on {date}",
    archiving_lesson: `Archiving an active version will make it unavailable to all trainings where this is used.`,
    are_you_sure: "Are you sure?",
    assign_competencies_upon: "Assign competencies upon",
    assign_comptencies_before_approval: "Assign competencies before approval",
    assigned: "Assigned",
    attempt_interval_in_days: "Attempt interval in days",
    attempts: "Attempt | Attempts",
    attestation_overview: "Attestation overview",
    attestations: "Attestation | Attestations",
    august: "August",
    author: "Author",
    automatic_registration: "Automatic registration",
    available_date: "Available date",
    available_from: "Available from",
    available_registrations: "Available registrations",
    available_to: "Available to",
    available_until: "Available until",
    back_to_home: "Back to home",
    back: "Back",
    bank_account: "Bank account",
    by_signing_up: "By signing in, you agree to our",
    cancel: "Cancel",
    cancelled_on: "Cancelled on",
    category: "Category",
    certificate_file_id: "Certificate",
    certificate_file: "Certificate file",
    certificate_template: "Certificate template",
    certificate_valid_until: "Certificate valid until",
    change_log: "Change log",
    change_password: "Change password",
    change: "Change",
    changed_user_password: `Password changed successfully for user <span class="font-medium">{name}</span>.`,
    click_here: "Click here",
    client_details: "Client details",
    code: "Code",
    collapse_all: "Collapse all",
    collapse: "Collapse",
    collection_id: "Collection",
    collection_registration_failure: `You have failed to register to this collection.`,
    collection_registration_success: `You have sucessfully registered to this collection.`,
    collection_registrations: "Collection registrations",
    collections_approaching_deadline: "Collections approaching deadline",
    collections: "Collection | Collections",
    colour: "Color",
    comments: "Comments",
    competencies_by_department: "Competencies by department",
    competencies_by_role: "Competencies by role",
    competencies: "Competency | Competencies",
    competency_matrix: "Competency matrix",
    competency_name: "Competency name",
    competency_score_progression: "Competency score progression",
    competency_score: "Competency score",
    competency_statuses: "Competency statuses",
    complete_lesson_password_msg: `Please enter your password to confirm that you completed the lesson.`,
    complete_lesson_pin_msg: `Please enter your PIN to confirm that you completed the lesson.`,
    complete_lesson: "Complete lesson",
    completed_on: "Completed on",
    completed: "Completed",
    completion_approval_requested_on: "Completion approval requested on",
    completion_approved_by: "Completion approved by",
    completion_approved_on: "Completion approved on",
    completion_approved: "Completion approved",
    completion_approver_comments: "Completion approver comments",
    completion_approver: "Completion approver",
    completion_comments: "Completion comments",
    completion_date: "Completion date",
    completion_deadline_days_new_learners: `Completion deadline for new learners (days)`,
    completion_deadline_days: "Completion deadline (days)",
    completion_deadline: "Completion deadline",
    completion_requested_on: "Completion requested on",
    completion_score_progression: "Completion score progression",
    completion_score: "Completion score",
    completion_time: "Completion time",
    completions: "Completion | Completions",
    confirm_password_msg: "Please enter your password to confirm your request.",
    confirm_password: "Confirm password",
    confirm: "Confirm",
    confirmed_new_password: "Confirmed new password",
    contact_details: "Contact details",
    content_location: "Content location",
    coordinator: "Coordinator",
    copied: "Copied to clipboard!",
    correct_answer: "Correct answer",
    count_towards_maximum: "Count towards maximum",
    create_new_version: "Create new version",
    created_on: "Created on",
    credits: "Credits",
    criteria: "Criteria",
    current_score: "Current score",
    daily: "Daily",
    daily_completions: "Daily completion | Daily completions",
    dashboard: "Dashboard",
    data: "Data",
    date_activated: "Date activated",
    date_archived: "Date archived",
    date: "Date",
    days_valid: "Days valid",
    days: "Days",
    deactivated: "Deactivated",
    december: "December",
    delete_language: `Do you really want to delete <span class="font-medium">ALL</span> texts in <span class="font-medium">{name}</span>? <br /> This process cannot be undone.`,
    default_answer_type: "Default answer type",
    default_approve_by: "Default approve by",
    default_assign_competencies_upon: "Default assign competencies upon",
    default_maximum_failed_attempts: "Default maximum failed attempts",
    default_submit_by: "Default submit by",
    delete_profile_avatar: `Do you really want to delete your avatar? <br /> This process cannot be undone.`,
    delete_item: `Do you really want to delete <span class="font-medium">{name}</span>? <br /> This process cannot be undone.`,
    delete_relationship: `Do you really want to remove <span class="font-medium">{name}</span> from <span class="font-medium">{relationship}</span>? <br /> This process cannot be undone.`,
    delete_text: `Do you really want to remove the texts in <span class="font-medium">{name}</span>? <br /> This process cannot be undone.`,
    delete: "Delete",
    department_id: "Department",
    department_users: "Department users",
    departments: "Department | Departments",
    description: "Description",
    designations: "Designations",
    details: "Details",
    disabled_new_version_msg: `Can only create a new version from an active version`,
    display_answers: "Display answers",
    display_correct_answers: "Display correct answers",
    download_audio: "Download audio",
    download_document: "Download document",
    download_slides: "Download slides",
    download: "Download",
    drag: "Drag",
    due_soon: "Due soon",
    duplicate: "Duplicate",
    duration_type: "Duration type",
    duration: "Duration",
    edit_competency: "Edit competency",
    edit_department: "Edit department",
    edit_lesson: "Edit lesson",
    edit_profile: "Edit profile",
    edit_question: "Edit question",
    edit_requirement: "Edit requirement",
    edit_role: "Edit role",
    edit_site: "Edit site",
    edit_user: "Edit user",
    edit: "Edit",
    email_enabled: "Email notifications enabled",
    email_notification: "Email notification",
    email: "Email",
    end_date: "End date",
    equal: "Equal",
    evaluation_results: "Evaluation results",
    evaluations: "Evaluation | Evaluations",
    events: "Event | Events",
    expand_all: "Expand all",
    expand: "Expand",
    expired_competencies: "Expired competencies",
    expires_on: "Expires on {date}",
    expiring_competencies: "Expiring competency | Expiring competencies",
    export_results: "Export results",
    external_code: "External code",
    external_registrations: "External registrations",
    february: "February",
    feedback: "Feedback",
    field: "Field",
    file_id: "File",
    file_ids: "Files",
    files: "Files",
    firstname: "First name",
    forgot_password: "Forgot password?",
    grade_distribution: "Grade distribution",
    grade: "Grade",
    greater_equal: "Greater or equal",
    greater: "Greater",
    has_active_usage_msg: "This is being used by active trainings",
    home: "Home",
    hourly: "Hourly",
    hours: "Hours",
    image_file_id: "Image",
    import_frequency: "Import frequency",
    import_trainings: "Import trainings",
    in: "In",
    inactive: "Inactive",
    instructor_comment: "Instructor comment",
    instructor_comments: "Instructor comments",
    instructor_password: "Instructor password",
    instructor_pin: "Instructor PIN",
    instructors: "Instructors",
    integrations: "Integrations",
    interval_after_prerequisite: "Interval after prerequisite",
    interval_between_attempts: "Interval between attempts",
    invoices: "Invoice | Invoices",
    ip_address: "IP address",
    items_require_attention_msg: `Item require attention | Items require attention`,
    items_requiring_attention_msg: `Items requiring attention available in their respective area`,
    january: "January",
    july: "July",
    june: "June",
    language: "Language",
    languages: "Language | Languages",
    last_28_days: "Last 28 days",
    last_changed: "Last changed",
    last_imported_at: "Last imported at",
    last_modified_on: "Last modified on",
    lastname: "Last name",
    latest_feedback: "Latest feedback",
    latest_submissions: "Latest submissions",
    learner_comment: "Learner comment",
    learner_comments: "Learner comments",
    learner_completed_on: "Learner completed on",
    learner_completed: "Learner completed",
    learner_password: "Learner password",
    learner_pin: "Learner PIN",
    learners: "Learner | Learners",
    learning_hub: "Learning hub",
    learning_scores: "Learning Scores",
    learning: "Learning",
    lesser_equal: "Lesser or equal",
    lesser: "Lesser",
    lesson_no_content: "This lesson has no content.",
    lessons: "Lesson | Lessons",
    license_details: "License details",
    license_id: "License",
    license_users: "License users",
    licenses: "License | Licenses",
    like: "Like",
    linked: "Linked",
    links: "Links",
    list_competencies: "List of competencies",
    listen: "Listen",
    log_in: "Log in",
    log_types: "Log types",
    login_subtitle: `Maintain your organisation's trainings, competencies, and mentorships`,
    login_time: "Login time",
    login_title_1: "Knowledge and learning",
    login_title_2: "Management for professionals",
    logins: "Login | Logins",
    logout: "Logout",
    logs: "Logs",
    manage_competencies: "Manage competencies",
    manage_instructors: "Manage instructors",
    manage_prerequisites: "Manage prerequisites",
    manage_questions: "Manage questions",
    manage_requirements: "Manage requirements",
    manage_roles: "Manage roles",
    manage_trainings: "Manage trainings",
    mandatory_for_roles: "Mandatory for roles",
    mandatory_for: "Mandatory for",
    manual: "Manual",
    march: "March",
    mark_as_all_read: "Mark as all read",
    mark_as_read: "Mark as read",
    mark_as_unread: "Mark as unread",
    matchup_matrix: "Matchup matrix",
    maximum_attempts_per_registration: "Maximum attempts per registration",
    maximum_attempts: "Maximum attempts",
    maximum_failed_attempts: "Maximum failed attempts",
    maximum_registration_duration: "Maximum registration duration",
    may: "May",
    memberships: "Memberships",
    mentee_profile: "Mentee profile",
    mentee: "Mentee",
    mentor_profile: "Mentor profile",
    mentor: "Mentor",
    mentorship: "Mentorship",
    messages: "Messages",
    minutes: "Minutes",
    missing_non_obtainable: "Missing non obtainable",
    missing_obtainable: "Missing obtainable",
    missing: "Missing",
    mobile: "Mobile",
    failed_attempts: "Failed Attempts",
    name: "Name",
    new_password: "New password",
    old_password: "Old password",
    new_user_license: "New user license",
    new_user_status: "New user status",
    never: "Never",
    no_answers: "There are no answers",
    no_approaching_deadline: "There are no items approaching deadline.",
    no_attestation_requests: `This attestation has no pending requests at the moment.`,
    no_completion_requests: "There are no completion requests",
    no_content_lesson: "This lesson does not have any content.",
    no_criteria: "There are no criteria",
    no_expiring_competencies: "There are no expiring competecies",
    no_feedback: "There is no feedback.",
    no_latest_feedbacks: "There are no latest feedbacks.",
    no_latest_submissions: `This attestation does not have any latest submissions.`,
    no_learner_comment: "No comments.",
    no_notifications: "There are no notifications.",
    no_outline: "There is no outline items at the moment.",
    no_pending_approvals: "There are no pending approvals at the moment.",
    no_questions: "There are no questions",
    no_registrations: "There are no registrations",
    no_requirements: "There are no requirements",
    no_results_found: "No results found.",
    no_statistics: "There are no statistics.",
    no_submission_requests: "There are no submission requests at the moment.",
    no_tagged_resources: "There are no tagged resources for this tag.",
    no_tags: "No tags found.",
    no_unread_notifications: "No unread notifications",
    no_versions_lesson: "This lesson does not have any other versions.",
    no_versions: "There are no other versions.",
    non_obtainable_competencies: "Non obtainable competencies",
    none: "None",
    not_equal: "Not equal",
    not_found_subtitle: "But we learn from our mistakes everyday.",
    not_found_title: "Oops. this is embarrassing...",
    notification_read: "Notification read",
    notifications: "Notifications",
    november: "November",
    number_of_reevaluations_if_failed: "Number of reevaluation if failed",
    observation_requested_on: "Observation requested on",
    observation_scheduled_on: "Observation scheduled on",
    observations: "Observation | Observations",
    observed_by: "Observed by",
    observer_comments: "Observer comments",
    observer_id: "Observer",
    observer: "Observer",
    obtainable_competencies: "Obtainable competencies",
    obtained_on: "Obtained on",
    obtained_via: "Obtained via",
    october: "October",
    ok: "Ok",
    on: "On",
    outline: "Outline",
    overview: "Overview",
    pairings: "Pairings",
    parent_department: "Parent department",
    passing_grade: "Passing grade",
    password: "Password",
    past_due: "Past due",
    pause: "Pause",
    payment_info: "Payment info",
    pending: "Pending",
    pending_approvals: "Pending approvals",
    pending_requests: "Pending requests",
    perpetual_license: "Perpetual license",
    perpetuity: "Perpetuity",
    personal_information: "Personal information",
    personification_msg: `You are impersonating <span class='font-medium'>{name}</span>.`,
    phone: "Phone number",
    pin: "PIN",
    places_available: "Places available",
    points: "Points",
    position: "Position",
    prerequisites: "Prerequisite | Prerequisites",
    preview: "Preview",
    price: "Price",
    print: "Print",
    privacy_policy: "Privacy policy",
    product_name: "Product name",
    profile: "Profile",
    progress: "Progress",
    pts: "Pts",
    purchase_order: "Purcharse order",
    qty: "Qty",
    questionnaire_no_answer_comments_error: `You need to have an answer selected before you can comment on a question.`,
    questions: "Question | Questions",
    quiz_leave_msg: `Are you sure you want to leave this quiz? you will not be able to resume later. you will be assigned a grade of 0 and a result of incomplete.`,
    quiz_start_msg: `You are about to start this quiz. you cannot pause or leave without receiving a failing grade. please ensure that all other chexpertise windows and tabs are closed before you begin.`,
    quizzes: "Quiz | Quizzes",
    randomize_answer_order: "Randomize answer order",
    randomize_question_order: "Randomize question order",
    range: "Range",
    ratings: "Rating | Ratings",
    read: "Read",
    receipt: "Receipt",
    recent_logins: "Recent logins",
    recover_password: "Recover password",
    redirect_url: "Redirect URL",
    redirect: "Redirect",
    refresh_content: "Refresh content",
    refresh: "Refresh",
    regenerate: "Regenerate",
    register_now: "Register now",
    register: "Register",
    registered_on: "Registered on",
    registered_via: "Registered via",
    registration_approved_by: "Registration approved by",
    registration_approver: "Registration approver",
    registration_collections: `Registration collection | Registration collections`,
    registration_date: "Registration date",
    registration_processes: "Registration processes",
    registration_requested_on: "Registration requested on",
    registration_trainings: "Registration training | Registration trainings",
    registrations_to_complete: "Registrations to complete",
    registrations: "Registration | Registrations",
    reject_all: "Reject all",
    reject: "Reject",
    remember_me: "Remember me",
    reorder_questions: "Reorder questions",
    reports: "Report | Reports",
    request_attestation_upload: "Request attestation upload",
    request_observation: "Request observation",
    request_upload: "Request upload",
    requested_on: "Requested on",
    requests: "Requests",
    required_for_role: "Required for role",
    required_valid: "Required valid",
    requirements: "Requirement | Requirements",
    requires_approval: "Requires approval",
    requires_completion_approval: "Requires completion approval",
    requires_registration_approval: "Requires registration approval",
    reschedule: "Reschedule",
    reset_password: "Reset password",
    reset: "Reset",
    resource_name: "Resource name",
    resource: "Resource",
    result: "Result",
    results: "Result | Results",
    resume: "Resume",
    role_users: "Role users",
    roles: "Roles",
    save_criteria: "Save criteria",
    save: "Save",
    saved_searches: "Saved searches",
    schedule: "Schedule",
    scheduled_observations: "Scheduled Observations",
    scheduled_on: "Scheduled on",
    scheduled: "Scheduled",
    score: "Score",
    scores_progression: "Scores progression",
    search_results: "Search Results",
    search: "Search",
    select: "Select",
    select_month: "Select month",
    select_new_license: "Select new license",
    send: "Send",
    september: "September",
    show_expired: "Show expired",
    showing_entries: "{first} to {last} of {total}",
    sign_in: "Sign in",
    sign_up_sheet: "Sign up sheet",
    site_users: "Site users",
    sites: "Site | Sites",
    sms_enabled: "SMS notifications enabled",
    sms_notification: "SMS notification",
    source: "Source",
    sources: "Sources",
    start_date: "Start date",
    start: "Start",
    started_on: "Started on",
    statistics: "Statistics",
    status: "Status",
    statuses: "Status | Statuses",
    stop_impersonating: "Stop impersonating",
    submission_details: "Submission details",
    submissions: "Submission | Submissions",
    submit_by: "Submit by",
    submit_evaluation: "Submit evaluation",
    submit: "Submit",
    submitted_by: "Submitted by",
    submitted_on: "Submitted on",
    submitter_id: "Submitter",
    subtotal: "Subtotal",
    summary: "Summary",
    synchronize_now: "Synchronize now",
    sys_admin_locked_competency_msg: `You need to be a system administrator to make changes to this competency.`,
    sys_admin_locked_lesson_msg: `You need to be a system administrator to make changes to this lesson.`,
    sys_admin_locked_msg: `You need to be a system administrator to make changes to this.`,
    sys_admin_locked: "System admin locked",
    system_role: "System role",
    tags: "Tag | Tags",
    taxes_included: "Taxes included",
    teaching: "Teaching",
    temp_password: "Temporary password",
    terms_and_conditions: "Terms and conditions",
    terms: "Terms",
    time: "Time",
    title: "Title",
    to: "To",
    top_trainings: "Top training | Top trainings",
    total_amount: "Total amount",
    total: "Total",
    training_completions: "Training completion | Training completions",
    training_id: "Training",
    training_imports: "Training imports",
    training_registration_failure: `You have failed to register to this training.`,
    training_registration_success: `You have sucessfully registered to this training.`,
    trainings_approaching_deadline: "Trainings approaching deadline",
    trainings: "Training | Trainings",
    type: "Type",
    ui_settings: "UI settings",
    unable_copy: "Unable to copy.",
    unassigned: "Unassigned",
    unlimited_attempts: "Unlimited attempts",
    unlimited: "Unlimited",
    unread: "Unread",
    update_criteria: "Update criteria",
    update_saved_search: "Update saved search",
    updated_on: "Last updated on {date}",
    update_status_emails: "Update status emails",
    update_status_emails_subtext: "Add a comma at the end of each email",
    upload_as: "Upload as",
    upload_attestation: "Upload attestation",
    upload_request_success: "You have successfully requested an upload.",
    upload: "Upload",
    uploaded_by: "Uploaded by",
    usage: "Usage",
    user_id: "User",
    user_type: "User type",
    username: "Username",
    users_assigned: `Users assigned <span class="text-theme-1 font-semibold">{number}</span> of <span class="text-theme-1 font-semibold">{total}</span>`,
    users: "User | Users",
    valid_competencies: "Valid competencies",
    valid_period: "Valid period",
    valid_until: "Valid until",
    value: "Value",
    venue: "Venue",
    version: "Version",
    versions: "Version | Versions",
    view_all: "View all",
    view_attempt: "View attempt",
    view_attestation: "View attestation",
    view_evaluation: "View evaluation",
    view_lesson_msg: `Please complete the lesson to start immediately viewing the lesson.`,
    view_lesson: "View lesson",
    view_registration: "View registration",
    view: "View",
    waiting_approval_competencies: "Waiting approval competencies",
    watch: "Watch",
    wiki_docs: "Wiki docs",
    with: "With",
    // DO NOT CHANGE KEY BELOW
    INSTRUCTOR: "Instructor",
    OBSERVER: "Observer",
    APPROVER: "Approver",
    REGISTRATION_APPROVER: "Registration Approver",
    COMPLETION_APPROVER: "Completion Approver",
    DEFAULT_SUBMITTER: "Default Submitter",
    DEFAULT_APPROVER: "Default Approver",
    TRAINING_SUBMITTER: "Training Submitter",
    TRAINING_APPROVER: "Training Approver",
    REGISTRATION_ATTESTATION_SUBMITTED: "Registration Attestation Submitted",
    REGISTRATION_ATTESTATION_APPROVED: "Registration Attestation Approver",
    "All Learners": "All Learners",
    "Any Training Instructor": "Any Training Instructor",
    "Completion Approval Requested": "Completion Approval Requested",
    "Completion Approval": "Completion Approval",
    "External Learners": "External Learners",
    "In Progress": "In Progress",
    "Information Update": "Information Update",
    "Instructor Action": "Instructor Action",
    "Internal and External Learners": "Internal and External Learners",
    "Internal Learners": "Internal Learners",
    "Learner Completed": "Learner Completed",
    "Learner Completion": "Learner Completion",
    "Live Lecture": "Live Lecture",
    "New Learners": "New Learners",
    "Nightly Process": "Nightly Process",
    "Non-obtainable": "Non-obtainable",
    "O-3C": "Three Choices",
    "O-4C": "Four Choices",
    "Observation Requested": "Observation Requested",
    "Observation Scheduled": "Observation Scheduled",
    "Pending Approval": "Pending Approval",
    "Q-MS": "Multiple Choices",
    "Q-SS": "Single Choice",
    "Q-TF": "True/False",
    "Registration Requested": "Registration Requested",
    "Specific Instructor": "Specific Instructor",
    "Under Review": "Under Review",
    "User Request": "User Request",
    "Waiting Approval": "Waiting Approval",
    Accepted: "Accepted",
    Active: "Active",
    Approved: "Approved",
    Archived: "Archived",
    Audio: "Audio",
    Cancelled: "Cancelled",
    Completed: "Completed",
    Denied: "Denied",
    Disabled: "Disabled",
    Document: "Document",
    Expired: "Expired",
    External: "External",
    Failed: "Failed",
    Inactive: "Inactive",
    Incomplete: "Incomplete",
    Internal: "Internal",
    Learner: "Learner",
    No: "No",
    None: "None",
    Observation: "Observation",
    Obtainable: "Obtainable",
    Passed: "Passed",
    Paused: "Paused",
    Pending: "Pending",
    Quiz: "Quiz",
    Registered: "Registered",
    Rejected: "Rejected",
    Requested: "Requested",
    Slides: "Slides",
    URL: "URL",
    Valid: "Valid",
    Video: "Video",
    Withdrawn: "Withdrawn",
    Yes: "Yes"
  },
  backend: {
    "Unauthorized access": "Unauthorized access.",
    // Login
    "Login failed Expired license key": "Login failed. Expired license key.",
    "Login failed Invalid user": "Login failed. Invalid user.",
    "Login failed Invalid username and / or password": `Login failed. Invalid username and/or password.`,
    "Login failed The user status is not active": `Login failed. The user status is not active.`,
    "Login successful": "Login successful.",
    "Maximum login attempts reached Account disabled": `Maximum login attempts reached. Account disabled.`,
    "Logout successful": "Logout successful.",
    "Password expired": "Password expired.",
    "Could not find the user": "Could not find the user.",
    "Invalid current password": "Invalid current password.",
    "Login failed Invalid login credentials": `Login failed. Invalid login credentials.`,
    // System Wide Message
    "The license key will expire on __value1__": `The license key will expire on {value1}`,
    // 2FA
    "Verify 2FA code": "Verify 2FA code.",
    "Invalid 2FA code": "Invalid 2FA code.",
    "2FA code resent": "2FA code resent.",
    // Reset Request Password
    "User not found": "User not found.",
    "Reset code sent to __value1__": `Reset code sent to <span class="font-medium">{value1}</span>.`,
    // Reset Password
    "Reset request not found": "Reset request not found.",
    "Password reset successfully": "Password reset successfully.",
    // Change Password
    "Password changed": "Password changed",
    "Password previously used": "Password previously used.",
    "New password not provided": "New password not provided.",
    "New password cannot be empty": "New password cannot be empty.",
    // CREATE
    "Resource added successfully": "Resource added successfully.",
    "Validation errors": "Validation errors.",
    "Cannot perform this action": "Cannot perform this action.",
    // READ
    "Resource(s) found successfully": "Resource(s) found successfully.",
    // UPDATE
    "Resource updated successfully": "Resource updated successfully.",
    "Resource not updated": "Resource not updated.",
    "Resource(s) updated successfully": "Resource(s) updated successfully.",
    // DELETE
    "Resource(s) deleted successfully": "Resource(s) deleted successfully.",
    "Resource(s) not deleted": "Resource(s) not deleted.",
    "Unauthorized access System roles cannot be deleted": `Unauthorized access. System roles cannot be deleted.`,
    // Log actions
    "Logged In": "Logged In",
    Added: "Added",
    Updated: "Updated",
    Deleted: "Deleted",
    "Added User": "Added User",
    "Removed User": "Removed User",
    "Reset Password": "Reset Password",
    "Regenerated API Key": "Regenerated API Key",
    "Impersonated User": "Impersonated User",
    "Added Answer": "Added Answer",
    "Deleted Answer": "Deleted Answer",
    "Updated Answer": "Updated Answer",
    "Added Question": "Added Question",
    "Deleted Question": "Deleted Question",
    "Updated Question": "Updated Question",
    "Deleted Criteria": "Deleted Criteria",
    "Added Criteria": "Added Criteria",
    "Updated Criteria": "Updated Criteria",
    // Log types
    Role: "Role",
    Site: "Site",
    Department: "Department",
    Login: "Login",
    User: "User",
    Competency: "Competency",
    Lesson: "Lesson",
    Evaluation: "Evaluation",
    Attestation: "Attestation",
    Learner: "Learner",
    // UI Settings
    "Animate User Interface": "Animate User Interface",
    "Animations on the user interface page loads": `Animations on the user interface page loads.`,
    "Email Notfication Alerts": "Email Notfication Alerts",
    "Receive email alerts when a notification is sent": `Receive email alerts when a notification is sent.`,
    "Per Page Pagination": "Per Page Pagination",
    "Number of results to display per page": `Number of results to display per page.`,
    "SMS Notfication Alerts": "SMS Notfication Alerts",
    "Receive SMS alerts when a notification is sent": `Receive SMS alerts when a notification is sent.`,
    "UI Theme": "UI Theme",
    "The user interface colour settings": "The user interface colour settings.",
    Default: "Default",
    Yes: "Yes",
    No: "No",
    // Lesson Overview Stats
    number_of_learners: "Number of Learners",
    average_completion_time: "Average Completion Time",
    number_of_trainings: "Number of Training Used",
    number_of_instructors: "Number of Instructors",
    number_of_tags: "Number of Tags",
    number_of_competencies: "Number of Competencies",
    // Attestation Overview Stats
    number_of_approved: "Approved",
    number_of_rejected: "Rejected",
    number_of_pending_approvals: "Approval Requests",
    number_of_submission_requests: "Upload Requests",
    // Competency Usage Stats
    lessons: "Lessons",
    attestations: "Attestations",
    evaluations: "Evaluations",
    trainings: "Trainings",
    collections: "Collections",
    // Competency Learners Stats
    number_of_obtainable: "Obtainable",
    number_of_nonobtainable: "Non Obtainable",
    number_of_inprogress: "In Progress",
    number_of_waitingapproval: "Waiting Approvals",
    number_of_valid: "Valid",
    number_of_expired: "Expired",
    // Evaluation Stats
    number_of_questions: "Number of Questions",
    average_grade: "Average Grade",
    highest_grade: "Highest Grade",
    lowest_grade: "Lowest Grade",
    number_of_attempts: "Number of Attempts",
    longest_completion_time: "Longest Completion Time",
    shortest_completion_time: "Shortest Completion Time",
    // Training Stats
    number_of_completed_registrations: "Number of Completed Registrations",
    number_of_active_registrations: "Number of Active Registrations",
    number_of_completion_requests: "Number of Completion Requests",
    number_of_incomplete_registrations: "Number of Incomplete Registrations",
    number_of_late_completions: "Number of Late Completions",
    // Training Overview
    registration_requested: "Registration Requested",
    registered: "Registered",
    in_progress: "In Progress",
    completion_approval_requested: "Completion Approval Requested",
    completed: "Completed",
    cancelled: "Cancelled",
    denied: "Denied",
    passed: "Passed",
    failed: "Failed",
    withdrawn: "Withdrawn",
    // Teaching
    attestation_approvals: "Attestation Approvals",
    attestation_requests: "Attestation Requests",
    evaluation_approvals: "Evaluation Approvals",
    observation_requests: "Observation Requests",
    registration_approvals: "Registration Approvals",
    total: "Total",
    due_soon: "Due Soon",
    past_due: "Past Due",
    incomplete: "Incomplete",
    rejected: "Rejected",
    pending: "Pending",
    paused: "Paused",
    observation_requested: "Observation Requested",
    observation_scheduled: "Observation Scheduled",
    learner_completed: "Learner Completed",
    training_registrations: "Training Registrations",
    collection_registrations: "Collection Registrations",
    observations: "Observations",
    quizzes: "Quizzes",
    observation_request: "Observation Request",
    attestation_request: "Attestation Request",
    // Learner Overview
    "Registration Requested": "Registration Requested",
    Registered: "Registered",
    "In Progress": "In Progress",
    "Completion Approval Requested": "Completion Approval Requested",
    Rejected: "Rejected",
    Completed: "Completed",
    Cancelled: "Cancelled",
    Denied: "Denied",
    Passed: "Passed",
    Failed: "Failed",
    Withdrawn: "Withdrawn",
    Pending: "Pending",
    "Pending Approval": "Pending Approval",
    Approved: "Approved",
    Requested: "Requested",
    Paused: "Paused",
    "Observation Requested": "Observation Requested",
    "Observation Scheduled": "Observation Scheduled",
    "Learner Completed": "Learner Completed",
    Incomplete: "Incomplete",
    Obtainable: "Obtainable",
    "Non-obtainable": "Non-obtainable",
    "Waiting Approval": "Waiting Approval",
    Valid: "Valid",
    Expired: "Expired",
    Accepted: "Accepted",
    // Learning dashboard
    attestations_to_complete: "Attestations to complete",
    collections_to_complete: "Collections to complete",
    evaluations_to_complete: "Evaluation to complete",
    lessons_to_complete: "Lessons to complete",
    trainings_to_complete: "Trainings to complete",
    requested: "Requested",
    pending_approval: "Pending approval",
    "All unread notifications set as read": `All unread notifications set as read.`,
    "Notification set as read": "Notification set as read.",
    Active: "Active",
    Inactive: "Inactive",
    "Under Review": "Under Review",
    Archived: "Archived",
    Disabled: "Disabled",
    INSTRUCTOR: "Instructor",
    OBSERVER: "Observer",
    APPROVER: "Approver",
    REGISTRATION_APPROVER: "Registration Approver",
    COMPLETION_APPROVER: "Completion Approver",
    DEFAULT_SUBMITTER: "Default Submitter",
    DEFAULT_APPROVER: "Default Approver",
    TRAINING_SUBMITTER: "Training Submitter",
    TRAINING_APPROVER: "Training Approver",
    REGISTRATION_ATTESTATION_SUBMITTED: "Registration Attestation Submitted",
    REGISTRATION_ATTESTATION_APPROVED: "Registration Attestation Approver",
    "Activate Hints": "Activate Hints",
    "Display hints on pages": "Display hints on pages",
    "Show Intro": "Show Intro",
    "Display the intro sequence on the home page when the user logs in": `Display the intro sequence on the home page when the user logs in`,
    "User successfully anonymized": "User successfully anonymized.",
    "Reset PIN": "Reset PIN",
    "Invalid current PIN": "Invalid current PIN.",
    "Exported All Data": "Exported All Data",
    CollectionRegistration: "Collection Registration",
    Registration: "Registration",
    Collection: "Collection",
    Training: "Training",
    EvaluationText: "Evaluation Text",
    approaching_deadline: "Approaching Deadline"
  },
  notifications: {
    // Registrations
    RegistrationRequest: "Registration Request",
    RegistrationRequest_msg: `Registration request for training <span class="font-medium">{training}</span>.`,
    RegistrationAdded: "Registration Added",
    RegistrationAdded_msg: `Registration added for training <span class="font-medium">{training}</span>.`,
    RegistrationAcceptanceDecision: "Registration Acceptance Decision",
    RegistrationAcceptanceDecision_msg: `Registration acceptance decision for training <span class="font-medium">{training}</span>`,
    RegistrationCompletionRequest: "Registration Completion Request",
    RegistrationCompletionRequest_msg: `Registration completion request <span class="font-medium">{training}</span>`,
    RegistrationCompletionApproval: "Registration Completion Approval",
    RegistrationCompletionApproval_msg: `Registration completion approval for training <span class="font-medium">{training}</span>`,
    RegistrationCancelled: "Registration Cancelled",
    RegistrationCancelled_msg: `Registration cancelled for training <span class="font-medium">{training}</span>`,
    // Collection Registrations
    CollectionRegistrationRequest: "Collection Registration Request",
    CollectionRegistrationRequest_msg: `Collection registration request for collection <span class="font-medium">{collection}</span>.`,
    CollectionRegistrationAdded: "Collection Registration Added",
    CollectionRegistrationAdded_msg: `Collection registration added for collection <span class="font-medium">{collection}</span>.`,
    CollectionRegistrationAcceptanceDecision: `Collection Registration Acceptance Decision`,
    CollectionRegistrationAcceptanceDecision_msg: `Collection registration acceptance decision for collection <span class="font-medium">{collection}</span>`,
    CollectionRegistrationCompletionRequest: `Collection Registration Completion Request`,
    CollectionRegistrationCompletionRequest_msg: `Collection registration completion request <span class="font-medium">{collection}</span>`,
    CollectionRegistrationCompletionApproval: `Collection Registration Completion Approval`,
    CollectionRegistrationCompletionApproval_msg: `Collection registration completion approval for collection <span class="font-medium">{collection}</span>`,
    CollectionRegistrationCancelled: "Collection Registration Cancelled",
    CollectionRegistrationCancelled_msg: `Collection registration cancelled for collection <span class="font-medium">{collection}</span>`,
    // Attestations
    AttestationUploadRequest: "Attestation Upload Request",
    AttestationUploadRequest_msg: `Attestation <span class="font-medium">{attestation}</span> upload requested for training <span class="font-medium">{training}</span>.`,
    AttestationApprovalRequest: "Attestation Approval Request",
    AttestationApprovalRequest_msg: `Attestation <span class="font-medium">{attestation}</span> approval request for training <span class="font-medium">{training}</span>`,
    AttestationInstructorSubmission: "Attestation Instructor Submission",
    AttestationInstructorSubmission_msg: `Attestation <span class="font-medium">{attestation}</span> instructor submission for traiing <span class="font-medium">{training}</span>`,
    AttestationApprovalDecision: "Attestation Approval Decision",
    AttestationApprovalDecision_msg: `Attestation <span class="font-medium">{attestation}</span> approval decision for training <span class="font-medium">{training}</span>`,
    // Evaluations
    ObservationRequest: "Observation Request",
    ObservationScheduled: "Observation Scheduled",
    EvaluationCompletionApprovalRequest: `Evaluation Completion Approval Request`,
    ObservationRequest_msg: `Observation <span class="font-medium">{observation}</span> request for training <span class="font-medium">{training}</span>`,
    ObservationScheduled_msg: `Observation <span class="font-medium">{observation}</span> scheduled for training <span class="font-medium">{training}</span>`,
    EvaluationCompletionApprovalRequest_msg: `Evaluation <span class="font-medium">{evaluation}</span> completion approval request for training <span class="font-medium">{training}</span>`,
    // Misc
    Send2FACode: "2FA Code Sent",
    Send2FACode_msg: `2FA code  <span class="font-medium">{code}</span> sent`,
    ResetPIN: "PIN Reset",
    ResetPIN_msg: `PIN reset to <span class="font-medium">{pin}</span>`
  },
  intro: {
    welcome_title: "Welcome to Chexpertise",
    welcome_subtitle: "Manage all your learning etc under one umbrella.",
    welcome_msg: `Dashboards & overviews in each section of the software give you a summary of what’s most important for your day-to-day activities.`,
    settings_title: "Settings & Notifications",
    settings_subtitle: `You can configure your account settings by selecting the desired options from the UI Settings page under My Profile in the top right corner.`,
    settings_msg: `Chexpertise can send you emails, notifications, and SMSs with pertinent information related to your learning or teaching roles.`,
    competency_title: "Competency & Completion Scores",
    competency_subtitle: "Learning",
    competency_msg: `Chexpertise calculates a completion score and a competency score daily for your learning activities. This score is calculated according to your required training elements and your required competencies.`,
    evaluation_title: "Evaluations",
    evaluation_subtitle: "Teaching",
    evaluation_msg: `Did you know Chexpertise has two types of evaluations? <br /> Quizzes are questionnaires learners need to take on their own from their account.  The learner must complete the evaluation within the given time limit. <br /> Observations are conducted by instructors while being in-person with the learner.  They can be conducted over several sessions if required.`,
    lesson_title: "Lessons",
    lesson_subtitle: "Teaching & Learning",
    lesson_msg: `Chexpertise provides several different types of lessons.  This includes documents, and streaming videos.`,
    attestation_title: "Attestations",
    attestation_subtitle: "Teaching & Learning",
    attestation_msg: `Attestations are external electronic proofs of activities conducted outside of Chexpertise.  They can be submitted by the learner.  They can also be submitted by certain instructors following a learner submission request.`,
    log_title: "Everything Logged",
    log_subtitle: "Administration",
    log_msg: `Chexpertise keeps a log trail of all user activities.  Administrators can view and export any and all types of logs from the Logs section under Administration.`
  },
  hints: {
    administration_users: `Manage users of the system and their memberships to Roles, Departments, and Sites.`,
    administration_users_external_code: `A handle that uniquely identifies the user in third-party system.  It can be the ID of the user in another HR management system.`,
    administration_users_type: `Internal users are those who have access the system.  External users may be given access to certain contents via another interface.  Generally speaking, external users do not log in to the software.`,
    administration_users_pin: `The PIN is used to acknowledge content when navigating the software.  For instance, a learner may need to acknowledge reading a lesson.  The PIN would be used to verify the user’s identity.  Note, the password is not used in such scenarios because single sign-on clients do not have user passwords.`,
    administration_roles: `Manage the roles available within an organization.  Users may have multiple roles assigned to them.  Learning contents may be made mandatory for certain roles.`,
    administration_roles_code: `An acronym or code to uniquely and easily identify a role.`,
    administration_departments: `Manage your organization’s departments and sub-departments.  User can be assigned to their respective departments.`,
    administration_departments_parent_department: `This makes the current department a sub-department of the parent department.`,
    administration_departments_code: `An acronym or code to uniquely and easily identify a department.`,
    administration_departments_external_code: `A handle that uniquely identifies the department in third-party system.  It can be the ID of the department in another ERP system.`,
    administration_sites: `Manage your organization’s physical locations.  They can be considered either as separate addresses or campuses.  User can be assigned to their respective sites.`,
    administration_sites_code: `An acronym or code to uniquely and easily identify a site.`,
    administration_sites_external_code: `A handle that uniquely identifies the site in third-party system.  It can be the ID of the site in another building or property management system.`,
    teaching_competencies: `Manage the competencies awarded by completing learning activities.  Competencies may be awarded via a multitude of learning activities such as trainings, lessons, etc.`,
    teaching_learners: `Manage the learners who are eligible to complete trainings in the software.  You can follow their learning activities from this section.`,
    teaching_learners_competency_score: `The system calculates a score for each learner based on their competency requirements and their successful attainments.  The score is a percentage value based on dividing the total number of valid competencies by the total number of required competencies.`,
    teaching_learners_completion_score: `The system calculates a score for each learner based on their learning requirements and their successful completions.  The score is a percentage value based on dividing the total number of completed learning elements (i.e.: lessons, evaluations, etc.) by the total number of required elements.`,
    teaching_collections: `Manage the logical grouping of trainings in this section.  Collections assemble learning materials that are meant to be learnt in sequence.  Example of collections can be onboarding sequences, training programs, etc. where a learner must take a certain number of trainings to obtain their competencies.`,
    teaching_collections_code: `An acronym or code to uniquely and easily identify a collection.`,
    teaching_collections_assign_competencies_upon: `Competencies can be assigned as soon as the learner completes the collection.  Alternatively, competencies can be assigned after an instructor approves the learners’ completion.  In the latter case, the competency assignment may be delayed till the instructor completes his/her task.`,
    teaching_trainings: `Manage the courses that you provide to your learners in this section.  Trainings comprise of lessons, evaluations, and attestations.`,
    teaching_trainings_code: `An acronym or code to uniquely and easily identify a training.`,
    teaching_trainings_assign_competencies_upon: `Competencies can be assigned as soon as the learner completes the training.  Alternatively, competencies can be assigned after an instructor approves the learners’ completion.  In the latter case, the competency assignment may be delayed till the instructor completes his/her task.`,
    teaching_trainings_available_to: `Trainings can be made available to internal learners or external learners who are usually not members of your organization.  External members log in via their own portal.`,
    teaching_trainings_mandatory_for: `A training can be mandatory for all learners who are part of the role(s) the training is assigned to.  Alternatively, training can be assigned to only new learners who have not previously completed the training.  The latter is useful for new versions of a given training that learners who have successfully completed a previous version need not register.`,
    teaching_attestations: `Manage the documents that prove successful completion of learning activates outside of the software platform.  These documents may be uploaded by the learners of instructors.`,
    teaching_attestations_default_approve_by: `A submitted attestation may be approved by either a specific instructor or any instructor of a given training.`,
    teaching_attestations_default_assign_competencies: `Competencies related to the given attestation may be assigned as soon as the learner submits their documents or upon approval of their submissions by an instructor.`,
    teaching_lessons: `Manage the learning contents in this section.  Lessons can be in a variety of formats including downloadable documents (Word, PDF, etc.), external links, streaming videos, and live lectures.`,
    teaching_lessons_type: `Choose the format of the lesson content.`,
    teaching_evaluations_quizzes: `Manage evaluations where the learners are quizzed in a timed format.  Quizzes are performed solo and are submitted to the system once completed.`,
    teaching_evaluations_observations: `Manage evaluations where the learners are observed by an instructor who asks them to perform certain tasks.  The evaluation is conducted by the instructor and the learner is graded according to a set of predetermined criteria.`,
    teaching_evaluations_assign_competencies_upon: `Competencies related to the given evaluation may be assigned as soon as the evaluation is completed or upon approval by an instructor.`,
    teaching_evaluations_attempt_interval_in_days: `The number of days a learner must wait before another attempt to succeed is available.  This is useful to prevent repeated attempts to pass an evaluation without properly learning the content.`,
    teaching_evaluations_max_attempts_per_registration: `The number of attempts allowed to pass the given evaluation. `,
    teaching_evaluations_number_of_reeevaluation_if_failed: `In certain cases, it might be necessary for the learner to successfully complete an evaluation more than once upon a failed attempts.  This indicates the number of re-evaluations required following each failed attempt.`,
    teaching_registrations_trainings: `Manage the registrations of learners to their respective trainings.  A training registration is the assignment to complete the given course and its respective elements.`,
    teaching_registrations_collections: `Manage the registrations of learners to their respective collections.  A collection registration is the assignment to complete the given collection and its respective trainings.`,
    teaching_requests_observations: `Manage the observation evaluations requested by learners.  This list contains the observations you may be responsible to conduct.`,
    teaching_requests_attestations: `Manage the attestation upload requests submitted by learners.  This list contains the attestations you may be responsible to submit.`,
    teaching_requests_registration_trainings: `Manage the training registration requests submitted by learners.  Although most trainings would have automatic registrations, learners may have to request enroll in certain trainings depending on how they are configured.`,
    teaching_requests_registration_collections: `Manage the collection registration requests submitted by learners.  Although most collections would have automatic registrations, learners may have to request enroll in certain collections depending on how they are configured.`,
    teaching_approvals_evaluations: `Manage the completion approval requests of the evaluations where you are the assigned approver.`,
    teaching_approvals_attestations: `Manage the submission approval requests of the attestations where you are the assigned approver.`,
    teaching_approvals_registration_trainings: `Manage the completion approval requests of the training registrations where you are the assigned approver.`,
    teaching_approvals_registration_collections: `Manage the completion approval requests of the colelction registrations where you are the assigned approver.`,
    learning_competencies: `View the required competencies required or obtained for your roles.  Competencies can be acquired upon successful completion of various learning activities and may have expiry dates associated to them.`,
    learning_registrations_trainings: `View the trainings you have been registered for.  You may have been automatically registered to trainings that are required for your assigned roles.  Certain registrations may have been created manually by an instructor or following your request.`,
    learning_registrations_collections: `View the colelctions you have been registered for.  You may have been automatically registered to collections that are required for your assigned roles.  Certain registrations may have been created manually by an instructor or following your request.`,
    learning_lessons: `View the list of lessons you have completed or you need to complete in order to conclude your required trainings.`,
    learning_evaluations_quizzes: `View the list of quizzes you have completed or you need to complete in order to conclude your required trainings.`,
    learning_evaluations_observations: `View the list of observations you have completed or you need to complete in order to conclude your required trainings.`,
    learning_attestations: `View the list of attestation submissions you have completed or you need to complete in order to conclude your required trainings.`,
    learning_trainings: `View the list of trainings available in the system.`,
    learning_collections: `View the list of collections available in the system.`
  },
  validations: {
    required: "The field is required.",
    maxLength: "The field must be {max} characters maximum.",
    minLength: "The field must be {min} characters minimum.",
    email: "The field must have a valid email address.",
    sameAs: "The field must match the {otherName} field."
  }
};
