export default {
  SYSTEM_ADMIN: "SYSAD",
  ADMIN: "ADMIN",
  INSTRUCTOR: "INSTR",
  USER: "USER",
  LEARNER: "LERNR",
  MENTEE: "MENTE",
  MENTOR: "MENTR",
  MENTORSHIP_COORDINATOR: "MCORD"
};
