import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "roles",
    name: "teaching-roles",
    meta: {
      title: t("app.roles", 2)
    },
    redirect: { name: "teaching-roles-search" },
    component: () => import("@/views/teaching/roles/IndexView"),
    children: [
      {
        path: "",
        name: "teaching-roles-search",
        component: () => import("@/views/teaching/roles/IndexPage")
      }
    ]
  },
  {
    path: "roles/:id",
    name: "teaching-roles-update",
    meta: {
      parentName: "teaching-roles",
      title: t("app.roles", 2)
    },
    redirect: { name: "teaching-roles-overview" },
    props: true,
    component: () => import("@/views/teaching/roles/ReadView"),
    children: [
      {
        path: "overview",
        name: "teaching-roles-overview",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/roles/OverviewPage")
      },
      {
        path: "learners",
        name: "teaching-roles-learners",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/roles/LearnersPage")
      },
      {
        path: "instructors",
        name: "teaching-roles-instructors",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/roles/InstructorsPage")
      },
      {
        path: "trainings",
        name: "teaching-roles-trainings",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/roles/TrainingsPage")
      },
      {
        path: "collections",
        name: "teaching-roles-collections",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/roles/CollectionsPage")
      }
    ]
  }
];
