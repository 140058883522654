const getEndpoint = (url, method) => ({ url, method });
const getPostEndpoint = url => getEndpoint(url, "post");
const getPatchEndpoint = url => getEndpoint(url, "patch");
const getGetEndpoint = url => getEndpoint(url, "get");
const getDeleteEndpoint = url => getEndpoint(url, "delete");

const getCRUDEndpoints = (url, id = "id") => ({
  ...getGetEndpoint(url),
  create: getPostEndpoint(""),
  read: getGetEndpoint(`{${id}}`),
  update: getPatchEndpoint(`{${id}}`),
  delete: getDeleteEndpoint(`{${id}}`)
});

const getUserEndpoints = () => ({
  users: {
    ...getGetEndpoint("{id}/users"),
    create: getPostEndpoint(""),
    delete: getDeleteEndpoint("{user_id}")
  }
});

export default {
  platform: {
    url: "",
    tags: {
      ...getGetEndpoint("tags"),
      read: getGetEndpoint("{id}"),
      delete: getDeleteEndpoint("{id}")
    },
    files: {
      url: "files",
      show: getGetEndpoint("show/{id}"),
      upload: getPostEndpoint("upload"),
      delete: getDeleteEndpoint("delete/{id}")
    },
    feedback: {
      url: "feedback",
      add: getPostEndpoint("{type}/{id}")
    },
    rating: {
      url: "rating",
      add: getPostEndpoint("{type}/{id}")
    },
    "password-verification": getPostEndpoint("password-verification/{id}"),
    "pin-verification": getPostEndpoint("pin-verification/{id}"),
    "resend-2FA": getPostEndpoint("users/resend-2fa-code"),
    search: {
      ...getGetEndpoint("search"),
      results: getGetEndpoint("{type}")
    },
    dashboard: {
      ...getGetEndpoint("dashboard"),
      refresh: getGetEndpoint("refresh")
    },
    integration: {
      ...getCRUDEndpoints("integrations"),
      enabled: getGetEndpoint("enabled"),
      synchronize: getPostEndpoint("synchronize")
    }
  },
  login: getPostEndpoint("users/login"),
  password: {
    url: "password",
    "request-reset": getPostEndpoint("request-reset"),
    reset: getPostEndpoint("reset"),
    change: getPostEndpoint("change")
  },
  user: {
    url: "users/{user_id}",
    "verify-token": getPostEndpoint("verify-token")
  },
  "ui-settings": getGetEndpoint("ui-settings"),
  my: {
    url: "my",
    "export-all-data": getGetEndpoint("export-all-data"),
    "saved-searches": getCRUDEndpoints("saved-searches"),
    "pinned-searches": getCRUDEndpoints("pinned-searches"),
    profile: {
      ...getGetEndpoint("profile"),
      update: getPatchEndpoint("")
    },
    "password-verification": getPostEndpoint("password-verification"),
    "pin-verification": getPostEndpoint("pin-verification"),
    password: getPostEndpoint("password"),
    key: getPostEndpoint("regenerate-key"),
    memberships: getGetEndpoint("memberships"),
    logs: getGetEndpoint("logs"),
    feedback: getGetEndpoint("feedbacks"),
    "ui-settings": getPatchEndpoint("ui-settings"),
    avatar: {
      url: "avatar",
      update: getPostEndpoint(""),
      delete: getDeleteEndpoint("")
    },
    notifications: {
      url: "",
      index: getGetEndpoint("notifications"),
      "unread-all": getGetEndpoint("unread-notifications"),
      "mark-as-read": getGetEndpoint("read-notification/{id}"),
      "mark-as-all-read": getGetEndpoint("read-all-notifications"),
      summary: getGetEndpoint("notifications-summary")
    }
  },
  administration: {
    url: "administration",
    dashboard: getGetEndpoint("dashboard"),
    users: {
      ...getCRUDEndpoints("users"),
      password: getPostEndpoint("{id}/set-password"),
      regenerate: getPostEndpoint("{id}/regenerate-key"),
      impersonate: getGetEndpoint("{id}/impersonate"),
      anonymize: getGetEndpoint("{id}/anonymize"),
      "reset-pin": getPostEndpoint("{id}/reset-pin")
    },
    sites: {
      ...getCRUDEndpoints("sites"),
      ...getUserEndpoints()
    },
    roles: {
      ...getCRUDEndpoints("roles"),
      ...getUserEndpoints()
    },
    departments: {
      ...getCRUDEndpoints("departments"),
      ...getUserEndpoints()
    },
    licenses: {
      ...getGetEndpoint("licenses"),
      users: getGetEndpoint("{id}/users"),
      invoice: getGetEndpoint("{id}/invoice/{invoice_id}")
    },
    logs: {
      url: "logs",
      index: getGetEndpoint("{log_type}/{year}/{month}"),
      months: getGetEndpoint("{log_type}/months"),
      resource: getGetEndpoint("{log_type}/id/{resource_id}"),
      logins: getGetEndpoint("user-logins/{id}"),
      actions: getGetEndpoint("user-actions/{id}")
    }
  },
  teaching: {
    url: "teaching",
    instructors: {
      ...getGetEndpoint("instructors"),
      read: getGetEndpoint("{id}"),
      overview: getGetEndpoint("{id}/overview"),
      lessons: getGetEndpoint("{id}/lessons"),
      attestations: getGetEndpoint("{id}/attestations/{type}"),
      evaluations: getGetEndpoint("{id}/evaluations/{type}"),
      collections: getGetEndpoint("{id}/collections/{type}"),
      trainings: getGetEndpoint("{id}/trainings/{type}")
    },
    roles: {
      ...getGetEndpoint("roles"),
      read: getGetEndpoint("{id}"),
      overview: getGetEndpoint("{id}/overview"),
      learners: getGetEndpoint("{id}/learners"),
      instructors: getGetEndpoint("{id}/instructors"),
      collections: getGetEndpoint("{id}/collections"),
      trainings: getGetEndpoint("{id}/trainings")
    },
    dashboard: {
      ...getGetEndpoint("dashboard"),
      refresh: getGetEndpoint("refresh"),
      "expiring-competencies": getGetEndpoint("expiring-competencies")
    },
    certificates: getGetEndpoint("certificates"),
    competencies: {
      ...getCRUDEndpoints("competencies"),
      overview: getGetEndpoint("{id}/overview"),
      learners: getGetEndpoint("{id}/learners"),
      usage: getGetEndpoint("{id}/usage"),
      texts: {
        url: "{id}/texts",
        delete: getDeleteEndpoint("{text_id}")
      }
    },
    lessons: {
      ...getCRUDEndpoints("lessons"),
      "video-upload-url": getPostEndpoint("video-upload-url"),
      feedback: getGetEndpoint("{id}/feedback"),
      overview: getGetEndpoint("{id}/overview"),
      learners: getGetEndpoint("{id}/learners"),
      usage: getGetEndpoint("{id}/usage"),
      texts: {
        url: "{id}/texts",
        delete: getDeleteEndpoint("{text_id}")
      },
      instructors: {
        url: "{id}/instructors",
        delete: getDeleteEndpoint("{instructor_id}")
      },
      competencies: {
        url: "{id}/competencies",
        delete: getDeleteEndpoint("{competency_id}")
      },
      duplicate: getPostEndpoint("{id}/duplicate"),
      "new-version": getPostEndpoint("{id}/new-version")
    },
    attestations: {
      ...getCRUDEndpoints("attestations"),
      overview: getGetEndpoint("{id}/overview"),
      learners: getGetEndpoint("{id}/learners"),
      usage: getGetEndpoint("{id}/usage"),
      submissions: getGetEndpoint("{id}/submissions"),
      submission: {
        url: "submission/{id}",
        read: getGetEndpoint(""),
        update: getPatchEndpoint(""),
        submit: getPostEndpoint("approval-decision")
      },
      texts: {
        url: "{id}/texts",
        delete: getDeleteEndpoint("{text_id}")
      },
      competencies: {
        url: "{id}/competencies",
        delete: getDeleteEndpoint("{competency_id}")
      },
      duplicate: getPostEndpoint("{id}/duplicate"),
      "new-version": getPostEndpoint("{id}/new-version"),
      requests: {
        url: "",
        submissions: getGetEndpoint("all-submission-requests"),
        approvals: getGetEndpoint("all-approval-requests")
      }
    },
    evaluations: {
      ...getCRUDEndpoints("evaluations"),
      feedback: getGetEndpoint("{id}/feedback"),
      overview: getGetEndpoint("{id}/overview"),
      attempts: getGetEndpoint("{id}/attempts"),
      attempt: getGetEndpoint("view-attempt/{id}"),
      usage: getGetEndpoint("{id}/usage"),
      requests: getGetEndpoint("all-observation-requests"),
      approvals: getGetEndpoint("all-completion-approval-requests"),
      approval: getPostEndpoint("attempt/{id}/approval-decision"),
      schedule: getPostEndpoint("schedule-observation/{id}"),
      "delete-language": getPostEndpoint("{id}/delete-language"),
      texts: {
        url: "{id}/texts",
        delete: getDeleteEndpoint("{text_id}")
      },
      instructors: {
        url: "{id}/instructors",
        delete: getDeleteEndpoint("{instructor_id}")
      },
      competencies: {
        url: "{id}/competencies",
        delete: getDeleteEndpoint("{competency_id}")
      },
      duplicate: getPostEndpoint("{id}/duplicate"),
      "new-version": getPostEndpoint("{id}/new-version"),
      "reorder-questions": getPatchEndpoint("{id}/reorder-questions")
    },
    questions: getCRUDEndpoints("evaluation/{id}/questions", "question_id"),
    answers: getCRUDEndpoints(
      "evaluation/{id}/question/{question_id}/answers",
      "answer_id"
    ),
    criteria: getCRUDEndpoints(
      "evaluation/{id}/question/{question_id}/criterias",
      "criteria_id"
    ),
    trainings: {
      ...getCRUDEndpoints("trainings"),
      feedback: getGetEndpoint("{id}/feedback"),
      overview: getGetEndpoint("{id}/overview"),
      registrations: getGetEndpoint("{id}/registrations"),
      outline: {
        ...getGetEndpoint("{id}/outline"),
        update: getPatchEndpoint("update-attestation/{attestation_id}")
      },
      "remove-outline-item": getPatchEndpoint(
        "{id}/outline/remove-item/{type}/{item_id}"
      ),
      "reorder-outline": getPatchEndpoint("{id}/reorder-outline"),
      usage: getGetEndpoint("{id}/usage"),
      texts: {
        url: "{id}/texts",
        delete: getDeleteEndpoint("{text_id}")
      },
      instructors: {
        url: "{id}/instructors",
        delete: getDeleteEndpoint("{instructor_id}")
      },
      competencies: {
        url: "{id}/competencies",
        delete: getDeleteEndpoint("{competency_id}")
      },
      roles: {
        url: "{id}/roles",
        delete: getDeleteEndpoint("{role_id}")
      },
      prerequisites: {
        url: "{id}/prerequisites",
        delete: getDeleteEndpoint("{prerequisite_id}")
      },
      duplicate: getPostEndpoint("{id}/duplicate"),
      "new-version": getPostEndpoint("{id}/new-version")
    },
    collections: {
      ...getCRUDEndpoints("collections"),
      feedback: getGetEndpoint("{id}/feedback"),
      overview: getGetEndpoint("{id}/overview"),
      outline: getGetEndpoint("{id}/outline"),
      texts: {
        url: "{id}/texts",
        delete: getDeleteEndpoint("{text_id}")
      },
      instructors: {
        url: "{id}/instructors",
        delete: getDeleteEndpoint("{instructor_id}")
      },
      competencies: {
        url: "{id}/competencies",
        delete: getDeleteEndpoint("{competency_id}")
      },
      roles: {
        url: "{id}/roles",
        delete: getDeleteEndpoint("{role_id}")
      },
      trainings: {
        url: "{id}/trainings",
        delete: getDeleteEndpoint("{training_id}")
      },
      duplicate: getPostEndpoint("{id}/duplicate"),
      "new-version": getPostEndpoint("{id}/new-version"),
      "reorder-trainings": getPatchEndpoint("{id}/reorder-trainings")
    },
    registrations: {
      ...getCRUDEndpoints("registrations"),
      approvals: getGetEndpoint("all-completion-approval-requests"),
      approval: getPostEndpoint("{id}/approval-decision"),
      collections: getGetEndpoint(
        "available-collections/{user_id}/{training_id}"
      ),
      requests: getGetEndpoint("all-registration-requests"),
      "request-approval": getPostEndpoint("{id}/acceptance-decision")
    },
    "collection-registrations": {
      ...getCRUDEndpoints("collection-registrations"),
      approvals: getGetEndpoint("all-completion-approval-requests"),
      approval: getPostEndpoint("{id}/approval-decision"),
      requests: getGetEndpoint("all-registration-requests"),
      "request-approval": getPostEndpoint("{id}/acceptance-decision")
    },
    learners: {
      ...getGetEndpoint("learners"),
      read: getGetEndpoint("{id}"),
      overview: getGetEndpoint("{id}/overview"),
      collections: getGetEndpoint("{id}/collections"),
      trainings: getGetEndpoint("{id}/trainings"),
      evaluations: getGetEndpoint("{id}/evaluations"),
      lessons: getGetEndpoint("{id}/lessons"),
      attestations: getGetEndpoint("{id}/attestations"),
      competencies: getGetEndpoint("{id}/competencies"),
      feedback: getGetEndpoint("{id}/feedbacks")
    },
    reports: {
      url: "reports",
      departments: getPostEndpoint("departments"),
      "failed-attempts": getPostEndpoint("failed-attempts"),
      learners: getPostEndpoint("learners"),
      trainings: getPostEndpoint("trainings"),
      roles: getPostEndpoint("roles")
    }
  },
  learning: {
    url: "learning",
    dashboard: {
      ...getGetEndpoint("dashboard"),
      refresh: getGetEndpoint("refresh")
    },
    competencies: {
      ...getGetEndpoint("competencies"),
      statuses: getGetEndpoint("statuses"),
      expiring: getGetEndpoint("expiring")
    },
    lessons: {
      ...getGetEndpoint("lessons"),
      read: getGetEndpoint("{id}"),
      acknowledge: getPostEndpoint("{id}/read")
    },
    attestations: {
      ...getGetEndpoint("attestations"),
      read: getGetEndpoint("{id}"),
      "request-upload": getGetEndpoint("{id}/request-upload"),
      upload: getPostEndpoint("{id}/submit")
    },
    evaluations: {
      ...getGetEndpoint("evaluations"),
      index: getGetEndpoint("{type}"),
      read: getGetEndpoint("{id}/view"),
      request: getGetEndpoint("{id}/request-observation"),
      begin: getGetEndpoint("{id}/begin"),
      submit: getPostEndpoint("{id}/submit"),
      resume: getGetEndpoint("{id}/resume-observation"),
      pause: getGetEndpoint("{id}/pause-observation"),
      "submit-answer": getPostEndpoint("{id}/submit-answer"),
      "submit-criteria": getPostEndpoint("{id}/submit-criteria"),
      "add-file": getPostEndpoint("{id}/add-question-file")
    },
    registrations: {
      ...getGetEndpoint("registrations"),
      read: getGetEndpoint("{id}")
    },
    "collection-registrations": {
      ...getGetEndpoint("collection-registrations"),
      read: getGetEndpoint("{id}")
    },
    trainings: {
      ...getGetEndpoint("trainings"),
      read: getGetEndpoint("{id}"),
      request: getGetEndpoint("{id}/request-registration")
    },
    collections: {
      ...getGetEndpoint("collections"),
      read: getGetEndpoint("{id}"),
      request: getGetEndpoint("{id}/request-registration")
    }
  }
};
