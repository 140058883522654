<template>
  <div class="modal" tabindex="-1" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" :class="size">
      <div class="modal-content">
        <slot name="header" />

        <div class="modal-body">
          <slot name="content" />
        </div>

        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "",
      validator: value => {
        return ["modal-sm", "", "modal-lg", "modal-xl"].includes(value);
      }
    }
  }
};
</script>
