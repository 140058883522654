import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "learners",
    name: "teaching-learners",
    meta: {
      title: t("app.learners", 2)
    },
    redirect: { name: "teaching-learners-search" },
    component: () => import("@/views/teaching/learners/LearnerView"),
    children: [
      {
        path: "",
        name: "teaching-learners-search",
        component: () => import("@/views/teaching/learners/IndexPage")
      }
    ]
  },
  {
    path: "learners/:id",
    name: "teaching-learners-update",
    meta: {
      parentName: "teaching-learners",
      title: t("app.learners", 2)
    },
    redirect: { name: "teaching-learners-overview" },
    props: true,
    component: () => import("@/views/teaching/learners/Learner"),
    children: [
      {
        path: "overview",
        name: "teaching-learners-overview",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/learners/overview/OverviewPage")
      },
      {
        path: "competencies",
        name: "teaching-learners-competencies",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/learners/CompetenciesPage")
      },
      {
        path: "feedback",
        name: "teaching-learners-feedback",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/learners/FeedbackPage")
      },
      {
        path: "lessons",
        name: "teaching-learners-lessons",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/learners/LessonsPage")
      },
      {
        path: "evaluations",
        name: "teaching-learners-evaluations",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/learners/EvaluationsPage")
      },
      {
        path: "quizzes/:attemptId",
        name: "teaching-learners-quizzes-attempts-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/learners/QuizAttemptPage")
      },
      {
        path: "observations/:attemptId",
        name: "teaching-learners-observations-attempts-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/learners/ObservationAttemptPage")
      },
      {
        path: "attestations",
        name: "teaching-learners-attestations",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/learners/AttestationsPage")
      },
      {
        path: "trainings",
        name: "teaching-learners-trainings",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/learners/TrainingsPage")
      },
      {
        path: "collections",
        name: "teaching-learners-collections",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () => import("@/views/teaching/learners/CollectionsPage")
      }
    ]
  }
];
