import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "trainings",
    name: "teaching-registrations-trainings",
    meta: {
      title: t("app.registrations", 2)
    },
    redirect: { name: "teaching-registrations-trainings-search" },
    component: () =>
      import("@/views/teaching/registrations/trainings/ResourceView"),
    children: [
      {
        path: "",
        name: "teaching-registrations-trainings-search",
        component: () =>
          import("@/views/teaching/registrations/trainings/IndexPage")
      },
      {
        path: "add",
        name: "teaching-registrations-trainings-create",
        meta: {
          title: t("app.add", 2)
        },
        component: () =>
          import("@/views/teaching/registrations/trainings/CreatePage")
      }
    ]
  },
  {
    path: "trainings/:id",
    name: "teaching-registrations-trainings-update",
    meta: {
      parentName: "teaching-registrations-trainings",
      title: t("app.registrations", 2)
    },
    redirect: { name: "teaching-registrations-trainings-details" },
    props: true,
    // eslint-disable-next-line
    component: () => import("@/views/teaching/registrations/trainings/ReadPage"),
    children: [
      {
        path: "details",
        name: "teaching-registrations-trainings-details",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/registrations/trainings/DetailsPage")
      },
      {
        path: "progress",
        name: "teaching-registrations-trainings-progress",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/registrations/trainings/outline/OutlinePage")
      },
      {
        path: "lessons/:lessonId",
        name: "teaching-registrations-trainings-lessons-details",
        meta: {
          title: t("app.lessons")
        },
        props: true,
        // eslint-disable-next-line
        component: () => import("@/views/teaching/registrations/trainings/LessonPage")
      },
      {
        path: "attestations/:attestationId",
        name: "teaching-registrations-trainings-attestations-details",
        meta: {
          title: t("app.attestations")
        },
        props: true,
        // eslint-disable-next-line
        component: () => import("@/views/teaching/registrations/trainings/AttestationPage")
      },
      {
        path: "evaluations/:evaluationId",
        name: "teaching-registrations-trainings-evaluations-details",
        meta: {
          title: t("app.evaluations")
        },
        props: true,
        // eslint-disable-next-line
        component: () => import("@/views/teaching/registrations/trainings/EvaluationPage")
      },
      {
        path: "logs",
        name: "teaching-registrations-trainings-logs",
        meta: {
          title: t("app.edit", 2)
        },
        props: true,
        component: () =>
          import("@/views/teaching/registrations/trainings/LogsPage")
      }
    ]
  }
];
