import i18n from "@/i18n";

const t = i18n.global.t;

export default [
  {
    path: "instructors",
    name: "teaching-instructors",
    meta: {
      title: t("app.instructors", 2)
    },
    redirect: { name: "teaching-instructors-search" },
    component: () => import("@/views/teaching/instructors/IndexView"),
    children: [
      {
        path: "",
        name: "teaching-instructors-search",
        component: () => import("@/views/teaching/instructors/IndexPage")
      }
    ]
  },
  {
    path: "instructors/:id",
    name: "teaching-instructors-update",
    meta: {
      parentName: "teaching-instructors",
      title: t("app.instructors", 2)
    },
    redirect: { name: "teaching-instructors-overview" },
    props: true,
    component: () => import("@/views/teaching/instructors/ReadView"),
    children: [
      {
        path: "overview",
        name: "teaching-instructors-overview",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/instructors/OverviewPage")
      },
      {
        path: "lessons",
        name: "teaching-instructors-lessons",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/instructors/LessonsPage")
      },
      {
        path: "attestations",
        name: "teaching-instructors-attestations",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/instructors/AttestationsPage")
      },
      {
        path: "evaluations",
        name: "teaching-instructors-evaluations",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/instructors/EvaluationsPage")
      },
      {
        path: "trainings",
        name: "teaching-instructors-trainings",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/instructors/TrainingsPage")
      },
      {
        path: "collections",
        name: "teaching-instructors-collections",
        meta: {
          title: t("app.details", 2)
        },
        props: true,
        component: () => import("@/views/teaching/instructors/CollectionsPage")
      }
    ]
  }
];
