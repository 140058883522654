import { createStore } from "vuex";
import main from "./modules/main";
import sideMenu from "./modules/side-menu";
import simpleMenu from "./modules/simple-menu";
import topMenu from "./modules/top-menu";
import user from "./modules/user";
import icons from "./modules/icons";
import client from "./modules/client";
import api from "./modules/api";
import app from "./modules/app";
import messages from "./modules/messages";
import queries from "./modules/queries";
import criteria from "./modules/criteria";
import notifications from "./modules/notifications";
import options from "./modules/options";

const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    user,
    icons,
    client,
    api,
    app,
    messages,
    queries,
    criteria,
    notifications,
    options
  }
});

export function useStore() {
  return store;
}

export default store;
