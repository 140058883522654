import i18n from "@/i18n";
import middleware from "../middleware";

const t = i18n.global.t;

export default [
  {
    path: "/login",
    name: "login",
    meta: {
      middleware: [middleware.unauth]
    },
    component: () => import("@/views/general/login/LoginPage")
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      middleware: [middleware.unauth]
    },
    component: () => import("@/views/general/resetPassword/ResetPasswordPage")
  },
  {
    path: "/change-password",
    name: "change-password",
    meta: {
      middleware: [middleware.unauth]
    },
    component: () => import("@/views/general/changePassword/ChangePasswordPage")
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () => import("@/views/general/TermsAndConditionsPage")
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/general/PrivacyPolicyPage")
  },
  {
    path: "/evaluations",
    component: () => import("@/components/templates/QuestionnairePage"),
    children: [
      {
        path: "/quizzes/:id/begin",
        name: "begin-quiz",
        props: true,
        component: () => import("@/views/learning/evaluations/BeginQuizPage")
      },
      {
        path: "/observations/:id/begin",
        name: "begin-observation",
        props: true,
        component: () =>
          import("@/views/learning/evaluations/BeginObservationPage")
      },
      {
        path: "/observations/:id/resume",
        name: "resume-observation",
        props: true,
        component: () =>
          import("@/views/learning/evaluations/ResumeEvaluationPage")
      }
    ]
  },
  {
    path: "/",
    name: "home",
    redirect: { name: "home-dashboard" },
    meta: {
      middleware: [middleware.auth],
      title: t("app.home", 2)
    },
    component: () => import("@/components/side-menu/TheSideMenu"),
    children: [
      {
        path: "dashboard",
        name: "home-dashboard",
        meta: {
          title: t("app.dashboard", 2)
        },
        component: () => import("@/views/home/HomePage")
      },
      {
        path: "tags",
        name: "tags",
        meta: {
          title: t("app.tags", 2)
        },
        component: () => import("@/views/home/tags/TagsPage")
      },
      {
        path: "tags/:id",
        name: "tag",
        meta: {
          title: t("app.tags")
        },
        props: true,
        component: () => import("@/views/home/tags/TagPage")
      },
      {
        path: "search/:type/:term",
        name: "search",
        meta: {
          title: t("app.search")
        },
        props: true,
        component: () => import("@/views/home/search/SearchPage")
      },

      {
        path: "notifications",
        name: "home-notifications",
        meta: {
          title: t("app.notifications", 2)
        },
        component: () => import("@/views/home/notifications/NotificationsPage")
      },
      {
        path: "profile",
        name: "profile",
        meta: {
          title: t("app.profile", 2)
        },
        redirect: { name: "profile-details" },
        component: () => import("@/views/home/profile/ProfilePage"),
        children: [
          {
            path: "",
            name: "profile-details",
            component: () => import("@/views/home/profile/DetailsPage")
          },
          {
            path: "memberships",
            name: "profile-memberships",
            component: () => import("@/views/home/profile/MembershipsPage")
          },
          {
            path: "logs",
            name: "profile-logs",
            component: () => import("@/views/home/profile/LogsPage")
          },
          {
            path: "feedback",
            name: "profile-feedback",
            component: () => import("@/views/home/profile/FeedbackPage")
          },
          {
            path: "ui-settings",
            name: "profile-ui-settings",
            component: () => import("@/views/home/profile/UISettingsPage")
          }
        ]
      }
    ]
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    meta: {
      middleware: [middleware.auth]
    },
    component: () => import("@/views/general/UnauthorizedPage")
  },
  {
    path: "/error-page",
    name: "error-page",
    meta: {
      middleware: [middleware.auth]
    },
    component: () => import("@/views/general/NotFoundPage")
  }
];
