import faker from "./faker";
import helper from "./helper";
import lodash from "./lodash";
import moment from "./moment";
import toast from "./toast";

export default app => {
  app.use(faker);
  app.use(helper);
  app.use(lodash);
  app.use(moment);
  app.use(toast);
};
