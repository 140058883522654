import systemRoles from "@/constants/systemRoles";

export default function admin({ next, store, nextVue }) {
  const roles = store.getters["user/roles"];
  const authorizedRoles = [systemRoles.SYSTEM_ADMIN, systemRoles.ADMIN];
  const hasAccess = roles.some(role => authorizedRoles.includes(role.code));

  if (!hasAccess) {
    return nextVue({ name: "unauthorized" });
  }

  return next();
}
